export default {
    usingPlatformTrekioAgreement: "Používáním platformy Trekio souhlasíš s následujícími podmínkami jejího používání a bereš na vědomí naše Zásady ochrany osobních údajů.",
    definitions: "Definice",
    definitionsParagraph1: "Trekio označuje web {trekioUrl} a mobilní aplikaci Trekio.",
    definitionsParagraph2: "Obsahem se míní všechna data umístěná uživateli na Trekio, včetně textu, fotografií, hodnocení, informací a komunikace uživatelů prostřednictvím platformy Trekio.",
    definitionsParagraph3: "Výlet je speciální typ obsahu, který může vytvořit pouze uživatel typu Author (viz II. C). Jde o cestovní itinerář sestávající z textu, trasy a fotografií. Tento typ obsahu musí být před publikací schválen administrátory Trekia.",
    profileRegistrationAndSecurity: "Registrace a bezpečnost profilu",
    profileRegistrationAndSecurityParagraph1: "Abys mohl/a využít některé funkce Trekia, nemusíš se registrovat. Neregistrovaní uživatelé si mohou prohlížet některý obsah a využít některé funkce mobilní aplikace, pokud se chovají v souladu s našimi podmínkami a normami chování.",
    profileRegistrationAndSecurityParagraph2: "Pro plné využití platformy Trekio je potřeba zaregistrovat účet. Registrací přijímáš tyto Podmínky a bereš na vědomí naše {principlesDataProcessingUrl}. Tímto způsobem se stáváme smluvními partnery, z nichž každý má smluvní práva a povinnosti.",
    profileRegistrationAndSecurityParagraph3: "Registrace na platformě Trekio je zdarma. Můžeš se registrovat jako běžný uživatel typu User nebo jako Author, který může vytvářet Výlety.",
    profileRegistrationAndSecurityParagraph4: "Registrací jako Author s Trekiem zároveň uzavíráš Licenční smlouvu.",
    profileRegistrationAndSecurityParagraph5: "Registruješ se zadáním platné e-mailové adresy a bezpečného hesla nebo prostřednictvím sociálních sítí (pokud jsou k dispozici, například Google nebo Facebook). Zvolíš si uživatelské jméno a k profilu můžeš přidat své vlastní jméno, příjmení a telefonní číslo. Po registraci profilu můžeš přidat také profilový obrázek. Nepovinné údaje nemusíš vyplňovat. Jejich vyplněním a uložením vyjadřuješ svůj souhlas se zpracováním těchto osobních údajů.",
    profileRegistrationAndSecurityParagraph6: "V případě účtu Authora musíš pro potřeby Licenční smlouvy uvést celé své jméno a příjmení, adresu a datum narození.",
    profileRegistrationAndSecurityParagraph7: "Tvá registrace nabývá účinnosti, jakmile dokončíš registrační proces a my aktivujeme tvůj účet nebo ti to jiným způsobem potvrdíme.",
    profileRegistrationAndSecurityParagraph8: "Účet na Trekiu je tvůj osobní účet. Za všechny aktivity prováděné prostřednictvím svého účtu neseš výhradní odpovědnost. Zavazuješ se nás neprodleně informovat o jakémkoli skutečném nebo domnělém neoprávněném přístupu ke svému účtu.",
    profileRegistrationAndSecurityParagraph9: "Zavazuješ se, že nebudeš na platformu Trekio zadávat žádné falešné informace o vlastní osobě a nebudeš vytvářet profily pro cizí osoby bez jejich a našeho výslovného povolení. Nebudeš vytvářet uživatelská jména, která by bylo možné zaměnit s již existujícími uživatelskými jmény, porušující práva třetích stran, či jinak nezákonná či nevhodná. Administrátoři platformy Trekio mohou uživatelská jména upravovat dle vlastního uvážení.",
    profileRegistrationAndSecurityParagraph10: "Zavazuješ se, že své osobní údaje a kontaktní informace profilu budeš udržovat aktuální.",
    rightsToUploadedContent: "Práva k vkládanému obsahu",
    rightsToUploadedContentParagraph1: "Uživatel typu User i Author, který vkládá na Trekio jakýkoliv Obsah, včetně Výletů, odesláním tohoto Obsahu potvrzuje, že je nositelem veškerých autorských práv k umísťovanému Obsahu.",
    rightsToUploadedContentParagraph2: "Vložením obsahu na naši platformu vzniká pro nás územně neomezená licence na celou dobu trvání majetkových práv k danému obsahu, která lze zrušit pouze písemnou cestou a v případě Výletů vytvořených Authorem pouze podle podmínek příslušné Licenční smlouvy. Tato licence se vztahuje i na smazaná data.",
    rightsToUploadedContentParagraph3: "V případě Výletů vytvořených Authorem, se veškerá licenční práva (včetně práv k textu i fotografiím přidruženým k Výletu) řídí Licenční smlouvou uzavřenou mezi takovým uživatelem a Trekiem.",
    rightsToUploadedContentParagraph4: "Zveřejnění jakéhokoliv obsahu znamená, že tato informace je volně přístupná celosvětově na internetu a kdokoliv na ni může odkazovat. Umístěním obsahu na Trekiu souhlasíš s celosvětovým zpřístupněním tohoto obsahu veřejnosti v nehmotné podobě prostřednictvím Trekia, s tím, že nemáš z toho titulu vůči Trekiu či komukoli jinému nárok na jakoukoli úplatu nebo jinou protihodnotu.",
    rightsToUploadedContentParagraph5: "Odpovídáš za to, že jsi sám/sama oprávněn/a k vyslovení souhlasu s těmito podmínkami, případně ho vyslovuješ i v platném zastoupení všech dalších osob, jejichž souhlas je zapotřebí.",
    rightsToUploadedContentParagraph6: "Provozovatel Trekia nepřebírá povinnost tebou umístěný Obsah chránit a nenese žádnou odpovědnost za porušení tvých práv k Obsahu, který na Trekio umístíš.",
    rightsToUploadedContentParagraph7: "Jsme vděční za jakékoliv připomínky a podněty k fungování a vylepšení Trekia, ber však na vědomí, že použitím tvého nápadu nebo tebou zaslaného materiálu nevzniká Trekiu žádná povinnost ti nápad nebo použití materiálu a v něm obsažených informací jakkoliv kompenzovat; jeho předáním souhlasíš s jeho využitím za těchto podmínek, včetně podmínky bezúplatnosti.",
    rightsToUploadedContentParagraph8: "U obsahu, na který se vztahují naše práva k duševnímu vlastnictví a který je přístupný prostřednictvím platformy Trekio (například námi poskytované obrázky, design či Výlety), si uchováváme všechna práva.",
    rightsToUploadedContentParagraph9: "Pro další použití jakéhokoli obsahu z Trekia (umístěného uživateli typu User, Author nebo administrátory a provozovateli platformy) i částí platformy samotné je potřeba náš předchozí písemný souhlas. K úpravě, překladu, vytváření odvozených děl, dekompilaci či zpětné analýze obsahu umístěného na Trekiu nebo jeho částí, či jiné snaze o extrahování našeho zdrojového kódu, musíš obdržet naše písemné svolení (nebo oprávnění na základě open source licence) kromě případů, kdy platí výjimka či omezení dle příslušných právních předpisů.",
    termsOfUse: "Zásady užívání",
    termsOfUseParagraph1: "Nebudeš na Trekio umísťovat žádný Obsah ani činit žádné akce, jež by směřovaly k možnému porušení nebo ohrožení práv jiných osob. Nebudeš Trekio využívat k nezákonným nebo nekalým aktivitám.",
    termsOfUseParagraph2: "Obsah, který na Trekio umístíš, nebude propagovat ani obsahovat rasovou, národnostní, náboženskou ani jinou nesnášenlivost či diskriminaci ani nezákonné aktivity. Tebou umísťovaný obsah bude respektovat soukromí jiných osob.",
    termsOfUseParagraph3: "Nebudeš na Trekio vkládat námi předem nepovolenou komerční komunikaci v jakékoliv podobě.",
    termsOfUseParagraph4: "Zavazuješ se dodržovat následující podmínky: Nebudeš shromažďovat jakákoliv data o uživatelích Trekia. Nebudš uploadovat viry či malware (ani obsah, u něhož si tím nejsi jist/a), stejně tak nebudeš ani vkládat odkazy na stránky, které by mohly viry, malware či jakýkoliv jiný útočný software obsahovat. Nebudeš napadat profily jiných uživatelů či jakkoliv jinak se pokoušet manipulovat s profily, které jsi sám/sama nezaložil/a.",
    termsOfUseParagraph5: "Nebudeš činit nic, co by mohlo ohrozit provoz platformy Trekio, jakkoliv poškodit její systém a správnou funkčnost. Jakékoliv úpravy profilu, které nejsou přímo připravené v administraci profilu, jsou zakázané.",
    termsOfUseParagraph6: "Nalezené chyby v systému nám nahlásíš a nebudeš je zneužívat.",
    termsOfUseParagraph7: "Jakýkoli uživatel je povinen být seznámen s aktuálním zněním Uživatelských podmínek. Trekio si vyhrazuje právo tyto podmínky bez předchozího upozornění pozměňovat. Větší zásahy a změny těchto podmínek budou ohlášeny emailem na registrované adresy.",
    termsOfUseParagraph8: "O porušení pravidel a interpretaci kteréhokoliv výše uvedeného bodu rozhoduje výhradně Trekio.",
    violationOfUserTermsAndConditions: "Porušení uživatelských podmínek",
    violationOfUserTermsAndConditionsParagraph1: "Vyhrazujeme si právo odstranit jakýkoliv tvůj obsah, pokud se domníváme, že odporuje těmto podmínkám používání platformy. Pokud se domníváš, že jsou jakkoliv porušena tvá práva, napiš nám na email {trekioInfoEmail}",
    violationOfUserTermsAndConditionsParagraph2: "Pokud bys opakovaně porušoval/a tyto podmínky používání platformy, vyhrazujeme si právo zrušit tvůj profil a zablokovat ti přístup k Trekiu.",
    ourLiabilityForTheContent: "Odpovědnost provozovatele Trekia za obsah",
    ourLiabilityForTheContentParagraph1: "Neneseme žádnou odpovědnost za Obsah umístěný uživateli na Trekio, jeho užití a nakládání s ním. Pokud kdokoliv zahájí jakýkoliv soudní či jiný spor směřující proti tvým akcím či tebou umístěnému obsahu na Trekiu, a bude-li se v něm domáhat i nároků vůči provozovateli Trekia, zavazuješ se nahradit nám veškeré náklady s tím spojené, včetně nákladů právního zastoupení ve skutečné výši. Totéž platí pro mimosoudní uplatnění a případné vypořádání takových nároků. ",
    ourLiabilityForTheContentParagraph2: "Trekio nenese žádnou odpovědnost za Obsah, jeho užití a nakládání s ním. Na platformě můžeš najít cestovní itineráře a doporučení, neneseme ale žádnou odpovědnost za pravdivost a aktuálnost informací poskytnutých v rámci obsahu, ani za důsledky plynoucí z užití takového obsahu. (Pokud se rozhodneš vydat na výlet, neneseme odpovědnost za to, jak bude probíhat, ani za to, pokud se ti na něm něco stane.)",
    ourLiabilityForTheContentParagraph3: "Jsme poskytovatelem prostoru na webu, který se snažíme spravovat dle našeho nejlepšího vědomí a svědomí, a který preventivně chráníme těmito podmínkami používání.",

    theseTermsTakeEffectOn: "Tyto podmínky nabývají účinnosti dnem 15.4.2024."
}