<template>
    <button
        :style="buttonStyle"
        :type="type"
        :class="['button', {'button--primary': primary}, {'button--secondary': secondary}, { 'loading': isLoading}, disabled ]"
        :disabled="isLoading || disabled"
        @click="$emit('click', $event)"
    >
        <div v-if="isLoading" class="loader-1 center" :class="{'with-text': loadingText}"><span></span></div>
        <span v-if="isLoading && loadingText">{{ loadingText }}</span>
        <slot v-else-if="!isLoading"></slot>
    </button>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
            },
            primary: {
                type: Boolean,
                default: true
            },
            secondary: {
                type: Boolean,
                default: false
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
            loadingText: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            height: {
                type: String,
            },
            maxWidth: {
                type: String,
            },
            width: {
                type: String,
            },
            minWidth: {
                type: String,
            }
        },

        computed: {
            buttonStyle() {
                const style = {}
                if (this.height) {
                    style.height = this.height
                }
                if (this.maxWidth) {
                    style.maxWidth = this.maxWidth;
                }
                if (this.width) {
                    style.width = this.width
                }
                if (this.minWidth) {
                    style.minWidth = this.minWidth;
                }
                return style;
            }
        },
    }

</script>

<style lang="scss" scoped>
    .loader-1 {
        flex-shrink: 0;

        &.with-text {
            margin-left: -32px;
        }
    }
</style>