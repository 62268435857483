export default {
    errorSubmittingForm: "Error when submitting form.",
    foundErrorsInForms: "{n} errors found in forms | {n} error found in forms | {n} errors found in forms",
    foundErrorsInForm: "{n} errors found in form | {n} error found in form | {n} errors found in form",
    contactFormSubmit: "Contact form has been sent!",
    emptyItemCreated: "New waypoint has been created!",
    emptyItemCreateError: "Error when creating waypoint.",
    defaultItemCreated: "New point of interest has been created!",
    startItemCreated: "New starting point has been created!",
    finishItemCreated: "New end point has been created!",
    dayCreateError: "Error when creating the day",
    dayDeleteError: "Error when deleting day.",
    defaultItemCreateError: "Error when creating the point of interest",
    defaultItemUpdateError: "Error when saving the point of interest",
    defaultItemDeleteError: "Error when deleting point of interest.",
    emptyItemDeleted: "Waypoint has been deleted.",
    defaultItemDeleted: "The point of interest has been deleted.",
    commentCreated: "Comment has been submitted.",
    commentCreateError: "Error when submitting comment!",
    loginError: "Incorrect login details!",
    loginSuccessful: "You have been logged in successfully!",
    emailSendError: "Error when sending e-mail.",
    emailSend: "Email with registration link has been sent!",
    campaignCreated: 'The campaign has been created.',
    campaignCreateError: "Error when creating campaign.",
    campaignUpdated: 'The campaign has been saved.',
    campaignUpdateError: 'Error when saving the campaign.',
    campaignDelefted: 'The campaign has been deleted.',
    campaignDeleteError: "Error when deleting campaign.",
    unsupportedFormat: '{0} format type is not supported!',
    maxiumUnassignedPictures: "The maximum number of photos unassigned to trips or points of interest is 150!",
    pictureCreateError: "Error when creating photo.",
    pictureUpdateForbiddenError: "You cannot modify a photo if it is assigned to a trip with other than 'Draft' or 'For rework' state.",
    pictureDeleteForbiddenError: "You cannot delete a photo if it is assigned to a trip with other than 'Draft' or 'For rework' state.",
    pictureUpdated: "The photo has been successfully modified.",
    pictureDeleted: "The photo has been deleted.",
    pictureCreated: "The photo has been created.",
    picturesCreated: "The photos has been created.",
    dayCreated: "New day has been created!",
    dayUpdateError: "Error when saving the day",
    travelTipSentToRework: "Trip has been sent to user {0} for reworking.",
    travelTipToReworkError: "Error when sending trip for reworking.",
    travelTipCreateError: "Error when creating trip",
    travelTipUpdated: "Trip has been saved.",
    travelTipChangesUpdated: "Trip changes have been saved.",
    travelTipUpdatedAndSentToApprove: "Trip has been saved and submitted for approval!",
    travelTipUpdatedAndSentToControl: "Trip has been saved and submitted for review!",
    travelTipNoDaysOrItemsError: "Trip must contain days and points of interest in order to be submitted for review!",
    maxInProgressTravelTips: "You can't have more than 10 trip drafts.",
    errorsInForms: "Errors in forms: {0}",
    dayDeleted: "Day has been deleted.",
    travelTipDeleted: 'Trip has been deleted.',
    travelTipDeleteError: "Error when deleting trip.",
    travelTipDeclined: "Trip has been rejected.",
    travelTipStateChangeFormErrors: "It is not possible to change the trip status. Errors were found in the forms. Number of errors in forms: {0}",
    travelTipPublished: "Trip has been published!",
    travelTipUnpublished: "Trip status has been set to hidden!",
    authorPublishChange: "Author's attribution preferences changed successfully.",
    authorPublishChangeError: "Error when changing author's attribution preferences.",
    unknownError: "Unknown error",
    invalidEmailAddress: "Invalid e-mail addresss.",
    preferredLanguageChanged: 'Preferred language has been changed',
    preferredLanguageChangeError: 'Unknown error when changing the preferred language',
    userUpdateError: "Unknown error when saving user information.",
    userCreateError: "Unknown error when creating user.",
    userBlocked: "User has been blocked.",
    userBlockError: "Unknown error when blocking the user.",
    userUnblocked: "The user has been unblocked.",
    userUnblockError: "Unknkown error when unblocking the user.",
    userAnonymized: "User has been anonymized.",
    userCreated: "User has been created.",
    userUpdated: "User has been successfully modified!",
    registrationSuccessful: "You have registered successfully!",
    registrationLinkExpiredError: "The registration link is invalid or expired.",
    registrationError: "Unknown error when trying to register, please try again.",
    loginRecaptchaError: "Login failed due to ReCaptcha, please try again.",
    recaptchaValidationError: "Error validating ReCaptcha.",
    messageSent: "Your message has been sent.",
    userAnonymizeError: "Error when anonymizing user.",
    changesSaved: "Changes have been successfully saved.",
    passwordResetLinkExpiredError: "The link to change your password has expired!",
    passwordResetLinkSent: "We have emailed you a link to change your password.",
    loginDoesNotExistError: "Account with email address '{0}' does not exist!",
    tagCategoryCreated: "The category has been created.",
    tagCategoryCreateError: "Error when creating category.",
    tagCategoryUpdated: "The category has been updated.",
    tagCategoryUpdateError: "Error when updating category.",
    tagCategoryDeleted: "The category has been deleted.",
    tagCategoryDeleteError: "Error when deleting category.",
    tagCreated: "The tag has been created.",
    tagCreateError: "Error when creating tag.",
    tagCreateErrorExists: "The tag named '{0}' already exists.",
    tagUpdated: "The tag has been updated.",
    tagUpdateError: "Error when updating tag.",
    tagDeleted: "The tag has been deleted.",
    tagDeleteError: "Error when deleting tag.",
    equipmentCategoryCreated: "The category has been created.",
    equipmentCategoryCreateError: "Error when creating category.",
    equipmentCategoryUpdated: "The category has been updated.",
    equipmentCategoryUpdateError: "Error when updating category.",
    equipmentCategoryDeleted: "The category has been deleted.",
    equipmentCategoryDeleteError: "Error when deleting category.",
    equipmentCreated: "The equipment has been created.",
    equipmentCreateError: "Error when creating equipment.",
    equipmentUpdated: "The equipment has been updated.",
    equipmentUpdateError: "Error when updating equipment.",
    equipmentDeleted: "The equipment has been deleted.",
    equipmentDeleteError: "Error when deleting equipment.",
    equipmentTemplateCreated: "The template has been created.",
    equipmentTemplateCreateError: "Error when creating template.",
    equipmentTemplateUpdated: "The template has been updated.",
    equipmentTemplateUpdateError: "Error when updating template.",
    equipmentTemplateDeleted: "The template has been deleted.",
    equipmentTemplateDeleteError: "Error when deleting template.",
    localizationApprovalsError: "Some of the forms do not have approved translation.",
  };