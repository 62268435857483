<template>
    <li>
        <template v-if="$parent.$parent.multiple">
            <label class="checkbox">
                <slot></slot>
                <input :checked="isSelected" type="checkbox" @change.stop="selectOption">
                <span class="checkmark-select"></span>
            </label>
        </template>
        <button v-else type="button" @click.stop="selectOption()" :disabled="disabled">
            <slot></slot>
        </button>
    </li>
</template>

<script>
    export default {
        name: 'TrekioSelect',
        props: {
            value: {
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            isSelected() {
                if (this.$parent.$parent.multiple) {
                    return this.$parent.$parent.value.includes(this.value);
                } else {
                    return this.$parent.$parent.value == this.value;
                }
            },
        },
        methods: {
            selectOption(e) {
                this.$parent.$parent.handleOptionSelect(this);
            },
        },
    };
</script>

<style lang=scss scoped>
    @import '@/scss/variables';

    li button {
        border: none !important;
        text-align: left;
        padding-inline: 20px;
        border-radius: 0;
        outline: none;
        
        &:focus-visible {
            background-color: $tr-green;
            color: $tr-white;
        }

        &:disabled {
            opacity: 0.5;
        }
    }
</style>