export default {
    hopOn: "Hop on!",
    search: "Search",
    clear: "Clear",
    send: "Send",
    save: "Save",
    delete: "Delete",
    becomeAuthor: "Become author",
    registerAsAuthor: "Register as author",
    register: "Register",
    login: "Log in",
    logout: "Log out",
    editProfile: "Change profile",
    addAssessment: "Write a review",
    rate: "Submit review",
    back: "Back",

    createTranslation: "Create translation",
    showTranslations: "Show translations",
    hideTranslations: "Hide translations",

    sendInvitation: "Send invitation",

    upload: "Upload",

    addNewDay: "Add new day",
    addStartItem: "+ Create starting point of the day ",
    addEmptyItem: "+ Add waypoint",
    addDefaultItem: "+ Add point of interest",
    addFinishItem: "+ Create end point of the day",
    addTransfer: "Add means of transportation",

    saveTranslations: "Save translations",
    decline: "Reject",
    returnToRework: "Return for rework",
    saveAsConcept: "Safe draft",
    sendToApprove: "Submit for approval",
    sendToControl: "Submit for review",
    approve: "Approve",

    show: "Show",

    authorSignedContract: "Author has signed the contract",
    block: "Block user",
    unblock: "Unblock user",
    anonymize: "Anonymize",

    createPinbaord: "Create pinboard",
    addTrekioTrip: "+ Add a Trekio trip",
    changeTrip: "Change trip",
    deleteTrip: "Remove trip",
    saveChanges: "Save changes",
    addPinboardPost: "Add pinboard post",

    discardChangesAndContinue: "Discard changes and continue",
    saveAndHideOthers: "Save and hide others",

    loadOlder: "Load older",

    createCategory: "Create category",
    chooseTags: "Choose tags",
    changeTags: "Change tags",

    categories: "Categories",
    templates: "Templates",
    saveTemplate: "Save template",

    addEquipment: "Add item",
    showOriginalList: "Show original list",
    showMyList: "Show my list",

    filter: "Filter",
    select: "Select",
}