export default {
    required: "Pole je povinné",
    dayNumberUnique: "Číslo dne musí být unikátní!",
    dayNumberTooHigh: "Číslo dne je příliš velké!",
    dayNumberNotNegative: "Číslo dne nesmí být záporné!",
    latitudeRange: "Hodnota GPS musí být v rozmezí -90 až +90",
    longitudeRange: "Hodnota GPS musí být v rozmezí -180 až +180",
    sequenceNumberNotNegative: "Číslo zastávky nesmí být záporné!",
    sequenceNumberTooHigh: "Číslo zastávky je příliš velké!",
    sequenceNumberUnique: "Číslo zastávky musí být unikátní!",
    travelTipPictureRequired: "Fotka k výletu je povinná!",
    validToBeforeValidFrom: "Pole 'Platnost do' nemůže být dříve než pole 'Platnost od'",
    valueMustBeLargerThanZero: "Hodnota musí být vyšší než 0!",
    incorrectEmailFormat: "E-mail nemá správný formát.",
    incorrectPasswordFormat: "Heslo musí mít 8 znaků, jedno velké písmeno, jedno malé písmeno a jeden symbol",
    incorrectFirstNameFormat: "Jméno smí obsahovat pouze písmena a některé speciální znaky (pomlčku, apostrof).",
    incorrectLastNameFormat: "Příjmení smí obsahovat pouze písmena a některé speciální znaky (pomlčku, apostrof)",
    incorrectUserNameFormat: "Přezdívka smí obsahovat pouze písmena, číslice a některé speciální znaky",
    incorrectZipCodeFormat: "PSČ musí obsahovat pouze čísla nebo písmena.",
    mustBeAtLeast16ToRegister: "Pro registraci ti musí být aspoň 16 let",
    passwordsDoNotMatch: "Hesla se neshodují!",
    userNameAlreadyUsed: "Uživatel s přezdívkou {0} již existuje",
    loginAlreadyUsed: "Uživatel s e-mailovou adresou {0} již existuje",
    pinboardTitleMustBeUnique: "Zkontroluj, zda-li je název nástěnky unikátní",

    dateMustNotBeInPast: "Datum nesmí být v minulosti.",
    endDateBeforeStartDate: 'Datum nesmí být dříve než "Vyrazím nejdříve od".',
    dateRangeIsTooSmall: 'Rozpětí datumů je menší než "Počet dní od".'
}