export default {
    warning: "Varování:",
    changesWillBeIrreversible: "Změny, které provedeš, budou nevratné!",
    travelTipDeleteTitle: "Opravdu chceš výlet {0} smazat?",
    travelTipDeleteText: "Provedená změna bude nevratná! Všechny dny a zastávky ve výletu budou smazány!",
    travelTipDeclineTitle: "Opravdu chceš výlet {0} zamítnout?",
    travelTipDeclineText: "Stav výletu bude změněn na 'Zamítnutý' a za 30 dní bude smazán!",
    travelTipToReworkTitle: "Opravdu chceš výlet {0} vrátit k přepracování?",
    travelTipToReworkText: "Uživateli se výlet vrátí k přepracování. Napiš k výletu komentář, proč ho vracíš k přepracování, aby uživatel věděl, co má předělat.",
    travelTipApproveTitle: "Opravdu chceš výlet {0} schválit?",
    travelTipApproveText: "Uživateli bude výlet schválen. Stav výletu bude nastaven na 'Skrytý'.",
    travelTipToApproveTitle: "Opravdu chceš Výlet {0} odeslat ke schválení?",
    travelTipToApproveText: "Po odeslání výletu ke schválení již nebudeš moct výlet editovat.",
    travelTipToControlTitle: "Opravdu chceš Výlet {0} odeslat ke kontrole?",
    travelTipToControlText: "Po odeslání výletu ke kontrole již nebudeš moct výlet editovat.",
    dayDeleteTitle: "Opravdu chceš den {0} výletu {1} smazat?",
    dayDeleteText: "Provedené změny budou nevratné! Všechny zastávky obsažené ve dni budou smazány!",
    emptyItemDelete: "Opravdu chceš tento slepý bod smazat?",
    itemDelete: "Opravdu chceš zastávku {0} smazat?",
    emptyItemLimitText1: "Po smazání této zastávky by počet slepých bodů {0} určujících trasu mezi {{ prevItem && 'zastávkou ' }}{{ prevItem ? '\"' + prevItem.title + '\"' : 'současným začátkem trasy' }} a  \"{{ nextItem ? '\"' + nextItem.title + '\"' : 'současným koncem trasy' }}\" přesahoval maximální možný počet (9)",
    emptyItemLimitText2: "Kliknutím na 'Smazat' proto smažeme i všechny slepé body mezi těmito zastávkami.",
    emptyItemLimitText3: "Pokud chceš některý z těchto slepých bodů ponecht (max. 9), klikni na 'Zpět' a ručně odstraň alespoň {0} z těchto bodů.",
    userAnonymizeTitle: "Opravdu chceš uživatele {0} anonymizovat?",
    userAnonymizeAlert: "Pokoušíš se smazat svůj účet!",
    userAnonymizeOtherText: "Provedené změny budou nevratné! Uživatel již nebude mít přístup ke svému účtu.",
    userAnonymizeText: "Provedené změny budou nevratné. Po anonymizaci již nebudeš mít přístup ke svému účtu.",
    userAnonymizeText2: "Autorství výletů ti ale zůstane. (V naší interní databázi budeme kvůli licenčním právům i nadále moci dohledat, které výlety jsou od tebe.)\nZkontroluj si v profilu nastavení, jestli chceš být u svých výletů uveden/a jako autor i veřejně, nebo ne!\nPokud zvolíš ano, bude vidět tvá přezdívka a medailonek. Po zrušení účtu už nebudeš moci toto nastavení změnit.",
    userBlockTitle: "Opravdu chceš uživatele {0} zablokovat?",
    userBlockText: "Po zablokování se uživatel nebude moci přihlásit ke svému účtu.",
    userUnblockTitle: "Opravdu chceš uživatele {0} odblokovat?",
    userUnblockText: "Po odblokování se uživatel bude moci opět přihlášit ke svému účtu.",
    invitationTitle: "Opravdu chceš poslat pozvánku na e-mail {0}?",
    invitationText: "Autor bude mít možnost registrovat se pomocí zaslaného odkazu během následujících 14 dnů od odeslání tohoto formuláře.",
    campaignDeleteTitle: "Opravdu chceš kampaň smazat?",
    campaignDeleteText: "Provedená změna bude nevratná, ale výlety obsažené v kampani zůstanou zachovány v sekci Výlety.",
    pictureDeleteTitle: "Opravdu chceš fotku smazat?",
    pictureDeleteText: "Změny, které provedeš, budou nevratné!",
    assessmentDeleteTitle: "Opravdu chceš smazat své hodnocení?",
    assessmentDeleteOthersTitle: "Opravdu chceš smazat komentář uživatele {0}?",
    pinboardPostDeleteTitle: 'Opravdu chceš smazat příspěvek "{0}"?',
    pinboardPostDeleteText: "Veškeré konverzace s uživateli, které se týkají tohoto příspěvku, budou smazány!",
    pinboardDeleteTitle: 'Opravdu chceš smazat nástěnku "{0}"?',
    pinboardDeleteText: 'Všechny příspěvky v nástěnce budou přesunuty do nástěnky "GENERAL"',
    changedLocalizationInputWarningTitle: "Pozor, změnil/a jsi pole, která mají lokalizace do jiných jazyků.",
    changedLocalizationInputWarningText1: "Pokud šlo pouze o stylistickou změnu, zvol \"Uložit\" (bez skrytí ostatních lokalizací).",
    changedLocalizationInputWarningText2: "Pokud se změnil význam sdělení, zvol \"Uložit a skrýt ostatní\".",
    changedLocalizationInputWarningText3: "Volba \"Uložit a skrýt ostatní\" označí ve všech schválených lokalizacích změněnou část výletu jako neschválenou. Všechny lokalizace se pro běžné uživatele skryjí, dokud se v upravené části neprovede a neschválí odpovídající změna významu.",
    unsavedChangesInFormTitle: "Neuložené změny ve formuláři",
    unsavedChangesInFormText: "Vraťte se zpět a uložte změny, nebo změny zahoďte a pokračujte.",
    tagCategoryDeleteTitle: 'Opravdu chceš kategorii "{0}" smazat?',
    tagCategoryDeleteText1: "Tagy v kategorii jsou použity u následujících výletů",
    tagCategoryDeleteText2: "Všechny tagy v kategoriích budou smazány!",
    tagCategoryDeleteText3: "U výletů, ke kterým jsou tagy přiřazeny, budou smazány!",
    tagDeleteTitle: 'Opravdu chceš tag "{0}" smazat?',
    tagDeleteText1: "Tag je použitý u následujících výletů",
    tagDeleteText2: "U výletů, ke kterým je tag přiřazen, bude smazán!",
    equipmentDeleteTitle: 'Opravdu chceš vybavení "{0}" smazat?',
    equipmentTemplateDeleteTitle: 'Opravdu chceš šablonu "{0}" smazat?',
    equipmentCategoryDeleteTitle: 'Opravdu chceš kategorii "{0}" smazat?',
    equipmentCategoryDeleteText: 'Smazáním kategorie se všechno vybavení obsažené v kategorii přesune do kateogrie "DEFAULT"',
}