import { CLEVERTRIP_IMG_URL } from "../definitions";
import i18n from "../localization/i18n";

const PICTURE_HIGH_RES_DIMENSIONS =  "1600pxX1200px"
const PICTURE_LOW_RES_DIMENSIONS =  "640pxX480px"

function determineDifficulty(low, med, high) {
    if (low === 0) {
        if (med  === 0) {
            if (high === 0) {
            return i18n.t("general.noDifficulty");
            } else {
            return i18n.t("general.high");
            }
        } else {
            if (high === 0) {
            return i18n.t("general.medium");
            } else {
            return i18n.t("general.mediumHigh");
            }
        }
    } else {
        if (med  === 0) {
            if (high === 0) {
            return i18n.t("general.low");
            } else {
            return i18n.t("general.lowHigh");
            }
        } else {
            if (high === 0) {
            return i18n.t("general.lowMedium");
            } else {
            return i18n.t("general.lowHigh");
            }
        }
    }
}

export default {

    //random array shuffle, kudos to https://stackoverflow.com/users/310500/laurens-holst
    shuffleArray: function (array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    },


    //returns copy of graphQl campaigns array with fixed data types
    cleanupCampaigns: function(campaigns) {
        return campaigns.map((campaign) => {
            let c = {...campaign};
            c.id = parseInt(campaign.id);
            c.validFrom = new Date(campaign.validFrom);
            c.validTo = new Date(campaign.validTo);
            return c;
        });
    },

    //returns copy of graphQl TravelTips array with fixed data types
    cleanupTravelTips: function(travelTips) {
        return travelTips.map((travelTip) => {
            let t = {...travelTip};
            t.id = parseInt(travelTip.id);
            t.title = travelTip.title;
            t.shortInfo = travelTip.shortInfo;
            return t;
    });
    },

    scrollToTop() {
        window.scrollTo(0,0);
    },

    sleep: async function(msec) {
        return new Promise(resolve => setTimeout(resolve, msec));
    },

    getTimestamp() {
        return Math.round(+new Date()/1000).toString();
    },

    removeAccents(string) {
        if (string) {
            return string.normalize('NFD').replace(/\p{Diacritic}/gu, '')
        }
        return string
    },

    isEmailValid(email) {
        const emailRegex = /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@+[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/
        return emailRegex.test(email)
    },

    passwordHasRequiredSymbols(password) {
        const passRegex = /^(?=.*[0-9])(?=.*[a-zàáâãäåæçèéêëìíîïðñòóôõöøùúûüýÿaăąćčďđēĕėęěīĭıșšţťźżř])(?=.*[A-ZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝAĂĄĆČĐĒĖĘĪĬİŞŠŢŤŹŻŘ])(?=.*[@*#$%^&+|=_,.?!:';<>{}€-])(?=\S+$).{8,}$/;
        return password.match(passRegex)
    },

    stringHasOnlyLetters(string) {
        const stringRegex = /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF \-'']+$/;
        return stringRegex.test(string)
    },

    stringHasOnlyLettersOrNumbers(string) {
        const stringRegex =/^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\d ]+$/;
        return stringRegex.test(string)
    },

    isUserNameValid(string) {
        const stringRegex =/^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\d '-]+$/;
        return stringRegex.test(string)
    },

    getPictureUrlLowResolution(inputString, baseUrl = null) {
        const imgUrl= baseUrl ? baseUrl : CLEVERTRIP_IMG_URL;
        const nameWithoutExtension = inputString.substring(0, inputString.lastIndexOf('.'));
        const extension = inputString.substring(inputString.lastIndexOf('.') + 1);
        return `${imgUrl}${nameWithoutExtension}-dimensions-${PICTURE_LOW_RES_DIMENSIONS}.${extension}`;
    },
    
    getPictureUrlHighResolution(inputString) {
        const imgUrl = CLEVERTRIP_IMG_URL;
        const nameWithoutExtension = inputString.substring(0, inputString.lastIndexOf('.'));
        const extension = inputString.substring(inputString.lastIndexOf('.') + 1);
        return `${imgUrl}${nameWithoutExtension}-dimensions-${PICTURE_HIGH_RES_DIMENSIONS}.${extension}`;
    },

    extractInitials(string) {
        return string.split(' ').map(item => item[0]).join('').substring(0, 2);
    },

    throttle(callback, delay) {
        var lastTime = 0;
        return function () {
            var now = Date.now();
            if (now - lastTime >= delay) {
                callback();
                lastTime = now;
            }
        }
    },

    debounce(callback, delay) {
        var timeoutHandler = null;
        return function () {
            clearTimeout(timeoutHandler);
            timeoutHandler = setTimeout(function () {
                callback();
            }, delay);
        }
    },

    getDifficultyTextFromValue(difficulty) {
        if (!difficulty) return
        if (difficulty === 'LOW') return i18n.t("general.low")
        if (difficulty === 'MEDIUM') return i18n.t("general.medium")
        if (difficulty === 'HIGH') return i18n.t("general.high")
    },

    getDifficultyTextFromTravelTip(travelTip) {
        let low = 0;
        let med = 0;
        let high = 0;
        if (travelTip.itineraryDays) {
          for (const itineraryDay of travelTip.itineraryDays) {
            for (const itineraryItem of itineraryDay.itineraryItems) {
                if (itineraryItem.difficulty === 'LOW') {
                  low = 1;
                } else if (itineraryItem.difficulty === 'MEDIUM') {
                  med = 1;
                } else if (itineraryItem.difficulty === 'HIGH') {
                  high = 1;
                }
            }
          }
        }
  
        return determineDifficulty(low, med, high)
    },

    getDifficultyTextFromItineraryItems(itineraryItems) {
        let low = 0;
        let med = 0;
        let high = 0;

          for (const item of itineraryItems) {
            if (!item.difficulty) continue
            if (item.difficulty === 'LOW') {
                low = 1;
            } else if (item.difficulty === 'MEDIUM') {
                med = 1;
            } else {
                high = 1;
            }
          }
        return determineDifficulty(low, med, high)
    },
    sortLocalizations(localizations, primaryLanguage, editedLanguage) {
        if (localizations?.length > 1) {
            localizations.sort((a, b) => {
                if (a.language === primaryLanguage) return -1;
                else if (b.language === primaryLanguage) return 1;
                else if (a.language === editedLanguage) return -1;
                else if (b.language === editedLanguage) return 1;
                else return 0;
            });
        }
    },
    convertStringWithUrls(string) {
        const urlRegex = /https?:\/\/[^\s]+/g;
        return string.replace(urlRegex, (url) => {
            // Only allow http/https URLs to be rendered
            if (url.startsWith('http://') || url.startsWith('https://')) {
                return `<a href="${url}" target="_blank" class="highlighted-url">${url}</a>`;
            } else {
                return url;  // If it's not a valid URL, leave it as-is
            }
        });
    }
}