import cs_about_page from "./cs_about_page";
import cs_how_to_page from "./cs_how_to_page";
import cs_input_errors from "./cs_input_errors";
import cs_input_labels from "./cs_input_labels";
import cs_general from "./cs_general";
import cs_buttons from "./cs_buttons";
import cs_loading from "./cs_loading";
import cs_terms_of_use from "./cs_terms_of_use";
import cs_principles_data_processing from "./cs_principles_data_processing";
import cs_loading_errors from "./cs_loading_errors";
import cs_info from "./cs_info";
import cs_input_hints from "./cs_input_hints";
import cs_alerts from "./cs_alerts";
import cs_landing_page from "./cs_landing_page";
import cs_difficulty_modal from "./cs_difficulty_modal";
import cs_modals from "./cs_modals";

export default {
	inputLabels: cs_input_labels,
	inputErrors: cs_input_errors,
	inputHints: cs_input_hints,
    general: cs_general,
	buttons: cs_buttons,
	loading: cs_loading,
	loadingErrors: cs_loading_errors,
	info: cs_info,
	alerts: cs_alerts,
	modals: cs_modals,

	websiteTitle: "Trekio.com - Cestujte s námi a prozkoumejte celou zeměkouli",

	admin: {
		loginExpired: "Doba přihlášení vypršla",
		loginToContinue: "Pokud chceš pokračovat, musíš se přihlásit.",
		redirectToHomepage: "Přesměrovat na homepage",
		redirectToHomepageAlert: "(Přesměrováním ztratíš všechna neuložená data)",

		welcomeToAdmin: "Vítej v administraci Trekia!",
		currentlyActiveCampaigns: "Aktuálně aktivních kampaní | Aktuálně aktivní kampaň | Aktuálně aktivní kampaně",
		allCampaigns: "Všechny kampaně",
		currentlyActiveTravelTips: "Aktuálně aktivních výletů | Aktuálně aktivní výlet | Aktuálně aktivní výlety",
		allTravelTips: "Všechny výlety",
		registeredUsers: "Registrovaných uživatelů | Registrovaný uživatel | Registrovaní uživatelé",
		manageUsers: "Spravovat uživatele",
		trafficOfTrekioInTheLast: "Návštěvnost trekio.com za poslední",

		inviteAuthor: "Pozvat autora",

		mobileViewAlert: "Admin sekce není přizpůsobena pro mobilní zobrazení",
		switchToDesktopVersion: "Přepnout do desktop verze",
		untilLogout: "Do odhlášení:",
		trekioVersion: "Trekio • Verze 2.1",
		state: "Stav",
		priority: "Priorita",
		publishTranslation: "Publikovat překlad",
		notSelected: "Nevybráno",
		original: "Výchozí",
		unsavedChangesWarning: "Některé provedené změny nebyly uloženy. Skutečně chcete opustit tuto stránku?",
		addCampaign: "Přidat kampaň",
		withoutCampaign: "Bez kampaně",
		addTravelTip: "Přidat výlet",
		addTravelTipToCampaign: "Přidat výlet ke kampani",
		topTravelTip: '"Topovat" výlet',
		untopTravelTip: '"Odtopovat" výlet',
		travelTipAlreadyUntopped: 'Výlet je již "odtopovaný"',
		travelTipAlreadyOnTheTop: 'Výlet je již nejvýše "topovaný"',
		addPicture: "Přidat fotku",
		addPictures: "Přidat fotky",
		clickOrDragImageToUpload: "Klikni, nebo přetáhni fotku k nahrání",
		clickOrDragImagesToUpload: "Klikni, nebo přetáhni fotky k nahrání",
		noTravelTipWithoutCampaign: "Neexistují žádné výlety bez přiřazené kampaně.",
		noCommonLocalizationWithCampaign: 'Výlet nemá společnou lokalizaci s kampaní.\nZvýrazněné lokalizace výletu chybí.',
		inProgress: "Rozpracované",
		toRework: "K přepracování",
		toControl: "Ke kontrole",
		toApprove: "Ke schválení",
		declined: "Zamítnuté",
		published: "Publikované",
		notPublished: "Skryté",
		forSale: "K prodeji",
		notForSale: "Není k prodeji",
		tripsWithoutPictureAre: "Výlety bez fotky jsou",
		visible: "viditelné",
		hidden: "skryté",

		pictureIsAssignedTo: "Fotka je přiřazena u:",
		inTravelTip: "u výletu:",
		inDayWithNumber: "u dne s číslem",

		travelTipComments: "Komentáře k výletu",
		writeComment: "Napište zprávu...",

		approveCampaignLocalization: "Schválit překlad kampaně",
		approveTravelTipTranslation: "Schválit překlad základního infa",
		addTravelTipPicture: "Přidat fotku výletu",
		picturesTabChoosePictureFromTravelTip: "Vybrat z fotek z výletu",
		addNewPicture: "Přidat novou fotku",
		addNewPictures: "Přidat nové fotky",
		noPictureInTravelTip: "Ve výletu se nenachází žádná fotka.",
		choosePicture: "Vybrat fotku",
		saveTravelTipBeforeAddingDays: "Nejprve si musíš uložit výlet, abys mohl/a přidávat dny a zastávky.",

		deleteItemTooManyEmptyItems: 'Po smazání této zastávky by počet slepých bodů ({ammountOfEmptyPoints}) určujících trasu mezi {previousItem} a "{nextItem}" přesahoval maximální možný počet (9).',
		betweenItem: 'zastávkou "{0}"',
		currentStartOfRoute: "současným začátkem trasy",
		byTheCurrentEndOfTheRoute: "současným koncem trasy",
		warningMaxEmptyItems: "Maximální počet slepých bodů je 9!",
		closeItem: "Zavřít zastávku",
		dayPictureWarning: "Pro výběr fotky dne nejdřív nahraj fotky k jeho zastávkám. Z nich potom můžeš vybrat hlavní fotku dne.",
		dayPictureChoosePicture: "Vybrat fotku dne z fotek u zastávek",
		dayPictureWarningNoItemPicture: "Neexistuje žádná fotka u zastávek v tomto dni.",
		specifyRoute: "Upřesnit trasu",
		approveDayTranslation: "Schválit překlad dne",
		routeLengthHint: "Vzdálenost na zastávku z předchozí zastávky podle mapy: ",
		addPicturesToItem: "Přidat fotky k zastávce",

		approveItemTranslation: "Schválit překlad zastávky",

		doYouWantToBePublishedAsAuthor: "Chceš být uveden/a jako autor výletů?",
		authorIsPublished: "Autor je viditelný",
		authorIsHidden: "Autor je skrytý",
		aboutAuthor: "O autorovi",
		addUser: "Přidat uživatele",
		myProfile: "Můj profil",
		userIsBlocked: "Uživatel je zablokovaný!",
		userIsAnonymized: "Uživatel je anonymizovaný!",
		addProfilePicture: "Přidat profilovou fotku",

		hereWillBe: 'Zde bude "{0}"',
		hereCouldBe: 'Zde může být "{0}"',

		tagsChosenOutOfInLanguage: '{total} z toho {n} je v "{language}" | {total} z toho {n} je v "{language}" | {total} z toho {n} jsou v "{language}"',
		tagCategories: 'Kategorie tagů',
		addCategory: 'Přidat kategorii',
		translation: 'Překlad',
		addTag: "Přidat položku",

		addEquipment: "Přidat vybavení",
		addLink: "Přidat link",
		newTemplateTitle: "Název nové šablony",
		templates: "Šablony",
		notTranslated: "Nepřeloženo",
	},

	map: {
		zoomIn: "Přiblížit",
		zoomOut: "Oddálit",
		allDays: "Všechny dny",
		viewTheDayOnMapyCZ: "Zobrazit den na mapy.cz",
		showTrip: "Zobrazit výlet"
	},
		
	filter: {
		noResults: "Pro zadaný filtr nebyly nalezeny žádné výsledky.",
		searchTerm: "Vyhledat frázi",
		state: "Stav",
		assignedToTravelTip: "Přiřazené u výletu",
		assigned: "Přiřazené",
		notAssigned: "Nepřiřazené",
		lookUpYourTrip: "Najdi si výlet!",
		uncheckAll: "Zrušit vše",
		checkAll: "Vybrat všechno",
		tripLength: "Délka výletu",
		to: "až",
		chosenOutOf: "Vybráno {0} z {1}",
		numberOfDays: "Počet dní:",
		searchBySearchTerm: "Hledání podle fráze",
		moreCountries: "Více zemí",
		lessCountries: "Méně zemí",
		moreFilters: "Více filtrů",
		lessFilters: "Méně filtrů",
		searchingForTrips: "Hledání výletů",
		resultFoundTrips: "Pro vaše hledání máme {tripCount}",
	},

	public: {
		shareWithYourFriends: "Sdílej výlet s přáteli",
		copyLink: "Zkopírovat odkaz",
		shareVia: "Sdílet přes",
		checkOutTheTrip: "Podívejte se na výlet {0} na Trekiu!",

		travelTipDetailPage: {
			travelTipTranslationUnavailable: "Překlad výletu v tomto jazyce není dostupný",
			itemEstimatedTime: "Odhadovaný čas na zastávce: {0}",
			transferBetweenItems: "Doprava mezi zastávkami (km)",
			tripMap: "Mapa",
			settled: "Zařízeno",
			packed: "Zabaleno",
			packingList: "Věci na výlet",
			packingListInfo: 'Tento výlet nemá navrhovaný seznam vybavení, ale můžeš si vytvořit vlastní. Interaktivní seznam se ti u výletu rovnou uloží a budeš se k němu moci vrátit vždy z detailu výletu. Začneš kliknutím na "+" vedle popisku "Ostatní".',
			summary: "Přehled",
			itinerary: "Itinerář",
			gallery: "Galerie",
		},
		assessments: {
			confirmModalAssessmentDeleteTitle: "Opravdu chceš smazat své hodnocení?",
			confirmModalAssessmentDeleteOthersTitle: "Opravdu chceš smazat komentář uživatele XXX?",
			loadingDelete: "Mazání hodnocení...",
			beFirstToReview: "Buď první, kdo ohodnotí tento výlet.",
			overallRating: "Celkové Hodnocení",
			addAssessment: "Přidat hodnocení",
			loginToAddAssessment: "Pro přidání hodnocení se přihlaš",
			rateTravelTip: "Ohodnoť výlet",
			comment: "Komentář",
			loading: "Hodnotím...",
			overallInterest: "Celkový dojem",
			priceQualityRatio: "Poměr cena / kvalita",
			trafficAvailability: "Dostupnost dopravy",
			sights: "Pamětihodnosti",
			correspondsToTheDescription: "Odpovídá popisu",
			photogenic: "Fotogeničnost",
			availabilityOfEquipment: "Dostupnost vybavení",
			uniqueness: "Unikátnost",
			availabilityOfPublicFacilities: "Dostupnost veřejných zařízení",
			accommodation: "Ubytování",
			difficultyMatching: "Náročnost odpovídá popisu",
			cleanliness: "Čistota",
			personnel: "Personál",
			atmosphere: "Atmosféra",
		},
		profilePage: {
			support: "Podpora",
			havingTroubleWithTrekio: "Narazil/a jsi na Trekiu na nějaký zádrhel? Nevíš si s něčím rady? Záleží nám na tom, aby se ti naše platforma používala co nejsnáze. Dej nám prosím vědět, abychom dále mohli vylepšovat to, co již děláme.",
			hereForYou: "Jsme tu pro vás",
			message: "Zpráva",
			addProfilePicture: "Přidat profilovou fotku",
			changePicture: "Změnit fotku",
			anonymize: "Anonymizovat",
			anonymizeAccount: "Anonymizovat účet (smazat účet)",
			editProfile: "Upravit profil",
		},
		registrationPage: {
			registration: 'Registrace',
			authorRegistration: 'Registrace autora',
			joinUs: "Naskoč k nám",
			alreadyHaveAccount: "Máš již účet?",
			agreeWith: "Souhlasím s",
			agreeWithTermsOfUse: "podmínkami pro užívání platformy",
			iHaveRead: "Přečetl/a jsem si",
			iHaveReadPrinciplesDataProcessing: "zásady zpracování osobních údajů",
		},

		loginPage: {
			loginHeader: "Přihlášení",
			welcomeBack: "Vítej zpátky v Trekiu!",
			forgottenPassword: "Zapomněl/a jsem heslo",
			noAccountYetUser: "Ještě nemáš učet?",
			noAccountYetAuthor: "Chceš se stát autorem?",
			registerAuthor: "Registruj se jako autor",
			loginToContinueToAdmin: "Pro pokračování do administračního prostředí se prosím přihlaš.",
			loginExpired: "Doba přihlášení vypršla",
			loginToContinue: "Pokud chceš pokračovat, musíš se přihlásit.",
			redirectToHomepage: "Přesměrovat na homepage",
			redirectToHomepageAlert: "(Přesměrováním ztratíš všechna neuložená data)",
		},
		resetPasswordPage: {
			forgottenPassword: "Zapomenuté heslo",
			passwordChangedSuccessfully: "Heslo bylo úspěšně změněno!",
			fillInYourEmailAddress: "Zadej svou e-mailovou adresu",
			forLoginClickOn: "Pro přihlášení klikni na",
			fillInNewPassword: "Zadej nové heslo",
			changePassword: "Změnit heslo"
		},
		
		contactPage: {
			contactUs: "Kontaktuj nás",
			writeUs: "Napiš nám",
			inputLabelContactName: "Jméno",
			inputPlaceholderContactMessage: "Ahoj, mám dotaz ohledně..."
		},
		homePage: {
			homepageTagline: `Objev cesty, které v průvodci nenajdeš`,
			experiencesTitle: "Zážitky",
			experiencesText: "Nezapomenutelné zážitky, nápadité itineráře",
			experiencesSubTitle: "Zážitky",
			coolPlacesTitle: "Cool místa",
			coolPlacesText: "Originální místa, o kterých jste nevědeli",
		coolPlacesSubTitle: "Poznání",
			comfortTitle: "Pohoda",
			comfortText: "Plánování a starosti nechte na nás",
			comfortSubTitle: "Pohoda",
			interestsAboutTravel: "Co vás na cestování zajímá?",
			all: "Vše"
		},
		myTravelTipsPage: {
			myTrips: "Moje výlety",
			favourites: "Uložené",
			ongoing: "Probíhající",
			past: "Minulé",
			noTravelTips: "Nejsou tady žádné výlety. Co s tím?",
			tryToClearFilter: "Zkus vyčistit filtr.",
			clearFilter: "Vyčistit filtr",
			findYourTrips: "1. Vyber si výlet podle vlastního vkusu.\n2. Kliknutím na ikonku srdce si ho ulož.\n3. Uložené výlety najdeš tady.",
			browseTrips: "Procházet výlety",
			filterTrips: "Filtrovat výlety",
		},
		pinboardPostsPage: {
			lookingForTravelBuddy: "Hledáš parťáka na cesty?",
			createPostWithInfo: "Vytvoř příspěvek s informacemi, kdy a kam bys rád/a vyrazil/a.",
			otherUsersCanReply: "Ostatní registrovaní uživatelé ti budou moci odpovědět přímou zprávou. Pak můžete doladit detaily. Nebo se v naší cestovatelské seznamce podívej, na jaké výlety se chystají ostatní. A k někomu se připoj.",
			thereAreNoPosts: "Nejsou zde žádné příspěvky",
			findTravelBuddy: "Najdi spolucestovatele",
			chooseTripToYourPost: "Vyber výlet ke svému příspěvku",
			searchByName: "Vyhledat podle názvu",
			loginToAddPost: "Pro přidání příspěvku se přihlaš",
		},
		messagesPage: {
			noConversation: "Nemáš zde žádnou konverzaci",
			newConversation: "Nová konverzace",
			writeMessageToStartConversation: "Napiš zprávu pro zahájení konverzace",
			writeMessage: "Napiš zprávu",
		},
		aboutPage: cs_about_page,
		howToPage: cs_how_to_page,
		landingPage: cs_landing_page,
		notFoundPage: {
			pageNotFound: "Je nám líto, ale stránka neexistuje :(",
			weCantFind: "Nemůžeme najít",
			backToHomepage: "Zpět na HomePage"
		}
	},
	difficultyModal: cs_difficulty_modal,
	termsOfUse: cs_terms_of_use,
	principlesDataProcessing: cs_principles_data_processing
}