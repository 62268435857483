export default {
    hopOn: "Nastoupit",
    search: "Vyhledat",
    clear: "Vyčistit",
    send: "Poslat",
    save: "Uložit",
    delete: "Smazat",
    becomeAuthor: "Staň se autorem",
    registerAsAuthor: "Registruj se jako autor",
    register: "Registrovat se",
    login: "Přihlásit se",
    logout: "Odhlásit se",
    editProfile: "Upravit profil",
    addAssessment: "Přidat hodnocení",
    rate: "Ohodnotit",
    back: "Zpět",

    createTranslation: "Vytvořit překlad",
    showTranslations: "Zobrazit překlady",
    hideTranslations: "Zavřít překlady",

    sendInvitation: "Odeslat pozvánku",

    upload: "Nahrát",

    addNewDay: "Přidat nový den",
    addStartItem: "+ Přidat výchozí bod dne",
    addEmptyItem: "+ Přidat slepý bod",
    addDefaultItem: "+ Přidat zastávku",
    addFinishItem: "+ Přidat cílový bod dne",
    addTransfer: "Přidat dopravu",

    saveTranslations: "Uložit překlady",
    decline: "Zamítnout",
    returnToRework: "Vrátit k přepracování",
    saveAsConcept: "Uložit jako koncept",
    sendToApprove: "Odeslat ke schválení",
    sendToControl: "Odeslat ke kontrole",
    approve: "Schválit",

    show: "Zobrazit",

    authorSignedContract: "Autor podepsal smlouvu",
    block: "Zablokovat",
    unblock: "Odblokovat",
    anonymize: "Anonymizovat",

    createPinbaord: "Vytvořit nástěnku",
    addTrekioTrip: "+ Přidat výlet z Trekia",
    changeTrip: "Změnit výlet",
    deleteTrip: "Odstranit výlet",
    saveChanges: "Uložit úpravy",
    addPinboardPost: "Přidat příspěvěk",

    discardChangesAndContinue: "Zahodit úpravy a pokračovat",
    saveAndHideOthers: "Uložit a skrýt ostatní",

    loadOlder: "Načíst starší",

    createCategory: "Vytvořit kategorii",
    chooseTags: "Vybrat tagy",
    changeTags: "Změnit tagy",

    categories: "Kategorie",
    templates: "Šablony",
    saveTemplate: "Uložit šablonu",

    addEquipment: "Přidat položku",
    showOriginalList: "Zobrazit původní seznam",
    showMyList: "Zobrazit můj seznam",

    filter: "Filtrovat",
    select: "Vybrat",
}