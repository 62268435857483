export default {
    howItAllWorks: "How it all works...",
    iWantToGoOnATripTitle: "Wanna go on a trip?",
    iWantToGoOnATripText: "Do you have a dream destination? Want to discover new cool places? Need a tip for a day out? Or a whole off-the-beaten-path itinerary for your next holiday? ...you can find planned out trips  according to your needs using our simple filter.",
    howToChooseTitle: "How to choose?",
    howToChooseText: "You can choose based on criteria such as: {country}, {numberOfDays}, difficulty, preferred means of transportation, or {whatInterestsYouWhenTraveling}. Whether you enjoy hiking in the mountains or prefer city sightseeing and exploring the local culture. And if you don't know what to choose in the filter, get inspired by the popular trips!",
    country: "country",
    numberOfDays: "number of days",
    difficulty: "difficulty",
    whatInterestsYouWhenTraveling: "what interests you when traveling",
    howToChooseText2: "Found a trip you like? Or even more? {share} them with your family or friends!",
    share: "Share",
    howToChooseText3: "If you have an account, use the {heartIcon} to save the trip for later. That way you can come back to it when the time is right!",
    heartIcon: "heart icon",
    foundATripTitle: "Found a trip? Time to head out.",
    foundATripText: "We try to make sure that each of our trips contains all the important information. The info you need ahead of time, as well as the info that comes in handy during the trip. Our itineraries are structured accordingly. They include a map with the starting and end point of each day, the route and all the scheduled points of interest along the way.",
    foundATripText2: "All you have to do is figure out how you'll get to and from the starting and end point of the trip, and where you'll sleep if it's a multi-day trip. And then... let's go!",
    rateTheTripYouLikedTitle: "Did you like the trip? Rate it!",
    rateTheTripYouLikedText: "After login, you can rate and write reviews for the trip(s) you took and help other travelers decide if each trip is right for them.",
    rateTheTripYouLikedText2: "At Trekio, we are also happy to receive feedback. Let us know how you liked the trip planned by us via the {contactPageLink} section. We're interested in your travel experiences, as well as comments if something didn't go as planned. By telling us, you help us keep moving forward.",
    rateTheTripYouLikedText3: "Share photos from your trips on social media with the hashtag #trekio. We want to see them!",
    iWantToShareTripsTitle: "I want to share travel itineraries and interesting places!",
    iWantToShareTripsText: "Do you enjoy exploring during your travels and want to share interesting places? Or you want to show others a place in your area that we haven’t covered yet? Join our community of authors!",
    culture: 'Culture',
    threeDays: '3 days',
    italy: 'Italy',
}