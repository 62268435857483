export default {
    difficulties: "Difficulties",
    difficultyText1: "The overall difficulty of the trip depends on its parts. Trip itineraries consist of days and days of individual points of interest (POI). For a better idea, we assign the difficulty to each POI separately. It then tells you how hard it is to get to and complete that stop. So that you can clearly see which part of the day will be the most challenging! ",
    difficultyText2: "The difficulty is a combination of several different factors. It's not just physical difficulty, but also the accessibility of the place, the terrain, how difficult it is to find your way around and find the place, whether any special skills, knowledge or abilities are needed (e. g. how to move on a glacier), and whether someone can help you (e. g. a paid local guide). We also try to take into account the location of the POI within the day and the trip. The difficulty of a day and trip with POIs with different difficulty levels is then shown as a range of all difficulties (e.g. easy-moderate). ",
    lowDifficulty: "Easy",
    lowDifficultyText: "Easy POIs are those that a regular healthy adult should be able to manage easily and should not pose a big problem for active seniors and children. If all the stops during the day are marked easy, the total daily route, for example on foot, does not exceed 12 km or on a bicycle 40 km. The paths to the site are well marked (official tourist routes, cycle paths, etc.), easily navigable (roads, dirt and forest paths), without large and steep elevation changes, and can be completed with the usual basic equipment, knowledge and skills that could be expected for the type of trip or day (for example, if it is a bike trip, the usual equipment might be a bike you know how to ride, a helmet, sports clothing, and packed drinks and a snack). The description of the trip or day may include any additional special equipment (for example, bike panniers if it is a multi-day cycling tour). Some individual POIs on otherwise longer or more challenging routes may also be marked as easy, as long as the route to the stop meets these criteria (clear, easy terrain, well signposted, etc.) to differentiate the difficulty of individual sections of the route. ",
    mediumDifficulty: "Moderate",
    mediumDifficultyText: "A moderately difficult POI is slightly more demanding than an easy one in one or more (but not all) aspects. For example, it may be a short distance but in more challenging terrain where there is an unmarked path. It may also be a nice marked path where the elevation gain is high, or which is in total of the whole day longer than easy (daily route length between 12 and 30 km on foot, 40 and 80 km on bike). ",
    highDifficulty: "Difficult",
    highDifficultyText: "Difficult POIs are significantly more demanding in several aspects than the easy POIs. They require special training (whether physical, in a particular skill such as map orientation, or a combination of physical fitness and skills) and/or equipment to complete. For example, it can be a winter mountaineering trip (more demanding in terms of orientation and movement, thus requiring higher physical fitness, and special skills and equipment such as crampons). ",
}