import gql from 'graphql-tag'

export const CONVERSATIONS = gql`
    query conversations {
        conversations {
            id
            pinboardPost {
                id
                title
            }
            postOwner {
                id
                userName
                avatarFileName
            }
            conversationInitiator {
                id
                userName
                avatarFileName
            }
            postMessages {
                id
                senderId
                conversationId
                createdAt
                message
                seenAt
            }
            hasUnreadMessage
        }
    }`;

export const CONVERSATION_BY_ID = gql`
    query conversation($conversationId: ID!, $messageCount: Int!) {
        conversation(conversationId: $conversationId, messageCount: $messageCount) {
            id
            pinboardPost {
                id
                title
                description
            }
            postOwner {
                id
                userName
                avatarFileName
            }
            conversationInitiator {
                id
                userName
                avatarFileName
            }
            postMessages {
                id
                senderId
                conversationId
                createdAt
                message
                seenAt
            }
        }
    }`;

export const CONVERSATION_BY_ID_IF_IT_EXISTS = gql`
    query conversation($conversationId: ID!, $messageCount: Int!) {
        conversation(conversationId: $conversationId, messageCount: $messageCount) {
            id
        }
    }`;

export const UNREAD_MESSAGES_COUNT = gql`
    query unreadMessagesCount($conversationId: ID!) {
        unreadMessagesCount(conversationId: $conversationId) {
            value
        }
    }`;

export const UNREAD_MESSAGES = gql`
    query unreadMessages($conversationId: ID!) {
        unreadMessages(conversationId: $conversationId) {
            id
            senderId
            conversationId
            createdAt
            message
            seenAt
        }
    }`;


export const MESSAGES = gql`
    query messages($conversationId: ID!, $messageCount: Int!, $lastMessageDate: Date!) {
        oldMessages: messages(conversationId: $conversationId, messageCount: $messageCount, lastMessageDate: $lastMessageDate) {
            id
            senderId
            conversationId
            createdAt
            message
            seenAt
        }
    }`;

export const UNREAD_CONVERSATIONS_COUNT = gql`
    query unreadConversationsCount {
        unreadConversationsCount
    }`;