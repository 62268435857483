import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT
} from "../actions/auth";
import axios from 'axios';
import { setCookie, removeCookie } from "../../services/CookieService";
import {CLEVERTRIP_API_URL} from "@/definitions";

axios.defaults.withCredentials = process.env.VUE_APP_AXIOS_DEFAULT_CREDENTIALS

const state = {
    token: localStorage.getItem("user-token") || "",
    status: "",
    hasLoadedOnce: false
};

const getters = {

};

const actions = {
    [AUTH_REQUEST]: ({ commit}, {username, password, recaptchaToken}) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            // const token = window.btoa(username + ":" + password);
            const loginDTO = {
                "login" : username,
                "password" : password,
                "recaptchaToken" : recaptchaToken
            };
            axios.post(CLEVERTRIP_API_URL+`/api/authenticateWithRecaptcha`, loginDTO,
                 {
                     headers: {
                         // Authorization: 'Basic ' + token,
                         'Content-Type': 'application/json',
                         'X-Requested-With': 'XMLHttpRequest'
                     }
                 })
                .then(resp => {
                    console.log("Response ok ==>");
                    console.log(resp);
                    // console.log("token "+token);
                    // localStorage.setItem("user-token", token);
                    if(!resp.data.success) {
                        reject(resp.data.message);
                    } else {
                        // commit(AUTH_SUCCESS, token);
                        setCookie('loginExpirationDate')
                        localStorage.setItem("id", resp.data.userId);
                        localStorage.setItem("username", username);
                        localStorage.setItem("role", resp.data.userRole);
                        localStorage.setItem('preferredLanguage', resp.data.preferredLanguage ? resp.data.preferredLanguage : "cs");
                        commit('setLoggingOut', false)
                        commit('setCurrentUserId', resp.data.userId);
                        commit('setCurrentUser', username);
                        commit('setCurrentUserRole', resp.data.userRole);
                        commit('setUnreadMessagesCount', 0)
                        commit('setPreferredLanguage', resp.data.preferredLanguage ? resp.data.preferredLanguage : "cs");
                        resolve(resp);
                    }
                })
                .catch(err => {
                    commit(AUTH_ERROR, err);
                    console.log("Response error");
                    console.log(err);
                    localStorage.removeItem("user-token");
                    reject(err);
                });
        });
    },
    [AUTH_LOGOUT]: ({ commit }) => {
        return new Promise(resolve => {
            commit(AUTH_LOGOUT);
            axios.post(CLEVERTRIP_API_URL+`/api/logout`,
                { credentials: 'include' })
                .then(resp => {
                    console.log("Response ok ==>");
                    console.log(resp);
                });
            commit('setDefaultAdminFilters')
            commit('setCurrentUserId', null)
            commit('setCurrentUser', null);
            commit('setCurrentUserRole', null);
            commit('setLikedTravelTipIds', null)
            commit('setUnreadMessagesCount', 0)
        });
    },
};


const mutations = {
    [AUTH_REQUEST]: state => {
        state.status = "loading";
    },
    [AUTH_SUCCESS]: (state, token) => {
        state.status = 'success';
        state.token = token;
        state.hasLoadedOnce = true;
    },
    [AUTH_ERROR]: state => {
        state.status = "error";
        state.hasLoadedOnce = true;
    },
    [AUTH_LOGOUT] : state => {
        removeCookie('loginExpirationDate')
        state.status = "loggedout";
        localStorage.removeItem("user-token");
        localStorage.removeItem("id");
        localStorage.removeItem("username");
        localStorage.removeItem("role");
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
