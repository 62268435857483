<template>
    <div class='switch-wrapper'>
        <input type="checkbox" id="published" :checked="value" @change="$emit('input', $event.target.checked)"/>
        <label v-if="constantText" class="text-nowrap" for="published">{{ constantText }}</label>
        <label v-else-if="activeText && inactiveText" class="text-nowrap" for="published">{{ isLoading ? $t("loading.save") : (value ? activeText : inactiveText) }}</label>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: Boolean,
                default: false
            },
            activeText: {
                type: String,
            },
            inactiveText: {
                type: String,
            },
            constantText: {
                type: String
            },
            isLoading: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                checked: this.value
            };
        },

        watch: {
            checked(nV) {
                this.$emit('input', nV);
            }
        }
    }

</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    .switch-wrapper {
        display: flex;
        align-items: center;

        input {
            width: 60px !important;
            max-width: 60px !important;
            height: 30px !important;
            max-height: 30px !important;
            background-color: $tr-light-gray !important;
            border-radius: 40px !important;
            position: relative !important;
            background-image: none !important;
            appearance: none !important;
            border-color: transparent !important;
            cursor: pointer;
    
            &:focus-visible {
                outline: 2px solid $tr-green !important;
            }
    
            &::after{
                position: absolute;
                content: "";
                width: 30px;
                height: 30px;
                border-radius: 40px;
                background-color: $tr-gray;
                left: 0px;
                top: 0px;
                transition: 0.2s ease-in-out;
                transition-property: left, background-color;
            }
    
            &:checked{
                &::after{
                    background-color: $tr-green;
                    left: 30px;
                }
            }
    
            & + label{
                padding-left: 10px;
                transform: translateY(4px);
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $tr-black;
            }
        }
    }



</style>