export default {
    back: "Back",
    loadMore: "Load more",
    showMore: "Show more",
    showLess: "Show less",

    link: "link",
    search: "Search",
    here: "here",

    youMightAlsoLike: "You might also like",
    popularTrips: "Popular trips",

    home: "Home",
    travelTipAmmount: "{n} trips | {n} trip | {n} trips",
    travelTip: "Trip",
    travelTips: "Trips",
    aboutUs: "About",
    howTo: "How it works",
    contact: "Contact us",
    login: "Log in",
    register: "Register",
    administration: "Admin section",
    myTravelTips: "My trips",
    messages: "Messages",
    pinboard: "Travel buddies",
    profile: "Profile",
    logout: "Log out",
    basicInfo: "Basic info",
    pictures: "Photos",
    users: "Users",
    campaigns: "Campaigns",
    tags: "Tags",
    equipments: "Equipments",
    other: "Other",

    yes: "Yes",
    no: "No",

    week: "Week",
    month: "Month",
    year: "Year",
    min: "min",
    hour: "h",
    dayCapitalized: "Day",
    daysCapitalized: "Days",
    day: "Day",
    dayAmmount: "{n} day | {n} days",
    dayOrder: "Day {0}",
    dayRoute: "Itinerary of the day",
    itineraryItem: "Point of interest",
    itineraryItems: "Points of interest",
    startItem: "Starting point of the day",
    finishItem: "End point of the day",
    itemOrder: "Point of interest {0}",


    theme: "Category",
    culture: "Culture",
    food: "Food",
    adventure: "Adventure",
    nature: "Nature ",

    address: "Address",
    country: "Country",
    countries: "Countries",
    length: "Length",
    assessments: "Reviews",
    publishedFem: "Published",
    publishedMasc: "Published",
    publishedPlural: "Published",
    hiddenFem: "Hidden",
    hiddenMasc: "Hidden",
    hiddenPlural: "Hidden",
    numberOfTravelTips: "X trips",
    footerText: "Exploring has never been easier",
    termsOfUse: "Terms and conditions",
    principlesDataProcessing: "Personal data processing policy",
    signInAsAdmin: "Log in as Author",
    redirectToHome: "Return home",
    showResultsa: "Show results",
    all: "All",
    allFemAndLifeless: "All",
    allNeutral: "All",
    allMascLiving: "All",

    transport: "Means of transportation",
    walk: "Walk",
    flight: "Airplane",
    bus: "Bus",
    boat: "Boat",
    train: "Train",
    car: "Car",
    bike: "Bike",
    publicTransport: "Public transportation (city)",
    scooter: "Scooter",
    rollerScate: "Inline skates",
    cableCar: "Cable car",

    difficulty: "Difficulty rating",
    low: "Easy",
    medium: "Moderate",
    high: "Difficult",
    noDifficulty: "No difficulty assigned",
    mediumHigh: "Moderate to difficult",
    lowMedium: "Easy to moderate",
    lowHigh: "Easy to difficult",

    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
}