export default {
  errorSubmittingForm: "Chyba při odesílání formuláře.",
  foundErrorsInForms: "Nalezeno {n} chyb ve formulářích | Nalezena {n} chyba ve formulářích | Nalezeny {n} chyby ve formulářích",
  foundErrorsInForm: "Nalezeno {n} chyb ve formuláři | Nalezena {n} chyba ve formuláři | Nalezeny {n} chyby ve formuláři",
  contactFormSubmit: "Kontaktní formulář byl úspěšně odeslán!",
  emptyItemCreated: "Nový slepý bod byl úspěšně vytvořen!",
  emptyItemCreateError: "Chyba při vytváření slepého bodu.",
  defaultItemCreated: "Nová zastávka byla úspěšně vytvořena",
  startItemCreated: "Nový výchozí bod dne byl úspěšně vytvořen!",
  finishItemCreated: "Nový cílový bod dne byl úspěšně vytvořen!",
  dayCreateError: "Chyba při vytváření dne",
  dayDeleteError: "Chyba při odstraňování dne",
  defaultItemCreateError: "Chyba při vytváření zastávky",
  defaultItemUpdateError: "Chyba při ukládání zastávky",
  defaultItemDeleteError: "Chyba při odstraňování zastávky",
  emptyItemDeleted: "Slepý bod byl úspěšně odstraněn",
  defaultItemDeleted: "Zastávka byla úspěšně odstraněna",
  commentCreated: "Komentář byl úspěšně vytvořen.",
  commentCreateError: "Chyba při vytváření komentáře!",
  loginError: "Nesprávné přihlašovací údaje!",
  loginSuccessful: "Byl jsi úspěšně přihlášen!",
  emailSendError: "Chyba při odesílání emailu.",
  emailSend: "Email s odkazem k registraci byl úspěšně odeslán.",
  campaignCreated: "Kampaň byla úspěšně vytvořena.",
  campaignCreateError: "Chyba při vytváření kampaně",
  campaignUpdated: "Kampaň byla úspěšně uložena",
  campaignUpdateError: "Chyba při ukládání kampaně",
  campaignDeleted: "Kampaň byla úspěšně odstraněna.",
  campaignDeleteError: "Chyba při odstraňování kampaně",
  unsupportedFormat: "Formát typu {0} není podporován!",
  maxiumUnassignedPictures: "Maximální počet nepřiřazených fotek k výletům nebo zastávkám je 150!",
  pictureCreateError: "Chyba při vytváření fotky",
  pictureUpdateForbiddenError: "Nemůžeš editovat fotku pokud je u výletu, který není ve stavu 'Rozpracovaný' nebo 'K přepracování'",
  pictureDeleteForbiddenError: "Nemůžeš odstranit fotku pokud je u výletu, který není ve stavu 'Rozpracovaný' nebo 'K přepracování'",
  pictureUpdated: "Fotka byla úspěšně upravena",
  pictureDeleted: "Fotka byla úspěšně odstraněna.",
  pictureCreated: "Fotka byla úspěšně vytvořena.",
  picturesCreated: "Fotky byly úspěšně vytvořeny.",
  dayCreated: "Nový den byl úspěšně vytvořen!",
  dayUpdateError: "Chyba při ukládání dne",
  travelTipSentToRework: "Výlet byl poslán k přepracování uživateli {0}",
  travelTipToReworkError: "Chyba při odesílání výletu k přepracování",
  travelTipCreateError: "Chyba při vytváření výletu",
  travelTipUpdated: "Výlet byl úspěšně uložen",
  travelTipChangesUpdated: "Změny výletu se úspěšně uložily",
  travelTipUpdatedAndSentToApprove: "Výlet byl úspěšně uložen a odeslán ke schválení!",
  travelTipUpdatedAndSentToControl: "Výlet byl úspěšně uložen a odeslán ke kontrole!",
  travelTipNoDaysOrItemsError: "Výlet musí obsahovat dny a zastávky, aby mohl být poslán ke kontrole!",
  maxInProgressTravelTips: "Maximální počet výletů v ve stavu 'Rozpracovaný' na uživatele je 10",
  errorsInForms: "Chyby ve formulářích: {0}",
  dayDeleted: "Den úspěšně smazán",
  travelTipDeleted: "Výlet byl úspěšně odstraněn",
  travelTipDeleteError: "Chyba při mazání výletu",
  travelTipDeclined: "Výlet byl úspěšně zamítnut",
  travelTipStateChangeFormErrors: "Není možné změnit stav výletu. Ve formulářích byly nalezeny chyby. Počet chyb ve formulářích: {0}",
  travelTipPublished: "Výlet byl úspěšně publikován!",
  travelTipUnpublished: "Výlet úspěšně skryt!",
  authorPublishChange: "Změna publikace autora proběhla úspěšně.",
  authorPublishChangeError: "Chyba při změně publikace autora.",
  unknownError: "Neznámá chyba",
  invalidEmailAddress: "Neplatná e-mailová adresa",
  preferredLanguageChanged: "Preferovaný jazyk byl úspěšně změněn",
  preferredLanguageChangeError: "Neznámá chyba při změně preferovaného jazyka",
  userUpdateError: "Neznámá chyba při ukládání uživatele",
  userCreateError: "Neznámá chyba při vytváření uživatele",
  userBlocked: "Uživatel byl úspěšně zablokován.",
  userBlockError: "Neznámá chyba při blokování uživatele",
  userUnblocked: "Uživatel byl úspěšně odblokován.",
  userUnblockError: "Neznámá chyba při odblokování uživatele.",
  userAnonymized: "Uživatel byl úspěšně anonymizován.",
  userCreated: "Uživatel byl úspěšně vytvořen.",
  userUpdated: "Uživatel byl úspěšně upraven!",
  registrationSuccessful: "Úspěšně ses zaregistroval/a.",
  registrationLinkExpiredError: "Odkaz pro registraci je neplatný nebo jeho platnost vypršela.",
  registrationError: "Neznámá chyba při pokusu o registraci, zkus to prosím znovu.",
  loginRecaptchaError: "Přihlášení selhalo kvůli ReCaptche, zkus to prosím znovu.",
  recaptchaValidationError: "Chyba při validování recaptchi",
  messageSent: "Tvá zpráva byla úspěšně odeslána.",
  userAnonymizeError: "Chyba při anonymizaci uživatele",
  changesSaved: "Změny byly úspěšně uloženy",
  passwordResetLinkExpiredError: "Platnost odkazu na změnu hesla vypršela!",
  passwordResetLinkSent: "Odkaz pro změnu hesla jsme ti poslali na e-mail.",
  loginDoesNotExistError: "Účet s e-mailovou adresou '{0}' neexistuje!",
  tagCategoryCreated: "Kategorie byla úspěšně vytvořena.",
  tagCategoryCreateError: "Chyba při vytváření kategorie.",
  tagCategoryUpdated: "Kategorie byla úspěšně upravena.",
  tagCategoryUpdateError: "Chyba při upravování kategorie.",
  tagCategoryDeleted: "Kategorie byla úspěšně smazána.",
  tagCategoryDeleteError: "Chyba při mazání kategorie.",
  tagCreated: "Tag byl úspěšně vytvořen.",
  tagCreateError: "Chyba při vytváření tagu.",
  tagCreateErrorExists: 'Tag s názvem "{0}" již existuje',
  tagUpdated: "Tag byl úspěšně upraven.",
  tagUpdateError: "Chyba při ukládání tagu.",
  tagDeleted: "Tag byl úspěšně smazán.",
  tagDeleteError: "Chyba při mazání tagu.",
  equipmentCategoryCreated: "Kategorie byla úspěšně vytvořena.",
  equipmentCategoryCreateError: "Chyba při vytváření kategorie.",
  equipmentCategoryUpdated: "Kategorie byla úspěšně upravena.",
  equipmentCategoryUpdateError: "Chyba při upravování kategorie.",
  equipmentCategoryDeleted: "Kategorie byla úspěšně smazána.",
  equipmentCategoryDeleteError: "Chyba při mazání kategorie.",
  equipmentCreated: "Vybavení bylo úspěšně vytvořeno.",
  equipmentCreateError: "Chyba při vytváření vybavení.",
  equipmentUpdated: "Vybavení bylo úspěšně upraveno.",
  equipmentUpdateError: "Chyba při ukládání vybavení.",
  equipmentDeleted: "Vybavení bylo úspěšně smazáno.",
  equipmentDeleteError: "Chyba při mazání Vybavení.",
  equipmentTemplateCreated: "šablona byla úspěšně vytvořeno.",
  equipmentTemplateCreateError: "Chyba při vytváření šablony.",
  equipmentTemplateUpdated: "Šablona byla úspěšně upraveno.",
  equipmentTemplateUpdateError: "Chyba při ukládání šablony.",
  equipmentTemplateDeleted: "Šablona byla úspěšně smazáno.",
  equipmentTemplateDeleteError: "Chyba při mazání šablony.",
  localizationApprovalsError: "Některé z formulářů nemají schválený překlad",
};