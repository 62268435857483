export default {
    travelTipCampaign: "Campaign",
    travelTipTitle: "Trip title",
    travelTipShortInfo: "Subtitle",
    travelTipPerex: "Lead paragraph",
    travelTipDescription: "Trip description",
    travelTipHashtags: "Hashtags",
    travelTipTheme: "Theme",
    travelTipVideoDescription: "Video description",
    travelTipVideoUrl: "Video URL",

    dayTitle: "Day title",
    dayDescription: "Day description",
    dayPicture: "Day title photo",

    itemTitle: "Point of interest title",
    itemDescription: "Point of interest description",
    itemLatitude: "Latitude",
    itemLongitude: "Longitude",
    itemDuration: "Estimated time spent at this point of interest. (min)",
    itemRouteLength: "Route length (km)",
    practicalInfo: "Practical info",
    countries: "Countries",
    country: "Country",
    difficulty: "Difficuly rating",
    transport: "Transport",

    email: "E-mail",
    login: 'E-mail',
    password: 'Password',
    username: "Nickname",
    repeatPassword: "Repeat password",

    aboutAuthor: "Short info",
    role: "Role",
    firstName: "Given name(s)",
    lastName: "Last name",
    dateOfBirth: "Date of birth",
    phoneNumber: "Phone number",
    city: "City/Town",
    street: "Street name",
    houseNumber: "Street number",
    zipCode: "Postal code",
    additionalInfo: "Additional info to address",
    authorEmail: "Author's e-mail",
    campaignTitle: "Title",
    campaignDescription: "Description",
    campaignValidFrom: "Valid from",
    campaignValidTo: "Valid to",
    pictureTitle: "Photo caption",
    pictureInputFile: "Attached file",
    assignedToTravelTip: "Assigned to trip",
    name: "Name",
    message: "Message",
    language: "Language",

    state: "State",
    originalLanguage: "Original language",
    chooseOriginalLanguage: "Choose original language",
    choosePrimaryLanguage: "Choose primary language",
    showTranslation: "Show translation",

    author: "Author",
    contractSigned: "Contract signed",

    pinboardTitle: "Pinboard title",
    pinboardDescription: "Pinboard description",
    pinboardPostTitle: "Title",
    pinboardPostDescription: "Post description",
    tripLength: "Trip length",
    leaveEarliestOn: "I wll leave earliest on",
    returnLatestOn: "I will return latest on",

    categoryTitle: "Category title",
  }