<template>
    <div class="textarea-wrapper">
        <textarea 
            ref="textarea"
            :id="id"
            :class="{error, 'white-bg': whiteBg, 'dark-bg': darkBg, [textareaClass]: textareaClass}"
            :style="textareaStyle"
            :value="value"
            :required="required"
            :readonly="disabled"
            v-bind="$attrs"
            @input="$emit('input', $event.target.value)"
            @blur="onBlur"
            v-on="height ? {mousedown: onMouseDown} : {}"
            :tabIndex="disabled ? -1 : 0">
        </textarea>
        <label v-if="label"
            :for="id"
            :data-required="required">
            {{ label }}
        </label>
        <div v-if="info" class="info-icon" v-tooltip="{content: info, placement: 'right-start'}"></div>
        <span v-if="error" class="input-alert">{{ error }}</span>
        <span v-if="hint" class="input-hint">{{ hint }}</span>
    </div>
  </template>
  
  <script>

  export default {
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
      },
      value: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      error: {
        type: String,
      },
      info: {
        type: String
      },
      hint: {
        type: String
      },
      height: {
        type: String
      },
      padding: {
        type: String
      },
      whiteBg: {
        type: Boolean
      },
      darkBg: {
        type: Boolean
      },
      textareaClass: {
        type: String
      }
    },

    data() {
      return {
        isResizeListenerDisabled: false,

      }
    },

    computed: {
      textareaStyle() {
        const style = {}
        if (this.height) {
          style.height = this.height
        }
        if (this.padding) {
          style.padding = this.padding
        }
        return style
      },
    },

    methods: {
      onBlur(event) {
        if (this.trim) {
          this.$emit('input', event.target.value.trim())
        }
        this.$emit('blur', event)
      },
      resizeTextArea(height) {
        this.$nextTick(() => {
          if (this.currentHeight != height) {
            const textareaRef = this.$refs.textarea
            if (textareaRef) {
              textareaRef.style.height = height + "px"
            }
          }
        })
      },
      onMouseDown() {
        this.initialHeight = this.$refs.textarea.offsetHeight;
        window.addEventListener('mouseup', this.onMouseUp);
      },
      onMouseUp() {
        this.$nextTick(() => {
          const currentHeight = this.$refs.textarea.offsetHeight;
          if (this.initialHeight !== currentHeight) {
            this.$emit('resizeTextarea', {id: this.id, height: currentHeight})
          }
          window.removeEventListener('mouseup', this.onMouseUp);
        })
      },
    },

    mounted() {
        this.currentHeight = this.$refs.textarea.height
    },

    inheritAttrs: false,
  };
  </script>
  
  <style scoped lang="scss">
    @import '@/scss/variables';

    .textarea-wrapper {
        max-width: 700px;
        width: 100%;
        position: relative;
        min-height: 60px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        
        label {
            position: absolute;
            font-size: 11px;
            color: $tr-gray;
            left: 20px;
            top: 7px;
            z-index: 2;
            
            &[data-required]::after {
                content: "*";
                margin-left: 3px;
            }
        }

        textarea {
            padding: 22px 18px 22px 18px;
            height: 100%;
            width: 100%;
            vertical-align: top;
            border: 2px solid transparent;
            background-color: $tr-white-alt;

            &.white-bg {
              background-color: $tr-white;
            }
            &.dark-bg {
              background-color: $tr-light-gray
            }

            &:read-only {
              border: none !important;
              outline: none !important;
              color: $tr-gray !important;
              cursor: default;

              & + label {
                color: $tr-gray;
              }
            }
            
            &.error {
                border-bottom: $tr-pink solid 2px !important;
            }

            &:focus {
                border: 2px solid $tr-green;
            }

            &:disabled, &:read-only &:read-write {
                color: $tr-gray;

                & + label {
                  color: $tr-mid-gray;
                }
            }

            &::placeholder {
              color: $tr-gray;
              opacity: 1;
            }

            &::-ms-input-placeholder {
              color: $tr-gray;
            }
        }

        .info-icon {
          position: absolute;
          right: 20px;
          top: 7px;
        }

        .input-hint {
          color: $tr-gray;
          font-size: 12px;
        }

        .input-alert {
          color: $tr-pink;
          font-size: 12px;
        }
    }

  </style>
  