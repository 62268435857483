
import { apolloClient } from "@/api/apolloClient";
import {CREATE_USER, REGISTER_USER, UPDATE_USER, UPDATE_USER_WITH_PASSWORD, UPDATE_USER_AVATAR, DELETE_USER_AVATAR, LOCK_USER, BLOCK_USER, DELETE_USER, REQUEST_PASSWORD_RESET, RESET_PASSWORD_BY_TOKEN, SEND_INVITATION_EMAIL, REGISTER_AUTHOR, REGISTER_AUTHOR_WITH_TOKEN, UPDATE_AUTHOR_WITH_PASSWORD, UPDATE_AUTHOR, SET_HAS_LICENCE_AGREEMENT_SIGNED, SET_IS_AUTHOR_PUBLISHED, UNBLOCK_USER, SET_PREFERRED_LANGUAGE_FOR_CURRENT_USER} from "../api/graphql/mutation/UserMutation";
import { USER_PREFERRED_LANGUAGE } from "../api/graphql/query/UserQuery";

export default {
    createUser: function(form){
        console.log("Start to creating user with name "+ form.userName);
        const {password, userLogin, userName, firstName, lastName, role} = form;
        return apolloClient.mutate({
            mutation: CREATE_USER,
            variables: {
                password,
                userLogin,
                userName,
                firstName,
                lastName,
                role
            },
        }).then((result) => {
            console.log("User created: ", result);
            return result;
        });
    },

    registerUser: function(form, recaptchaToken){
        console.log("Start to register user with name "+ form.userName);
        const {password, userLogin, userName, firstName, lastName, language} = form;
        return apolloClient.mutate({
            mutation: REGISTER_USER,
            variables: {
                password,
                userLogin,
                userName,
                firstName,
                lastName,
                recaptchaToken,
                language
            },
        }).then((result) => {
            console.log("User registered: ", result);
            return result;
        });
    },

    registerAuthor: function(form, recaptchaToken){
        console.log("Start to register author with name "+ form.userName);
        const {password, userLogin, userName, phoneNumber, address, firstName, lastName, dateOfBirth, language} = form;
        return apolloClient.mutate({
            mutation: REGISTER_AUTHOR,
            variables: {
                password,
                userLogin,
                userName,
                phoneNumber,
                recaptchaToken,
                address: {...address, zipCode: parseInt(address.zipCode)},
                firstName,
                lastName,
                dateOfBirth,
                language
            },
        }).then((result) => {
            console.log("Author registered: ", result);
            return result;
        });
    },

    registerAuthorWithToken: function(form, recaptchaToken, invitationToken){
        console.log("Start to register author with name "+ form.userName);
        const {password, userLogin, userName, phoneNumber, address, firstName, lastName, dateOfBirth, language} = form;
        return apolloClient.mutate({
            mutation: REGISTER_AUTHOR_WITH_TOKEN,
            variables: {
                password,
                userLogin,
                userName,
                phoneNumber,
                recaptchaToken,
                address: {...address, zipCode: parseInt(address.zipCode)},
                firstName,
                lastName,
                dateOfBirth,
                invitationToken,
                language
            },
        }).then((result) => {
            console.log("Author registered: ", result);
            return result;
        });
    },

    updateUser: function(changePassword, userId, form){
        console.log("Updating user with id " + userId);
        if (changePassword) {
            const {password, userLogin, userName, firstName, lastName, phoneNumber, role} = form;
            return apolloClient.mutate({
                mutation: UPDATE_USER_WITH_PASSWORD,
                variables: {
                    userId,
                    password,
                    userLogin,
                    userName,
                    firstName,
                    lastName,
                    phoneNumber,
                    role
                }
            }).then((result) => {
                console.log("User updated: ", result);
                return result;
            });
        } else {
            const { userLogin, userName, firstName, lastName, phoneNumber, role} = form;
            return apolloClient.mutate({
                mutation: UPDATE_USER,
                variables: {
                    userId,
                    userLogin,
                    userName,
                    firstName,
                    lastName,
                    phoneNumber,
                    role
                }
            }).then((result) => {
                console.log("User updated: ", result);
                return result;
            });
        }
    },

    updateAuthor: function(changePassword, userId, form){
        console.log("Updating user with id " + userId);
        console.log("change password:", changePassword)
        if (changePassword) {
            const { password, userLogin, userName, firstName, lastName, phoneNumber, about, address, dateOfBirth, role} = form;
            return apolloClient.mutate({
                mutation: UPDATE_AUTHOR_WITH_PASSWORD,
                variables: {
                    userId,
                    role,
                    password,
                    userLogin,
                    userName,
                    firstName,
                    lastName,
                    phoneNumber,
                    about,
                    address: {...address, countryId: address.countryId ? address.countryId : 60, zipCode: parseInt(address.zipCode)},
                    dateOfBirth,
                }
            }).then((result) => {
                console.log("User updated: ", result);
                return result;
            });
        } else {
            const { userLogin, userName, firstName, lastName, phoneNumber, about, address, dateOfBirth, role} = form;
            return apolloClient.mutate({
                mutation: UPDATE_AUTHOR,
                variables: {
                    userId,
                    role,
                    userLogin,
                    userName,
                    firstName,
                    lastName,
                    phoneNumber,
                    about,
                    address: {...address, countryId: address.countryId ? address.countryId : 60},
                    dateOfBirth,
                }
            }).then((result) => {
                console.log("User updated: ", result);
                return result;
            });
        }
    },

    updateUserAvatar: function(id, avatar){
        console.log("Updating Avatar with userId " + id);
            return apolloClient.mutate({
                mutation: UPDATE_USER_AVATAR,
                variables: {
                    id: id,
                    avatar: avatar
                },
            }).then((result) => {
                console.log("User Avatar updated: ", result);
                return result;
            }).catch(err => console.log(err));
    },
    deleteUserAvatar: function(id){
        console.log("Deleting user avatar with id " + id);
            return apolloClient.mutate({
                mutation: DELETE_USER_AVATAR,
                variables: {
                    id,
                },
            }).then((result) => {
                console.log("UserAvatarDeleted: ", result);
                return result;
            });
    },

    deleteUser: function(userId) {
        return apolloClient.mutate({
            mutation: DELETE_USER,
            variables: {
                userId
            },
            // update: (store) => {
            //     const queries = [
            //         { query: USERS_SHORT_LIST}, {query: FILTERED_USERS }
            //     ]
            //     const data = queries.map(query => store.readQuery(query))
            //     data.forEach(({ users: list }) => {
            //         const index = list.findIndex(o => o.id === userId)
            //         if (index !== -1) {
            //             list.splice(index, 1)
            //         }
            //     })
            //     queries.forEach((query, index) => {
            //         store.writeQuery({
            //             ...query,
            //             data: data[index],
            //         })
            //     })
            // },
        }).then((result) => {
            console.log("User deleted: ", result);
            return result;
        });
    },

    lockUser: function(userId) {
        return apolloClient.mutate({
            mutation: LOCK_USER,
            variables: {
                userId: parseInt(userId)
            },

        }).then((result) => {
            console.log("User locked: ", result);
            return result;
        });
    },

    blockUser: function(userId) {
        return apolloClient.mutate({
            mutation: BLOCK_USER,
            variables: {
                userId: parseInt(userId)
            },

        }).then((result) => {
            console.log("User blocked: ", result);
            return result;
        });
    },

    unblockUser: function(userId) {
        return apolloClient.mutate({
            mutation: UNBLOCK_USER,
            variables: {
                userId: parseInt(userId)
            },

        }).then((result) => {
            console.log("User Unblocked: ", result);
            return result;
        });
    },

    requestPasswordReset: function(userLogin, recaptchaToken){
        console.log("Sending email to: " + userLogin);
            return apolloClient.mutate({
                mutation: REQUEST_PASSWORD_RESET,
                variables: {
                    userLogin,
                    recaptchaToken
                },
            }).then((result) => {
                console.log("Email with reset token sent sucessfully: ", result);
                return result;
            })
    },

    resetPasswordByToken: function(token, password){
            return apolloClient.mutate({
                mutation: RESET_PASSWORD_BY_TOKEN,
                variables: {
                    token,
                    password
                },
            }).then((result) => {
                console.log("Password: ", result);
                return result;
            })
    },

    sendInvitationEmail: function(email, language){
        console.log("Sending email to: " + email);
            return apolloClient.mutate({
                mutation: SEND_INVITATION_EMAIL,
                variables: {
                    email,
                    language
                },
            }).then((result) => {
                console.log("Email with registration link sent sucessfully: ", result);
                return result;
            })
    },

    setHasLicenceAgreementSigned: function(id){
        console.log("Setting licence agreement signed to User with id: " + id);
            return apolloClient.mutate({
                mutation: SET_HAS_LICENCE_AGREEMENT_SIGNED,
                variables: {
                    id
                },
            }).then((result) => {
                console.log("Licence agreement signed succesfully: ", result);
                return result;
            })
    },

    setIsAuthorPublished: function(id, isAuthorPublished){
        console.log(`Setting is author with id ${id} published to : ${isAuthorPublished}`);
            return apolloClient.mutate({
                mutation: SET_IS_AUTHOR_PUBLISHED,
                variables: {
                    id,
                    isAuthorPublished
                },
            }).then((result) => {
                console.log("Is author published state changed succesfully: ", result);
                return result;
            })
    },

    setPreferredLanguageForCurrentUser: function(language){
        console.log(`Setting preferred language for current user. Language: ${language}`);
            return apolloClient.mutate({
                mutation: SET_PREFERRED_LANGUAGE_FOR_CURRENT_USER,
                variables: {
                    language
                },
                update: async (store) => {
                    const newData = {preferredLanguageForCurrentUser: language}
                    store.writeQuery({query: USER_PREFERRED_LANGUAGE, data: newData})
                }
            }).then((result) => {
                console.log("Preferred language changed succesfully: ", result);
                return result;
            })
    },

    
}