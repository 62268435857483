export default {
    difficulties: "Náročnosti",
    difficultyText1: "Celková náročnost výletu závisí na jeho částech. Itineráře výletů skládáme ze dnů a dny z jednotlivých zastávek. Pro lepší představu přiřazujeme náročnost ke každé zastávce zvlášť. Vypovídá pak o tom, jak těžké je dostat se na danou zastávku a absolvovat jí. Jednoznačně tedy zjistíte, která část dne bude nejnáročnější. ",
    difficultyText2: "Náročnost je kombinací několika různých oblastí. Nejde jen čistě o fyzickou obtížnost, ale i přístupnost místa, terén a další okolní vlivy, jak složité je zorientovat se a místo najít, jestli jsou k tomu potřeba nějaké speciální schopnosti, znalosti nebo dovednosti (například pohyb po ledovci) a jestli vám s tím na daném místě někdo může pomoci (například předem objednaný placený průvodce). Snažíme se zohledňovat i umístění zastávky v rámci dne a výletu. Náročnost dne a výletu s různě obtížnými zastávkami se pak ukazuje jako rozsah všech náročností (např. nízká-střední). ",
    lowDifficulty: "Lehká náročnost",
    lowDifficultyText: "Nízkou náročnost mají zastávky, které by běžný zdravý dospělý člověk měl zvládnout v pohodě a velký problém by neměly představovat ani pro aktivnější seniory a děti. Pokud jsou během dne nízkou náročností označeny všechny zastávky, celková denní trasa například pěšky nepřesahuje 12 km nebo na kole 40 km. Veřejnými dopravními prostředky se pak nemusíte kodrcat celý den. Cesty na místo jsou dobře značené (oficiální turistické značky, cyklostezky aj.), dobře schůdné (silničky, polní a lesní cesty), bez velkého a prudkého převýšení a můžete je absolvovat s běžným základním vybavením, znalostmi a dovednostmi vyplývajícími z typu výletu či dne (například pokud jde o cyklovýlet, běžné vybavení může být kolo, na kterém umíte jezdit, přilba, sportovní oblečení, pití a svačina, kterou máte kam dát). V popisu výletu nebo dne může být uvedeno případné další speciální vybavení (například brašny na kolo, pokud se jedná o vícedenní putování na kole). Nízkou náročnost mohou mít i některé jednotlivé zastávky celkově delších nebo náročnějších tras, pokud cesta na danou zastávku splňuje tato kritéria (je jasná, nenáročným terénem, dobře značená atd.), aby se rozlišila náročnost jednotlivých úseků cesty.  ",
    mediumDifficulty: "Střední náročnost",
    mediumDifficultyText: "Středně náročná zastávka je v jedné nebo více (ale ne ve všech) oblastech o něco náročnější než zastávka s nízkou náročností. Může to být například sice krátká vzdálenost, ale v náročnějším terénu, kde vede neznačená cesta. Může to taky být pěkná značená cesta, na které je vysoké převýšení, nebo která je celkově delší než lehká (délka denní trasy mezi 12 a 30 km pěšky, 40 a 80 km na kole). ",
    highDifficulty: "Vysoká náročnost",
    highDifficultyText: "Vysoká náročnost značí zastávku, která je ve více oblastech výrazně náročnější než lehká zastávka. Pro její absolvování je potřeba speciální trénink (ať už fyzický, v nějaké konkrétní dovednosti, jako například orientace v mapě, nebo kombinaci fyzické zdatnosti a dovedností) a/nebo i výbavu. Může to být například cesta v zimních horách (jde o náročnější pomínky orientace i pohybu, tím pádem je potřeba i vyšší fyzická zdatnost a speciální vybavení jako jsou mačky).",
}