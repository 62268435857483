import Vue from 'vue'
import VueRouter from 'vue-router'

// import HomePage from "@/pages/HomePage";
// import TravelTipDetailPage from '@/pages/TravelTipDetailPage';
// import RegistrationPage from '@/pages/RegistrationPage'
// import LoginPage from '@/pages/LoginPage'
// import ResetPasswordPage from "@/pages/ResetPasswordPage";
// import AdminHomePage from "@/pages/admin/AdminHomePage";
// import AdminRootPage from "@/pages/admin/AdminRootPage";
// import AdminLoginPage from "@/pages/admin/AdminLoginPage";
// import CampaignListPage from "@/pages/admin/CampaignListPage";
// import ManageCampaignPage from "@/pages/admin/ManageCampaignPage";
// import PictureListPage from "@/pages/admin/PictureListPage";
// import ManagePicturePage from "@/pages/admin/ManagePicturePage";
// import TravelTipListPage from "@/pages/admin/TravelTipListPage";
// import ManageTravelTipPage from "@/pages/admin/ManageTravelTipPage";
// import UserListPage from "@/pages/admin/UserListPage";
// import ManageUserPage from "@/pages/admin/ManageUserPage";
import {Role} from "../enum/role";
// import SearchResultPage from "@/pages/SearchResultPage";
// import AboutPage from "@/pages/static/AboutPage";
// import ContactPage from "@/pages/static/ContactPage";
// import HowToPage from "@/pages/static/HowToPage";

Vue.use(VueRouter);

const routes = [
    { 
        path: '*',
        component: () => import("@/pages/NotFound")
    },
    {
        path: '/',
        alias: '/homePage',
        name: 'HomePage',
        component: () => import("@/pages/HomePage")
    },
    {
        path: '/search',
        name: 'searchResult',
        component: () => import("@/pages/SearchResultPage"),
    },
    {
        path: '/travelTipDetail/:travelTipId?',
        name: 'travelTipDetail',
        component: () => import("@/pages/TravelTipDetailPage"),
        props: true
    },
    {
        path: '/about',
        name: 'about',
        component: () => import("@/pages/static/AboutPage")
    },
    {
        path: '/workWithUs',
        name: 'WorkWithUs',
        component: () => import("@/pages/static/LandingPage")
    },
    {
        path: '/principlesDataProcessing',
        name: 'PrinciplesDataProcessing',
        component: () => import("@/pages/static/PrinciplesDataProcessingPage")
    },
    {
        path: '/termsOfUse',
        name: 'TermsOfUse',
        component: () => import("@/pages/static/TermsOfUsePage")
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import("@/pages/static/ContactPage")
    },
    {
        path: '/howTo',
        name: 'howTo',
        component: () => import("@/pages/static/HowToPage")
    },
    {
        path: '/register',
        name: 'registration',
        component: () => import("@/pages/RegistrationPage")
    },
    {
        path: '/registerAuthor/:token?',
        name: 'authorRegistration',
        component: () => import("@/pages/AuthorRegistrationPage")
    },
    {
        path: '/login',
        name: 'login',
        component: () => import("@/pages/LoginPage")
    },
    {
        path: '/resetPassword/:token?',
        name: 'resetPassword',
        component: () => import("@/pages/ResetPasswordPage"),
    },
    {
        path: '/adminLogin',
        name: 'adminLogin',
        component: () => import("@/pages/admin/AdminLoginPage"),
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {
            authorize: [Role.User, Role.Admin, Role.Admin_supervisor]
        },
        component: () => import("@/pages/ProfilePage"),
    },
    {
        path: '/myTravelTips',
        name: 'myTravelTips',
        meta: {
            authorize: [Role.User, Role.Author, Role.Admin, Role.Admin_supervisor]
        },
        component: () => import("@/pages/MyTravelTipsPage"),
    },
    {
        path: '/pinboard',
        name: 'pinboard',
        component: () => import("@/pages/PinboardPostsPage"),
    },
    // {
    //     path: '/pinboardPosts/:pinboardId',
    //     name: 'pinboardPosts',
    //     component: () => import("@/pages/PinboardPostsPage"),
    // },
    {
        path: '/messages',
        name: 'messages',
        meta: {
            authorize: [Role.User, Role.Author, Role.Admin, Role.Admin_supervisor]
        },
        component: () => import("@/pages/MessagesPage"),
    },
    {
        path: '/adminTravelTipPreview/:travelTipId?',
        name: 'adminTravelTipPreview',
        meta: {
            authorize: [Role.Author, Role.Admin, Role.Admin_supervisor]
        },
        component: () => import("@/pages/admin/AdminTravelTipPreviewPage"),
    },
    {
        path: '/admin',
        component: () => import("@/pages/admin/AdminRootPage"),
        meta: {
            authorize: [Role.Author, Role.Admin, Role.Admin_supervisor]
        },
        children: [
            {
                path: '',
                name: 'adminHomePage',
                meta: {
                    authorize: [Role.Admin, Role.Admin_supervisor]
                },
                component: () => import("@/pages/admin/AdminHomePage"),
            },
            {
                path: 'campaigns',
                name: 'adminCampaignList',
                meta: {
                    authorize: [Role.Admin, Role.Admin_supervisor]
                },
                component: () => import("@/pages/admin/CampaignListPage"),
            },
            {
                path: 'manageCampaign/:campaignId?',
                name: 'adminManageCampaign',
                meta: {
                    authorize: [Role.Admin, Role.Admin_supervisor]
                },
                component: () => import("@/pages/admin/ManageCampaignPage"),
            },
            {
                path: 'travelTips',
                name: 'adminTravelTips',
                meta: {
                    authorize: [Role.Author, Role.Admin, Role.Admin_supervisor]
                },
                component: () => import("@/pages/admin/TravelTipListPage"),
            },
            {
                path: 'manageTravelTip/:travelTipId?',
                name: 'adminManageTravelTip',
                meta: {
                    authorize: [Role.Author, Role.Admin, Role.Admin_supervisor]
                },
                component: () => import("@/pages/admin/ManageTravelTipPage"),
            },
            {
                path: 'pictures',
                name: 'adminPictures',
                meta: {
                    authorize: [Role.Author, Role.Admin, Role.Admin_supervisor]
                },
                component: () => import("@/pages/admin/PictureListPage"),
            },
            {
                path: 'managePicture/:pictureId?',
                name: 'adminManagePicture',
                meta: {
                    authorize: [Role.Author, Role.Admin, Role.Admin_supervisor]
                },
                component: () => import("@/pages/admin/ManagePicturePage"),
            },
            {
                path: 'users',
                name: 'adminUsers',
                meta: {
                    authorize: [Role.Admin_supervisor, Role.Admin]
                },
                component: () => import("@/pages/admin/UserListPage"),
            },
            {
                path: 'manageUser/:userId?',
                name: 'adminManageUser',
                meta: {
                    authorize: [Role.Author, Role.Admin, Role.Admin_supervisor]
                },
                component: () => import("@/pages/admin/ManageUserPage"),
            },
            {
                path: 'authorInvitation',
                name: 'adminAuthorInvitation',
                meta: {
                    authorize: [Role.Admin, Role.Admin_supervisor]
                },
                component: () => import("@/pages/admin/InviteAuthorPage"),
            },
            {
                path: 'manageTags',
                name: 'adminTags',
                meta: {
                    authorize: [Role.Admin, Role.Admin_supervisor]
                },
                component: () => import("@/pages/admin/ManageTagsPage"),
            },            {
                path: 'manageEquipments',
                name: 'adminEquipments',
                meta: {
                    authorize: [Role.Admin, Role.Admin_supervisor]
                },
                component: () => import("@/pages/admin/ManageEquipmentsPage"),
            },

        ],
    },

];

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            let element = document.querySelector(to.hash);
            element.scrollIntoView({ behavior: 'smooth', block: 'center'});
        } else if (savedPosition) {
            return savedPosition
        } else if (from.name === to.name) {
            return {}
        }
          else {   
            return {x: 0, y: 0}
        }
    }
});

router.beforeEach((to, from, next) => {
    const {authorize} = to.meta;
    const userRole = localStorage.getItem("role");
    const username = localStorage.getItem("username");

    if (authorize) {
        if (!username) {
            // not logged in so redirect to login page with the return url
            return next({path: '/login', query: {returnUrl: to.path}});
        }

        if (authorize.length && !authorize.includes(userRole)) {
            // role not authorised so redirect to home page
            return next({path: '/'});
        }
    }
    next();
});
export default router
