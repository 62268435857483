import Vue from 'vue'
import VueI18n from 'vue-i18n'
import localizationData from './localizationData'

Vue.use(VueI18n);

export default new VueI18n({
    lazy: true,
    locale: 'cs',
    messages: localizationData,
    pluralizationRules: {
        /**
         * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
         * @param choicesLength {number} an overall amount of available choices
         * @returns a final choice index to select plural word by
         */
        'cs': function(choice, choicesLength) {    
          if (choice === 0) {
            return 0;
          }
          if (choice === 1) {
            return 1;
          }
          if (choice < 5) {
            return 2
          }
          return 0
        }
      }
})