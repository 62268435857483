export default {
    title: "Zásady zpracování osobních údajů na platformě Trekio",
    introductionText1: "Trekio je cestovní platforma, která nabízí naplánované výlety. Na naší platformě se můžeš zaregistrovat se dvěma různými typy účtů. Buď jako běžný uživatel (typ User), což ti umožní prohlížet a hodnotit výlety a ukládat si ty, které se ti líbí. Nebo se můžeš zaregistrovat jako autor výletů (účet typu Author). Tento typ registrace ti navíc umožňuje psát výlety, které lze na naší platformě zveřejnit. Pro účely licenční smlouvy potřebujeme od autorů shromažďovat ještě dodatečné údaje.",
    introductionText2: "Tyto zásady zpracování osobních údajů vysvětlují, jak naše platforma používá osobní údaje, které od uživatelů shromažďuje, když ji používají.",
    topics: "Témata",
    whatDataDoWeCollect: "Jaké údaje shromažďujeme?",
    howDoWeCollectYourData: "Jak shromažďujeme tvé údaje?",
    howWillWeUseYourData: "Jak budeme tvoje údaje používat?",
    howDoWeStoreYourData: "Jak uchováváme tvé údaje?",
    whatAreYourDataProtectionRights: "Jaká jsou tvá práva spojená s ochranou osobních údajů?",
    whatAreCookies: "Co jsou soubory cookie?",
    howDoWeUseCookies: "Jak používáme soubory cookie?",
    whatTypesOfCookiesDoWeUse: "Jaké typy souborů cookie používáme?",
    howToManageYourCookies: "Jak spravovat soubory cookie?",
    privacyPoliciesOfOtherWebsites: "Zásady zpracování osobních údajů jiných stránek",
    changesToOurPersonalDataProcessingPolicy: "Změny zásad zpracování osobních údajů",
    howToContactUs: "Jak nás kontaktovat",
    howToContactTheAppropriateAuthorities: "Jak kontaktovat příslušný úřad",

    trekioCollectsFollowingData: "Platforma Trekio shromažďuje následující údaje:",
    personalIdentificationInformation: "Osobní identifikační údaje",
    personalIdentificationInformationUsers: "Pro typ účtu User: e-mailová adresa a volitelně (celé) jméno a fotografie (profilový obrázek).",
    personalIdentificationInformationAuthors: "Pro typ účtu Author: celé jméno, e-mailová adresa, datum narození, adresa a volitelně telefonní číslo a fotografie (profilový obrázek).",
    personalInformationInYourContent: "Pokud se v rámci svého obsahu (např. recenze nebo popis cesty, nebo když zanecháš vzkaz pro ostatní uživatele v rámci nástěnky pro spolucestování nebo připojeného systému pro zasílání zpráv) rozhodneš uvést osobní údaje, budou také uloženy v naší databázi jako součást tvého obsahu. Takové údaje ale aktivně nevyhledáváme, přistupujeme k nim jako k jakémukoli jinému obsahu.",

    weCollectDataWhenYou: "Shromažďujeme a zpracováváme údaje, které nám přímo poskytneš na platformě Trekio. Děje se tak, když:",
    weCollectDataOption1: "se zaregistruješ online (a staneš se tak uživatelem typu User nebo Author)",
    weCollectDataOption2: "jakožto Author nám zašleš vyplněnou licenční smlouvu",
    weCollectDataOption3: "dobrovolně vyplníš kontaktní formulář nebo nás kontaktuješ jiným způsobem (např. prostřednictvím e-mailu nebo sociálních sítí)",
    weCollectDataOption4: "dobrovolně nám poskytneš své údaje v rámci jakéhokoli obsahu, který na platformu vložíš (včetně recenzí, hodnocení a popisů výletů, výletů a jejich fotografií nebo zpráv na nástěnce pro spolucestování a v propojeném systému zpráv) – tyto údaje v obsahu aktivně nevyhledáváme a nezpracováváme jinak, než jakýkoli běžný obsah",

    dataCollectionPurpose: "Platforma Trekio shromažďuje údaje proto, abychom mohli:",
    dataCollectionPurposeText: "spravovat tvůj účet a veškerý obsah, který nám poskytneš, a pro související komunikaci (např. informovat tě o schvalovacím procesu ohledně výletů, které vytvoříš, nebo ti dát vědět v případě, že bychom museli zablokovat tvůj účet).",

    howDoWeStoreYourDataText1: "Trekio bezpečně ukládá údaje, které nám poskytneš prostřednictvím naší platformy, do naší zabezpečené databáze. Pokud nám poskytneš nějaké údaje prostřednictvím e-mailu nebo jiných komunikačních prostředků (např. sociálních sítí, Whatsappu atd.), mohou být uloženy také na serverech příslušných poskytovatelů služeb třetích stran. Podepsané licenční smlouvy jsou zálohovány na zabezpečeném zařízení.",
    howDoWeStoreYourDataText2: "Trekio uchovává tvé údaje po dobu nezbytně nutnou pro plnění našeho vzájemného právního vztahu a pro správné fungování platformy. (Např. svůj účet můžeš jako uživatel typu User kdykoli anonymizovat. Po provedení této akce již nebudeme uchovávat ani zpracovávat tvou e-mailovou adresu, která je jediným povinným osobním údajem nutným pro to, abys mohl/a účet mít. Pro správné fungování platformy však nejsme schopni vymazat veškerý obsah, který jsi poskytl/a na různých místech, např. recenze výletů nebo zprávy pro spolucestující. Necháváme tedy na zodpovědnosti každého uživatele, co bude v rámci svého obsahu sdílet. Pokud chceš konkrétní obsah smazat, můžeš tak ve většině případů učinit sám/sama – dokud máš funkční účet, nebo nás můžeš kontaktovat na naší e-mailové adrese info@trekio.com nebo prostřednictvím formuláře Napiš nám.) Po uplynutí této doby tvoje údaje vymažeme anonymizací nebo odstraněním z naší databáze.",
    howDoWeStoreYourDataText3: "Upozornění pro všechny uživatele typu Author: mějte prosím na paměti, že po schválení Výletu jsme povinni uchovávat vaše licenční smlouvy po celou dobu trvání licence. Musíme mít také možnost propojit tě (jako jednu ze stran licenční smlouvy) a tvoje Výlety (autorská díla). Proto sice můžeš svůj účet anonymizovat, ale nepovede to k úplnému odstranění všech tvých osobních údajů z našich systémů.",

    whatAreYourDataProtectionRightsText1: "V Trekiu chceme, aby si všichni uživatelé byli plně vědomi všech svých práv na ochranu osobních údajů. Každý uživatel má následující práva:",
    whatAreYourDataProtectionRightsText2: "Právo na přístup - Máš právo požádat Trekio o kopie svých osobních údajů.",
    whatAreYourDataProtectionRightsText3: "Právo na opravu - Máš právo požadovat, abychom opravili veškeré údaje, o kterých se domníváš, že jsou nepřesné. Máš také právo požádat nás o doplnění informací, o kterých se domníváš, že jsou neúplné.",
    whatAreYourDataProtectionRightsText4: "(Většinu informací si můžeš vždy opravit nebo doplnit také sám/sama přímo ve svém profilu).",
    whatAreYourDataProtectionRightsText5: "Právo na výmaz - za určitých podmínek máš právo požadovat, aby Trekio vymazalo tvé osobní údaje.",
    whatAreYourDataProtectionRightsText6: "Právo na omezení zpracování - Máš právo požadovat, aby Trekio za určitých podmínek omezilo zpracování tvých osobních údajů.",
    whatAreYourDataProtectionRightsText7: "Právo vznést námitku proti zpracování - Za určitých podmínek máš právo vznést námitku proti zpracování tvých osobních údajů Trekiem.",
    whatAreYourDataProtectionRightsText8: "Právo na přenositelnost údajů - Máš právo požadovat, aby Trekio za určitých podmínek předalo údaje, které jsme shromáždili, jiné organizaci nebo přímo tobě.",
    whatAreYourDataProtectionRightsText9: "Pokud podáš žádost, máme jeden měsíc na to, abychom ti odpověděli. Pokud chceš uplatnit některé z těchto práv, kontaktuj nás prosím na našem e-mailu: info@trekio.com.",

    whatAreCookiesText: "Soubory cookie jsou textové soubory, které se ukládají do tvého počítače a shromažďují standardní informace z internetových protokolů a informace o chování návštěvníků. Když navštívíš naše webové stránky, můžeme od tebe automaticky shromažďovat informace prostřednictvím souborů cookie nebo podobné technologie.",
    whatAreCookiesMoreInfo: "Další informace najdeš na adrese allaboutcookies.org.",

    weUseCookiesFor: "Trekio používá soubory cookie různými způsoby, abychom mohli zlepšovat tvůj uživatelský zážitek s našimi webovými stránkami, včetně:",
    weUseCookiesForOption1: "přihlášení a jeho udržování",
    weUseCookiesForOption2: "porozumění tomu, jak uživatelé používají naše webové stránky",
    weUseCookiesForOption3: "zobrazení tras výletů na mapě (a zajištění správné funkčnosti mapy) ",

    weUseTheseCookies: "Existuje řada různých typů souborů cookie, naše webové stránky však používají pouze:",
    functionalCookies: "Funkční (nutné) - Nutné cookies pomáhají, aby byla webová stránka použitelná tak, že umožní základní funkce jako navigace stránky a přístup k zabezpečeným sekcím webové stránky. Webová stránka nemůže správně fungovat bez těchto cookies. Používáme kombinaci souborů cookie první a třetích stran.",
    weMightUseTheseCookiesInTheFuture: "Zde jsou vysvětleny další typy souborů cookie, které můžeš při používání našich webových stránek povolit. Tyto soubory cookie zatím nepoužíváme, ale je možné, že je v budoucnu implementujeme.",
    preferentialCookies: "Preferenční - Preferenční cookies umožňují, aby si webová stránka zapamatovala informace, které mění, jak se webová stránka chová nebo jak vypadá. Je to například preferovaný jazyk nebo region, kde se uživatelé nachází.",
    statisticalCookies: "Statistické - Statistické cookies pomáhají majitelům webových stránek, aby porozuměli, jak návštěvníci používají webové stránky. Anonymně sbírají a sdělují informace.",
    marketingCookies: "Marketingové - Marketingové cookies jsou používány pro sledování návštěvníků na webových stránkách. Záměrem je zobrazit reklamu, která je relevantní a zajímavá pro jednotlivého uživatele a tímto hodnotnější pro vydavatele a inzerenty třetích stran.",
    unclassifiedCookies: "Neklasifikované - Neklasifikované cookies jsou cookies, které máme v procesu klasifikování společně s poskytovateli jednotlivých cookies.",

    howToManageYourCookiesText: "V prohlížeči můžeš nastavit, aby soubory cookie nepřijímal, a na výše uvedené webové stránce se dozvíš, jak soubory cookie z prohlížeče odstranit. V několika případech však v důsledku toho nemusí fungovat některé funkce našich webových stránek.",

    privacyPoliciesOfOtherWebsitesText: "Platforma Trekio obsahuje odkazy na jiné webové stránky. Naše zásady zpracování osobních údajů se vztahují pouze na naši platformu, takže pokud klikneš na odkaz na jinou webovou stránku, měl/a by sis přečíst její zásady ochrany osobních údajů.",

    changesToOurPersonalDataProcessingPolicyText: "Trekio své zásady ochrany osobních údajů pravidelně kontroluje a veškeré aktualizace zveřejňuje na této webové stránce. Tyto zásady ochrany osobních údajů byly naposledy aktualizovány 15. dubna 2024.",
    
    howToContactUsText: "Pokud máš jakékoli dotazy týkající se zásad ochrany osobních údajů platformy Trekio, údajů, které o tobě uchováváme, nebo chceš uplatnit některé ze svých práv na ochranu osobních údajů, neváhej nás kontaktovat.",
    emailUs: "Pošli nám e-mail na adresu: info@trekio.com ",
    contactUsViaContactForm: "Nebo nám napiš pomocí kontaktního formuláře na stránce Kontakt.",

    howToContactTheAppropriateAuthoritiesText: "Pokud si přeješ podat stížnost nebo pokud máš pocit, že Trekio nevyřešilo tvé obavy uspokojivým způsobem, můžeš se obrátit na Úřad pro ochranu osobních údajů. Další informace: https://uoou.gov.cz/en",

    theseTermsTakeEffectOn: "Tyto podmínky nabývají účinnosti dnem 15.4.2024."
}