export default {
    loading: "Loading...",
    travelTipDelete: "Deleting trip...",
    travelTipDecline: "Declining trip...",
    travelTipToRework: "Returning the trip for rework...",
    formsValidation: "Validating forms...",
    delete: "Deleting...",

    dayCreate: "Creating day...",
    dayDelete: "Deleting day...",
    campaignCreate: "Creating campaign...",
    campaignUpdate: "Updating campaign...",
    campaignDelete: "Deleting campaign...",
    startItemCreate: "Creating starting point...",
    emptyItemCreate: "Creating waypoint...",
    finishItemCreate: "Creating end point...",
    itemCreate: "Creating point of interest...",
    itemSave: "Saving point of interest...",
    itemDelete: "Deleting point of interest...",
    emptyItemsDelete: "Deleting waypoints...",
    emptyItemDelete: "Deleting waypoint...",
    commentCreate: "Creating your comment...",
    pinboardCreate: "Creating pinboard...",
    pinboardUpdate: "Updating pinboard...",
    pinboardDelete: "Deleting pinboard...",
    pinboardPostCreate: "Creating post...",
    pinboardPostUpdate: "Updating post...",
    pinboardPostDelete: "Deleting post...",
    assessmentDelete: "Deleting review...",
    assessmentCreate: "Submitting review...",
    assessmentUpdate: "Submitting review...",
    login: "Logging in...",
    register: "Registering...",
    send: "Sending...",
    save: "Saving...",
    authorInvite: "Sending invite...",
    pictureUpload: "Uploading picture...",
    pictureUpdate: "Updating picture...",
    pictureCreate: "Creating picture...",
    picturesCreate: "Creating pictures...",
    pictureDelete: "Deleting picture...",
    userCreate: "Creating user...",
    userUpdate: "Updating user...",
    userDelete: "Deleting user...",
    userUnblock: "Unblocking user...",
    userBlock: "Blocking user...",
    authorUpdate: "Updating author...",
    tagDelete: "Deleting tag...",
    tagCategoryCreate: "Creating category...",
    tagCategoryUpdate: "Updating category...",
    tagCategoryDelete: "Deleting category...",
    equipmentCategoryCreate: "Creating category...",
    equipmentCategoryUpdate: "Updating category...",
    equipmentCategoryDelete: "Deleting category...",
    equipmentTemplateCreate: "Creating template...",
    equipmentTemplateUpdate: "Updating template...",
    equipmentTemplateDelete: "Delete template...",

    map: "Loading map...",
    calculatingRoute: "Calculating route...",
}