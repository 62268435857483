import gql from "graphql-tag";

export const CREATE_USER = gql`
    mutation createUser(
        $userLogin: String!,
        $userName: String!,
        $firstName: String,
        $lastName: String,
        $password: String!,
        $role: String!
    ){
        createUser(
            userLogin:$userLogin,
            userName:$userName,
            firstName:$firstName,
            lastName:$lastName
            password:$password,
            role:$role
        ){
            id
        }
    }`;

export const REGISTER_USER = gql`
    mutation registerUser(
        $userLogin: String!,
        $userName: String!,
        $password: String!,
        $firstName: String,
        $lastName: String,
        $recaptchaToken: String!,
        $language: String!
    ){
        registerUser(
            userLogin:$userLogin,
            userName:$userName,
            password:$password,
            firstName:$firstName,
            lastName:$lastName,
            recaptchaToken:$recaptchaToken,
            language:$language
        ){
            id
        }
    }`;

export const REGISTER_AUTHOR = gql`
    mutation registerAuthor(
        $userLogin: String!,
        $userName: String!,
        $password: String!,
        $phoneNumber: String,
        $recaptchaToken: String!,
        $address: AddressInput!,
        $firstName: String!,
        $lastName: String!,
        $dateOfBirth: Date!,
        $language: String!
    ){
        registerAuthor(
            userLogin:$userLogin,
            userName:$userName,
            password:$password,
            phoneNumber:$phoneNumber,
            recaptchaToken:$recaptchaToken,
            address:$address,
            firstName:$firstName,
            lastName:$lastName,
            dateOfBirth:$dateOfBirth,
            language:$language
        ){
            id
        }
}`;

export const REGISTER_AUTHOR_WITH_TOKEN = gql`
    mutation registerAuthorWithToken(
        $userLogin: String!,
        $userName: String!,
        $password: String!,
        $phoneNumber: String,
        $recaptchaToken: String!,
        $address: AddressInput!,
        $firstName: String!,
        $lastName: String!,
        $dateOfBirth: Date!,
        $invitationToken: String!,
        $language: String!
    ){
        registerAuthorWithToken(
            userLogin:$userLogin,
            userName:$userName,
            password:$password,
            phoneNumber:$phoneNumber,
            recaptchaToken:$recaptchaToken,
            address:$address,
            firstName:$firstName,
            lastName:$lastName,
            dateOfBirth:$dateOfBirth,
            invitationToken:$invitationToken,
            language:$language
        ){
            id
        }
}`;

export const UPDATE_USER_WITH_PASSWORD = gql`
    mutation updateUserWithPassword(
        $userId: Int!,
        $userLogin: String!,
        $userName: String!,
        $firstName: String,
        $lastName: String,
        $password: String!,
        $phoneNumber: String,
        $role: String!
    ){
        updateUserWithPassword(
            id: $userId,
            userLogin:$userLogin,
            userName:$userName,
            firstName:$firstName,
            lastName:$lastName,
            password:$password,
            phoneNumber:$phoneNumber
            role:$role
        ){
            id
        }
    }`;

export const UPDATE_USER = gql`
    mutation updateUser(
        $userId: Int!,
        $userLogin: String!,
        $userName: String!,
        $firstName: String,
        $lastName: String,
        $phoneNumber: String,
        $role: String!
    ){
        updateUser(
            id: $userId,
            userLogin:$userLogin,
            userName:$userName,
            firstName:$firstName,
            lastName:$lastName,
            phoneNumber:$phoneNumber,
            role:$role
        ){
            id
        }
    }`;

export const UPDATE_AUTHOR_WITH_PASSWORD = gql`
    mutation updateAuthorWithPassword(
        $userId: Int!,
        $userLogin: String!,
        $userName: String!,
        $password: String!,
        $phoneNumber: String,
        $address: AddressInput!,
        $firstName: String!,
        $lastName: String!,
        $dateOfBirth: Date,
        $role: String!
        $about: String,
    ){
        updateAuthorWithPassword(
            id: $userId,
            userLogin:$userLogin,
            userName:$userName,
            password:$password,
            phoneNumber:$phoneNumber,
            address:$address,
            firstName:$firstName,
            lastName:$lastName,
            dateOfBirth:$dateOfBirth,
            role:$role,
            about:$about
        ){
            id
        }
}`;

export const UPDATE_AUTHOR = gql`
    mutation updateAuthor(
        $userId: Int!,
        $userLogin: String!,
        $userName: String!,
        $phoneNumber: String,
        $address: AddressInput!,
        $firstName: String!,
        $lastName: String!,
        $dateOfBirth: Date,
        $role: String!
        $about: String,
    ){
        updateAuthor(
            id: $userId,
            userLogin:$userLogin,
            userName:$userName,
            phoneNumber:$phoneNumber,
            address:$address,
            firstName:$firstName,
            lastName:$lastName,
            dateOfBirth:$dateOfBirth,
            role:$role,
            about:$about
        ){
            id
        }
}`;

export const UPDATE_USER_AVATAR = gql`
    mutation updateUserAvatar(
        $id: Int!,
        $avatar: Upload!
    ){
        updateUserAvatar(
            id: $id,
            avatar: $avatar
        )
    }`;

export const DELETE_USER_AVATAR = gql`
    mutation deleteeUserAvatar(
        $id: Int!,
    ){
        deleteUserAvatar(
            id: $id,
        )
    }`;

export const DELETE_USER = gql`
    mutation deleteUser($userId: Int!) {
        deleteUser(userId: $userId)
    }`;

export const LOCK_USER = gql`
    mutation lockUser($userId: Int!) {
        lockUser(userId: $userId)
    }`;

export const BLOCK_USER = gql`
    mutation blockUser($userId: Int!) {
        blockUser(userId: $userId)
    }`;

export const UNBLOCK_USER = gql`
    mutation unblockUser($userId: Int!) {
        unblockUser(userId: $userId)
    }`;

export const REQUEST_PASSWORD_RESET = gql`
    mutation requestPasswordReset($userLogin: String!, $recaptchaToken: String!) {
        requestPasswordReset(userLogin: $userLogin, recaptchaToken: $recaptchaToken)
    }`;

export const RESET_PASSWORD_BY_TOKEN = gql`
    mutation resetPasswordByToken($token: String!,$password: String!) {
        resetPasswordByToken(token: $token, password: $password)
    }`;

export const SEND_INVITATION_EMAIL = gql`
    mutation sendInvitationEmail($email: String!, $language: String!) {
        sendInvitationEmail(email: $email, language: $language)
    }`

export const SET_HAS_LICENCE_AGREEMENT_SIGNED = gql`
    mutation setHasLicenceAgreementSigned($id:Int!) {
        setHasLicenceAgreementSigned(id: $id)
    }`

export const SET_IS_AUTHOR_PUBLISHED = gql`
    mutation setIsAuthorPublished($id:Int!, $isAuthorPublished:Boolean!) {
        setIsAuthorPublished(id: $id, isAuthorPublished: $isAuthorPublished)
    }`

export const SET_PREFERRED_LANGUAGE_FOR_CURRENT_USER = gql`
    mutation setPreferredLanguageForCurrentUser($language: String!) {
        setPreferredLanguageForCurrentUser(language: $language)
    }`

    


