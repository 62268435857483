export default {
    title: "Trekio Personal Data Processing Policy",
    introductionText1: "Trekio is a travel platform offering planned out trips and itineraries. There are two types of accounts you can have on our platform. You can either register as a regular User, which allows you to browse and rate trips, and save the ones you like for later. Or you can register as an Author of trips. This type of registration additionally allows you to write trips that can be published on our platform. For the purposes of licensing agreement, we need to collect more data from this type of users.",
    introductionText2: "This personal data processing policy will explain how the platform uses the personal data we collect from you when you use it.",
    topics: "Topics",
    whatDataDoWeCollect: "What data do we collect?",
    howDoWeCollectYourData: "How do we collect your data?",
    howWillWeUseYourData: "How will we use your data? ",
    howDoWeStoreYourData: "How do we store your data? ",
    whatAreYourDataProtectionRights: "What are your data protection rights? ",
    whatAreCookies: "What are cookies? ",
    howDoWeUseCookies: "How do we use cookies? ",
    whatTypesOfCookiesDoWeUse: "What types of cookies do we use? ",
    howToManageYourCookies: "How to manage your cookies ",
    privacyPoliciesOfOtherWebsites: "Privacy policies of other websites ",
    changesToOurPersonalDataProcessingPolicy: "Changes to our personal data processing policy ",
    howToContactUs: "How to contact us ",
    howToContactTheAppropriateAuthorities: "How to contact the appropriate authorities ",

    trekioCollectsFollowingData: "The Trekio platform collects the following data: ",
    personalIdentificationInformation: "Personal identification information ",
    personalIdentificationInformationUsers: "For Users: email address and optionally (full) name, and photo (profile picture) ",
    personalIdentificationInformationAuthors: "For Authors: full name, email address, birth date, address, and optionally phone number and photo (profile picture) ",
    personalInformationInYourContent: "If you choose to include personal information in your content (e.g. a trip review or description, or if you leave a message for other users on a travel buddies pinboard or in the connected messaging system), it will also be stored in our database as part of your content. However, we do not actively seek out such information. We treat it as any other content. ",

    weCollectDataWhenYou: "You directly provide the Trekio platform with the data we collect. We collect data and process data when you: ",
    weCollectDataOption1: "Register online (and thereby become User or Author) ",
    weCollectDataOption2: "Submit the licensing agreement as an Author ",
    weCollectDataOption3: "Voluntarily complete a Contact us form or contact us in any other way (e. g. via email or social media)",
    weCollectDataOption4: "Voluntarily provide us your data as part of any content you submit to the platform (including trip reviews, ratings and descriptions, trips and related photos, or messages on pinboard for travel buddies and in a connected messaging system)",

    dataCollectionPurpose: "The Trekio platform collects your data so that we can: ",
    dataCollectionPurposeText: "Manage your account and all the content you provide, and for related communication (e. g. letting you know about the approval process for the trips you created, or informing you in case we have to block your account)",

    howDoWeStoreYourDataText1: "Trekio securely stores the data you provide via our platform in our secured database. If you provide us any data via email or other means of communication (e. g. social media, Whatsapp etc.), they might be also stored on the servers of the respective third-party service providers. Signed licensing agreements are backed-up on a secured device.",
    howDoWeStoreYourDataText2: "Trekio will keep your data for the necessary period based on the legal relationship and correct functioning of the platform. (E. g. You can anonymize your account at any time as a User.  After completing this action, we will no longer store or process your email address, which is the only personal data we ever required from you. However, for the correct functioning of the platform, we are unable to delete all the content you provided in different places, such as trip reviews or messages to travel buddies. It is therefore your responsibility to choose what you share as part of your content. If you would like to delete specific content, you can in most cases do so yourself, or you can contact us on our email address info@trekio.com or via our Contact us form.) Once this time period has expired, we will delete your data by anonymizing or removing them from our database.",
    howDoWeStoreYourDataText3: "Authors, please keep in mind, that after submitting a trip, we are obliged to keep your license agreement for the whole duration of the license. We also need to be able to connect you (as one party of the licensing agreement) and your trips. Therefore, while you can anonymize your account, this will not lead to complete removal of all your personal data from our systems.",

    whatAreYourDataProtectionRightsText1: "Trekio would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:",
    whatAreYourDataProtectionRightsText2: "The right to access - You have the right to request Trekio for copies of your personal data.",
    whatAreYourDataProtectionRightsText3: "The right to rectification - You have the right to request that we correct any information you believe is inaccurate. You also have the right to request us to complete information you believe is incomplete.",
    whatAreYourDataProtectionRightsText4: "(In fact, you can also always correct or complete most of the information yourself directly in your profile.)",
    whatAreYourDataProtectionRightsText5: "The right to erasure — You have the right to request that Trekio erase your personal data, under certain conditions.",
    whatAreYourDataProtectionRightsText6: "The right to restrict processing - You have the right to request that Trekio restrict the processing of your personal data, under certain conditions.",
    whatAreYourDataProtectionRightsText7: "The right to object to processing - You have the right to object to Trekio’s processing of your personal data, under certain conditions.",
    whatAreYourDataProtectionRightsText8: "The right to data portability - You have the right to request that Trekio transfer the data that we have collected to another organization, or directly to you, under certain conditions.",
    whatAreYourDataProtectionRightsText9: "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: info@trekio.com",

    whatAreCookiesText: "Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology.",
    whatAreCookiesMoreInfo: "For further information, visit allaboutcookies.org.",

    weUseCookiesFor: "Trekio uses cookies in a range of ways to improve your experience on our website, including:",
    weUseCookiesForOption1: "Keeping you signed in",
    weUseCookiesForOption2: "Understanding how you use our website",
    weUseCookiesForOption3: "Showing trip routes on the map (and ensuring proper map functionality)",

    weUseTheseCookies: "There are a number of different types of cookies, however, our website uses only:",
    functionalCookies: "Functional — necessary cookies help to make the website usable by enabling basic functions such as site navigation and access to secure sections of the website. The website cannot function properly without these cookies. A mix of first-party and third-party cookies are used.",
    weMightUseTheseCookiesInTheFuture: "Here are explained other types of cookies that you may allow when using our website. We do not use these cookies as of yet, but it is possible that we might implement them in the future.",
    preferentialCookies: "Preferential — Preferencial cookies allow a website to remember information that changes how a website behaves or looks. For example, your preferred language or the region you are in.",
    statisticalCookies: "Statistical – Statistical cookies help us understand how our visitors use the website. They collect and communicate information anonymously.",
    marketingCookies: "Marketing – Marketing cookies are used to track visitors to the website. The intention is to display advertising that is relevant and interesting to the individual user and thus more valuable to publishers and third-party advertisers.",
    unclassifiedCookies: "Unclassified – Unclassified cookies are cookies that we have in the process of classifying together with the providers of the individual cookies.",

    howToManageYourCookiesText: "You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.",

    privacyPoliciesOfOtherWebsitesText: "The Trekio platform contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.",

    changesToOurPersonalDataProcessingPolicyText: "Trekio keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 15 April 2024.",
    
    howToContactUsText: "If you have any questions about Trekio’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.",
    emailUs: "Email us at: info@trekio.com",
    contactUsViaContactForm: "Or you can contact us using the contact form on the Contact page.",

    howToContactTheAppropriateAuthoritiesText: "Should you wish to report a complaint or if you feel that Trekio has not addressed your concern in a satisfactory manner, you may contact the Information Commissioner's Office. Further information: https://uoou.gov.cz/en",

    theseTermsTakeEffectOn: "These terms and conditions take effect on 15 April 2024."
}