export default {
    title: 'Cestujte s námi, víme, co děláme',
    whoAreWeTitle: 'Kdo jsme',
    whoAreWeText: 'Rádi cestujeme. Po Česku i ve světě. Chceme poznávat a objevovat. Nevyhýbáme se „must-see“ zajímavostem, ale víc se těšíme na neotřelá zapadlá místa, která v běžném průvodci nenajdete. Kde nepotkáte mainstreamové turisty, za to tam ale nechybí pozoruhodné zážitky. Baví nás pokec ve frontě na tržišti i východ slunce na zapadlé vyhlídce. Třeba s hrnkem čaje s místním medem. A k takovému cestování chceme pomoct i dalším spřízněným duším. Proto jsme začali tvořit platformu Trekio: pro sdílení cestovních itinerářů a tipů na zajímavá místa.',
    ourApproachTitle: 'Náš přístup',
    ourApproachText: 'Jasně, nedělíme se úplně o všechny zajímavosti, na které narazíme. Víme, že některé jsou příliš křehké a vzácné. Věříme ale, že krása místa nezávisí na tom, kolik ho navštíví lidí. Myšlenkově jdeme proti overturismu, protože víme, že všeho moc škodí. Příliš mnoho lidí na stále stejných místech vede k degradaci lokalit, může kazit životy místních i zážitky z cestování. A přitom existuje tolik fascinujících neznámých míst, která stojí za to vidět, a kde by i tamní komunity uvítaly trochu více návštěvníků. A právě ta chceme popisovat v našich výletech! Udržitelný soulad člověka a přírody ale musí vždy zůstat na prvním místě. K tomu chceme přispět i naším přístupem. Ať už jde o preferenci šetrnějších způsobů dopravy, podporu lokálních podniků nebo šíření povědomí o tom, co kde roste a žije, a poskytování praktických informací třeba o chráněných územích přímo v popisech jednotlivých výletů. Je to víc práce, ale víme, že tahle práce je důležitá. A věříme, že i vám bude tento přístup na našich výletech vlastní. Na výlet přece nechodíme konzumovat a devastovat, ale spíš objevovat a obdivovat, ne? ',
    preparingTripsIn15Countries: 'Připravujeme pro vás výlety v 15 zemích!',
    howItAllStartedTitle: 'Jak to celé začalo',
    howItAllStartedText: 'Pár let zpátky mi ujel autobus do Basileje. Chystal jsem se do uměleckého muzea. Teď už ho nestihnu. Na téměř vybitém telefonu hledám náhradní program. Nacházím nabídky předražených guided tours a dokolečka se opakující tipy na ta samá místa. Většina stránek je navíc zmatečná nebo plná reklam. Sotva se načtou… Asi si dovedete představit, že než jsem našel něco zajímavého, mobil se mi vybil úplně. Naštěstí jsem měl u sebe vytištěnou mapu a mohl si naplánovat jednoduchý výlet v okolí. Nakonec jsem si ho parádně užil. ',
    howItAllStartedText2: 'Stále ve mně však zůstávala frustrace z toho, že jsem za těch asi 15 minut nebyl schopen najít o žádném unikátním místě dostatek informací k tomu, abych mohl rovnou vyrazit na cestu. Zklamalo mě, že první příčky ve vyhledávání zabírají komerční stránky, které se snaží na svých uživatelích jen vydělat. A přitom poskytnuté informace vypadají jako špatně zkopírovaná Wikipedie. Navíc se všechny tipy na výlety stále opakovaly. Začala se mi v hlavě rodit myšlenka... Kdyby jen existovala stránka, která by doporučovala originální a nové nápady na místa a výlety a zároveň obsahovala přesné informace. Přehledně uspořádané a s kritérii, co se dají snadno filtrovat. A tak se zrodilo Trekio… ',
    fromAnIdeaToWorkingSearchEngineTitle: 'Od nápadu k fungujícímu vyhledávači',
    fromAnIdeaToWorkingSearchEngineText: 'Nápad se mnou sdílelo několik stejně smýšlejících lidí, a tak vznikl náš tým. Hned na první schůzce jsme přišli s názvem Trekio, který měl vystihovat naši touhu nabídnout běžným uživatelům návrhy na zcela nové a originální výlety a lokality, a tak začaly první krůčky našeho oficiálně pojmenovaného projektu. Základní koncept už jsme měli, a tak jsme se s plnou vervou vrhli na grafiku. Ta se ukázala jako nejsložitější. Představa o samotném rozvržení, barvách a tvarech jednotlivých komponent na stránkách nebyla tak jednoduše realizovatelná, jak se zdálo. Nakonec nám velmi pomohlo, že jeden z členů týmu se designu ve svém volném čase věnoval. Jakmile jsme měli grafický návrh, začali jsme se samotnou realizací webu.',
    fromAnIdeaToWorkingSearchEngineText2: 'Bylo to mnoho hodin strávených po večerech u obrazovek našich počítačů. Přibližně po třech měsících od naší první schůzky jsme měli provozuschopné webové stránky, které ale potřebovaly obsah. Většina členů našeho týmu naštěstí doslova procestovala půlku planety, a tak mohli ze svých cest přispět jak samotným textem, tak i obrazovým materiálem. Stránky se postupně naplnily desítkami cestovních itinerářů a my mohli slavit první úspěch. Náš projekt nabral ten správný směr a my začali sledovat, jak se pomalu začíná zvyšovat návštěvnost webu.',
    wePlanSoYouDontHaveTo: 'Cestování v pohodě? Plánujeme za vás.',
    whatNextTitle: 'A co dál?',
    whatNextText: 'Trekio se posouvá mílovými kroky, ale víme, že stále není dokonalé. Snažíme se stávající verzi zlepšovat a vychytat na ní všechny mouchy, aby bylo co nejužitečnější a nejpříjemnější na používání. Máme ale taky spoustu dalších nápadů do budoucna!',
    whatNextText2: 'Sami taky neznáme všechna zapomenutá místa a rádi zkoumáme nová. Proto tvoříme Trekio jako platformu pro sdílení takových objevů a užitečných informací o nich. Chtěli bychom, abyste se na vytváření výletů mohli podílet i vy, naši výletníci. A tak pro vás rozjíždíme možnost přidávat tipy na výlety a fotky, doplnit k nim praktické rady a podělit se o vlastní zkušenosti. V budoucnu bychom chtěli mít i appku, která by vás dokázala snadno provést vybraným výletem přímo na cestě.',
    whatNextText3: 'Rádi bychom, aby se kolem stránek vytvořila samostatně fungující komunita, která by svými nápady, zážitky a připomínkami dodala Trekiu to, co mu do této chvíle chybělo. Přímý kontakt s uživateli, kteří už podle našich itinerářů cestují nebo se podílejí na jejich vytváření. Abychom mohli společně vdechnout trochu života zapomenutým místům a naopak odlehčit turistickým trhákům, kde příliš vysoká návštěvnost devastuje přírodu i atmosféru.',
    coolPlacesYouMightNotFind: 'Cool místa, která byste možná sami ani nenašli',
    wantToBecomeAuthor: 'Chceš se stát autorem našich výletů?',
    becomeAuthor: 'Staň se autorem'
}