<template>
    <div id="app">
        <div v-if="window.location.hostname === 'trekiodev.cleverlance.com'" class="dev-label">DEV</div>
        <router-view></router-view>
    </div>
</template>

<script>
import { UNREAD_CONVERSATIONS_COUNT } from '@/api/graphql/query/MessageQuery'

    export default {
        name: 'app',

        data() {
            return {
                window: window
            }
        },

        methods: {
            enableGoogleAnalytics() {
                // eslint-disable-next-line
                if (Cookiebot && Cookiebot.consent.statistics) {
                    this.$gtag.config({
                        'enabled': true
                    })
                }
            }
        },

        created() {
            const chosenPreferredLanguage = localStorage.getItem('preferredLanguage')
            // When it hasn't been manually selected by the user before
            if (!chosenPreferredLanguage) {
                let preferredLanguage = navigator.language
                if (navigator.language === 'sk') {
                    preferredLanguage = 'cs'
                } else if (!this.$store.state.publicLanguageCodes.includes(navigator.language)) {
                    preferredLanguage = 'en'
                }
                this.$store.commit('setPreferredLanguage', preferredLanguage ? preferredLanguage : 'en')
                this.$root.$i18n.locale = preferredLanguage ? preferredLanguage : 'en'
            } else {
                this.$store.commit('setPreferredLanguage', chosenPreferredLanguage)
                this.$root.$i18n.locale = chosenPreferredLanguage
            }
            document.documentElement.lang = this.$root.$i18n.locale;
            document.title = this.$i18n.t('websiteTitle')
        },

        mounted() {
            window.addEventListener('CookiebotOnAccept', this.enableGoogleAnalytics())
        },

        destroyed() {
            window.removeEventListener('CookiebotOnAccept', this.enableGoogleAnalytics())
        },

        apollo: {
            unreadConversationsCount: {
                query: UNREAD_CONVERSATIONS_COUNT,
                pollInterval: 60000,
                skip: function() {
                    return !this.$store.state.currentUserId || this.$route.path.includes('admin')
                },
                result({data}) {
                    this.$store.commit('setUnreadMessagesCount', data.unreadConversationsCount)
                },
                fetchPolicy: 'network-only'
            },
        }
    }
</script>

<style scoped lang="scss">
    @import 'common-def';
    @import '@/scss/variables';

    .dev-label {
        pointer-events: none;
        position: fixed;
        color: $tr-white;
        background-color: red;
        font-weight: 600;
        border: 2px solid $tr-black;
        padding: 1px 10px;
        top: 5px;
        left: 5px;
        z-index: 10000;
    }

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        background-color: $tr-white;
        // overflow-x: hidden;
    }
</style>

<style lang="scss">
    @use 'global';
</style>
