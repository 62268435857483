export default {
    loading: "Načítání...",
    travelTipDelete: "Odstraňování výletu...",
    travelTipDecline: "Zamítání výletu...",
    travelTipToRework: "Předávání výletu uživateli...",
    formsValidation: "Validace formulářu...",
    delete: "Mazání...",

    dayCreate: "Vytváření dne...",
    dayDelete: "Odstraňování dne....",
    campaignCreate: "Vytváření kampaně...",
    campaignUpdate: "Úprava kampaně...",
    campaignDelete: "Odstraňování kampaně...",
    startItemCreate: "Vytváření výchozího bodu...",
    emptyItemCreate: "Vytváření slepého bodu...",
    finishItemCreate: "Vytváření cíle...",
    itemCreate: "Vytváření zastávky...",
    itemSave: "Ukládání zastávky...",
    itemDelete: "Odstraňování zastávky...",
    emptyItemsDelete: "Odstraňování slepých bodů...",
    emptyItemDelete: "Odstraňování slepého bodu...",
    commentCreate: "Vytváření komentáře...",
    pinboardCreate: "Vytváření nástěnky...",
    pinboardUpdate: "Úprava nástěnky...",
    pinboardDelete: "Odstraňování nástěnky...",
    pinboardPostCreate: "Vytváření příspěvku...",
    pinboardPostUpdate: "Úprava příspěvku...",
    pinboardPostDelete: "Odstraňování příspěvku...",
    assessmentDelete: "Mazání hodnocení...",
    assessmentCreate: "Hodnotím...",
    assessmentUpdate: "Hodnotím...",
    login: "Přihlašování...",
    register: "Registrování...",
    send: "Odesílání...",
    save: "Ukládání...",
    authorInvite: "Odesílání pozvánky...",
    pictureUpload: "Nahrávání fotky...",
    pictureUpdate: "Úprava fotky...",
    pictureCreate: "Vytváření fotky...",
    picturesCreate: "Vytváření fotek...",
    pictureDelete: "Odstraňování fotky...",
    userCreate: "Vytváření uživatele...",
    userUpdate: "Úprava uživatele...",
    userDelete: "Odstraňování uživatele...",
    userUnblock: "Odblokování uživatele...",
    userBlock: "Blokování uživatele...",
    authorUpdate: "Úprava autora...",
    tagDelete: "Odstraňování tagu...",
    tagCategoryCreate: "Vytváření kategorie...",
    tagCategoryUpdate: "Úprava kategorie...",
    tagCategoryDelete: "Odstraňování kategorie...",
    equipmentCategoryCreate: "Vytváření kategorie...",
    equipmentCategoryUpdate: "Úprava kategorie...",
    equipmentCategoryDelete: "Odstraňování kategorie...",
    equipmentTemplateCreate: "Vytváření šablony...",
    equipmentTemplateUpdate: "Úprava šablony...",
    equipmentTemplateDelete: "Odstraňování šablony...",
    
    map: "Načítání mapy...",
    calculatingRoute: "Vypočítává se trasa...",


    

}