<template>
    <div class="select-container" :class="{error: error, 'white-bg': whiteBg, 'dark-bg': darkBg, disabled}" :style="selectStyle">
        <div v-if="info" class="info-icon" v-tooltip.right-start="info"></div>
        <label v-if="label" :data-required="required">{{ label }}</label>
        <b-dropdown style="max-height: none" no-flip :text="text ? text : placeholder" ref="dropdown" :disabled="disabled">
            <div v-if="searchInput" class="search-input-wrapper">
                <input class="search-input" id="search" type="text" :value="searchString" @input="$emit('searchInput', $event.target.value.trim())" :placeholder="$t('general.search')">
            </div>
            <div class="option-list-wrapper">
                <ul style="list-style: none; padding: 0">
                    <label v-if="selectAllOption">
                        {{ isAllSelected ? $t("filter.uncheckAll") : $t("filter.checkAll") }}
                        <input :checked="isAllSelected" type="checkbox" @change.stop="selectOrDeselectAll">
                        <span class="checkmark-select"></span>
                    </label>
                    <slot></slot>
                </ul>
            </div>
        </b-dropdown>
        <span v-if="error" class="input-alert">{{ error }}</span>
    </div>
</template>

<script>
    export default {
        name: 'TrekioSelect',
        props: {
            value: {
                default: null,
            },
            placeholder: {
                type: String,
                default: 'Select an option',
            },
            label: {
                type: String,
            },
            required: {
                type: Boolean,
                default: false
            },
            error: {
                type: String,
            },
            info: {
                type: [String, Object]
            },
            searchInput: {
                type: Boolean,
                default: false
            },
            searchString: {
                type: String
            },
            text: {
                type: String,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            isAllSelected: {
                type: Boolean,
            },
            selectAllOption: {
                type: Boolean,
            },
            disabled: {
                type: Boolean,
                default: false
            },
            whiteBg: {
                type: Boolean,
                default: false,
            },
            darkBg: {
                type: Boolean,
            },
            width: {
                type: String,
            },
            maxWidth: {
                type: String
            }
        },

        data() {
            return {
                searchInputValue: '',
            }
        },

        computed: {
            selectStyle() {
                const style = {}
                if (this.width) {
                    style.width = this.width
                }
                if (this.maxWidth) {
                    style.maxWidth = this.maxWidth
                }
                return style;
            }
        },

        methods: {
            selectOrDeselectAll() {
                if (this.isAllSelected) {
                    this.$emit('input', [])
                } else {
                    const allOptionsInArray = this.$children[0]?.$children.map(child => child.value)
                    this.$emit('input', allOptionsInArray)
                }
            },
            closeDropdown() {
                this.$refs['dropdown'].hide(true)
            },
            handleOptionSelect(option) {
                if (this.multiple) {
                    let itemsArray = [...this.value]
                    const index = itemsArray.findIndex(
                        item => item === option.value
                    );
                    if (index === -1) {
                        itemsArray.push(option.value);
                    } else {
                        itemsArray.splice(index, 1);
                    }
                    this.$emit('input', itemsArray);
                } else {
                    this.$emit('input', option.value);
                    this.closeDropdown();
                }
            },
        },
    }

</script>

<style lang="scss">
    @import '@/scss/variables';

    .select-container {
        position: relative;
        max-width: 330px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        
        .search-input-wrapper {
            input {
                height: 60px;
                width: 100%;
                padding-inline: 20px;
                border: none;
                border-bottom: solid 2px $tr-gray;
                background-color: $tr-white;
            }

            & + .option-list-wrapper {
                max-height: 300px !important;
            }
        }

        .option-list-wrapper {
            overflow: auto;

            ul {
                list-style: none;
                padding: 0px;

                label {
                    width: 100%;
                    min-height: 60px;
                    padding-inline: 20px;
                    background-color: $tr-white-alt;
                    cursor: pointer;
                    position: relative;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &.disabled {
                        pointer-events: none !important;
                        opacity: 0.5;
                        cursor: not-allowed;
                    }

                    &:active .checkmark-select {
                        opacity: 0.85;
                    }
                    
                    &:hover {
                        background-color: $tr-green;
                        color: $tr-white;
                    }

                    &.checkbox:hover {
                        color: $tr-black;
                    }

                    & input:focus-visible + .checkmark-select {
                        outline: 2px solid $tr-green;
                    }
                
                    input {
                        appearance: none;
                        position: absolute;
                        height: 0;
                        width: 0;
                        &:checked + .checkmark-select::after {
                            display: block;
                        }
                    }
                    .checkmark-select {
                        height: 40px;
                        width: 40px;
                        background-color: #eee;
                        position: relative;
                        pointer-events: none;
                        flex-shrink: 0;
                
                        &::after {
                            display: none;
                            position: absolute;
                            content: "";
                            left: 15px;
                            top: 7px;
                            width: 11px;
                            height: 22px;
                            border: solid $tr-green;
                            border-width: 0 3px 3px 0;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
        & > label + .dropdown button.dropdown-toggle.btn-secondary {
            padding-top: 14px !important;
        } 
        
        .dropdown-toggle {

            &+.dropdown-menu.show{
                padding-left: 30px;
                border-radius: 0px;
                border: 0px solid transparent !important;
            }
        }

        &.error .dropdown button.dropdown-toggle.btn-secondary {
            border-bottom: 2px solid $tr-pink !important;
        }

        &.disabled, .no-interaction & {
            &::after {
                border-color: $tr-mid-gray transparent transparent transparent !important;
            }
            & > label {
                color: $tr-mid-gray !important;
            }
            button {
                opacity: 1 !important;
                color: $tr-mid-gray !important;
            }
        }
        
        & > label {
            position: absolute;
            font-size: 11px;
            color: $tr-gray;
            left: 20px;
            top: 7px;
            z-index: 2;
            line-height: 16px;
            pointer-events: none;

            &[data-required]::after {
                content: "*";
                margin-left: 3px;
            }
        }

        .dropdown {
            width: 100%;

            button.dropdown-toggle.btn-secondary {
                padding-inline: 20px 50px;
                min-height: 60px;
                font-weight: 500;
                width: 100%;
                color: $tr-black;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                border-radius: 0px;
                border: 0px solid transparent !important;
                background: none;
                background-color: $tr-white-alt !important;

                
                &::after {
                    position: absolute;
                    content: "";
                    right: 22px;
                    top: 50%; transform: translateY(-50%);
                    border-style: solid;
                    border-width: 7px 7px 0 7px;
                    border-color: $tr-black transparent transparent transparent;
                    pointer-events: none;
                    z-index: 2;
                }
                &:disabled::after {
                    border-color: $tr-mid-gray transparent transparent transparent;
                }

                &:focus-visible {
                    outline: 2px solid $tr-green !important;
                }
            }
        }

        &.white-bg .dropdown button.dropdown-toggle {
            background-color: $tr-white !important;
        }
        &.dark-bg .dropdown button.dropdown-toggle {
            background-color: $tr-light-gray !important;
        }

        .info-icon {
            position: absolute;
            right: 20px;
            top: 22px;
        }

        .info-icon ~ .dropdown button.dropdown-toggle::after {
            right: 50px;
        }


        .dropdown-menu {
            z-index: 1035;
            max-height: 362px;
            overflow: auto;
            width: 100%;
            padding: 0 !important;
            margin-top: 0 !important;
            box-shadow: rgb(0 0 0 / 50%) 0px 2px 2px !important;
        }

        .btn.dropdown-toggle.btn-secondary + .dropdown-menu.show {
            border-top: solid $tr-green 2px !important;
        }

        ul button {
            width: 100%;
            min-height: 60px;
            background-color: $tr-white-alt;

            &.disabled {
                pointer-events: none !important;
                opacity: 0.5;
                cursor: not-allowed;
            }
            
            &:hover {
                background-color: $tr-green;
                color: $tr-white;
            }
        }
        .input-alert {
            font-size: 12px;
            color: $tr-pink;
        }
    }



</style>