<template>
      <b-dropdown no-flip :text="preferredLanguage" ref="choose-language" class="button-localization ml-auto">
        <li v-for="language in availableLanguages" :key="language.code" class="dropdown-option d-flex align-items-center justify-content-between" @click="setPreferredLanguage(language.code)">{{ language.code }}</li>
      </b-dropdown>
</template>

<script>
    import { mapGetters } from 'vuex';
    import UserService from '../services/UserService'

    export default {

        data() {
            return {

            }
        },

        methods: {
            async setPreferredLanguage(language) {
                localStorage.setItem('preferredLanguage', language)
                this.$refs['choose-language'].hide(true)
                
                if (this.$store.state.currentUserId) {
                  await UserService.setPreferredLanguageForCurrentUser(language)
                    .then(resp =>{
                    })
                    .catch(err => {
                      console.log(err)
                    })
                  }
                  location.reload()
            },
        },

        computed: {
            ...mapGetters({
                preferredLanguage: 'preferredLanguage',
                languages: 'languages'
            }),
            availableLanguages() {
                return this.languages.filter(lang => !lang.isSecondary)
            },
        }
    }

</script>

<style lang="scss">
    @import '@/scss/variables';

.button-localization {
    display: flex;
    height: 40px;
    background-color: transparent !important;
    align-items: center;
    gap: 5px;
    margin-left: auto;
    border: 1px solid $tr-black;
    cursor: pointer;
    position: relative;
    color: $tr-black;

    & button.btn.dropdown-toggle.btn-secondary {
      background-color: transparent !important;
      width: 100%;
      height: 100%;
      padding-inline: 34px 10px;
      color: $tr-black;
      border-radius: 0;
    }
    
    ul {
      min-width: 100%;
      width: 100%;
      padding: 0 !important;
      border-radius: 0;

      li {
        padding: 10px 20px !important;

        &:hover {
          background-color: $tr-light-gray;
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      top: 6px;
      left: 8px;
      background-image: url('data:image/svg+xml,<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)" fill="%23000"><path d="M25 45C13.967 45 5 36.033 5 25S13.967 5 25 5s20 8.967 20 20-8.967 20-20 20m0-38.333C14.9 6.667 6.667 14.9 6.667 25S14.9 43.333 25 43.333 43.333 35.1 43.333 25 35.1 6.667 25 6.667"/><path d="M25 45c-6.433 0-11.467-8.8-11.467-20S18.567 5 25 5s11.467 8.8 11.467 20S31.433 45 25 45m0-38.333c-5.4 0-9.833 8.233-9.833 18.333s4.4 18.333 9.833 18.333S34.833 35.1 34.833 25 30.4 6.667 25 6.667"/><path d="M9.067 13.533h31.866V15.2H9.067zm0 21.3h31.866V36.5H9.067zM5.833 24.167h38.334v1.666H5.833z"/><path d="M24.167 5.834h1.666v38.333h-1.666z"/></g><defs><clipPath id="a"><path fill="%23fff" d="M5 5h40v40H5z"/></clipPath></defs></svg>');
      background-size: 24px;
      background-position: center;
      z-index: 1;

    }

    img {
      height: 20px;
      width: 20px;
    }
  }
</style>