export const setCookie = (name) => {
    var currentDate = new Date();
    var expirationDate = new Date();
    expirationDate.setHours(currentDate.getHours() + 24);
    expirationDate.setSeconds(currentDate.getSeconds() - 10);

    var cookieExpiration = expirationDate.toUTCString();

    document.cookie = name + "=" + cookieExpiration + "; expires=" + cookieExpiration + "; path=/";
  };
  
  export const getCookie = (name) => {
    var cookies = document.cookie.split("; ");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].split("=");
        if (cookie[0] === name) {
            return cookie[1];
        }
    }
    return null;
  };

  export const removeCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };