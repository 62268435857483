<template>
  <nav id="nav-menu-wrapper" class="tr-top-nav" :class="{solidBackground : background, 'gray-bg': !background && !transparentBg, hidden: isHidden, 'tr-top-nav--open': isNavbarModalVisible, 'nav-shadow': !isNavAtTheTop}">
    <div class="nav-background"></div>
    <div class="tr-container">
      <router-link to="/" class="tr-top-nav__brand">
        <img v-if="!greenLogo" alt="logo trekio" src="../../assets/svg/trekio-logo-white.svg">
        <img v-if="greenLogo" alt="logo trekio" src="../../assets/svg/trekio-logo.svg">
      </router-link>
      
      <div v-on:click="toggleMenu" id="nav-menu-icon" class="tr-top-nav__menu-icon"
           :class="{'is-white-bar': !greenLogo, 'tr-top-nav__menu-icon--open': isNavbarModalVisible}">
        <div class="burger-line"></div>
        <div class="burger-line"></div>
        <div class="burger-line"></div>
      </div>

      <div class="tr-top-nav-items">
        <router-link @click.native="closeSamePath('/')" to="/" class="tr-top-nav-items__item home-link"
                     :class="$route.path === '/' ? 'tr-top-nav-items__item--active' : ''">{{ $t("general.home") }}
        </router-link>
        <router-link @click.native="closeSamePath('/search')" to="/search" class="tr-top-nav-items__item"
                     :class="$route.path === '/search' ? 'tr-top-nav-items__item--active' : ''">{{ $t("general.travelTips") }}
        </router-link>
        <router-link @click.native="closeSamePath('/pinboard')" to="/pinboard" class="tr-top-nav-items__item"
                     :class="$route.path === '/pinboard' || $route.name === 'pinboardPosts' ? 'tr-top-nav-items__item--active' : ''">{{ $t("general.pinboard") }}
        </router-link>
        <router-link @click.native="closeSamePath('/about')" to="/about" class="tr-top-nav-items__item"
                     :class="$route.path === '/about' ? 'tr-top-nav-items__item--active' : ''">{{ $t("general.aboutUs")}}
        </router-link>
        <router-link @click.native="closeSamePath('/howTo')" to="/howTo" class="tr-top-nav-items__item"
                     :class="$route.path === '/howTo' ? 'tr-top-nav-items__item--active' : ''">{{ $t("general.howTo") }}
        </router-link>
        <router-link @click.native="closeSamePath('/contact')" to="/contact" class="tr-top-nav-items__item nav-menu-last-item"
                     :class="$route.path === '/contact' ? 'tr-top-nav-items__item--active' : ''">{{ $t("general.contact") }}
        </router-link>
      </div>
      <LanguageSelect />
      <div v-if="!isUserLoggedIn" class="authentication-buttons">
        <router-link to="/register" class="tr-top-nav-items__item ml-auto">{{ $t("general.register") }}</router-link>
        <router-link to="/login" class="tr-top-nav-items__item">{{ $t("general.login") }}</router-link>
      </div>
      <div class="profile" :class="{hidden: !user, active: isMenuVisible }" @click="showMenu">
        <div v-if="unreadMessagesCount != 0" class="unread-conversations-count">{{ unreadMessagesCount }}</div>
        <img v-if="!user" class="user-icon" :class="{'green-bg': !background && !transparentBg}" src="../../assets/svg/icon-profile.svg">
        <img v-else-if="user && user.avatarFileName" class="user-icon" :src="userAvatarFilePath">
        <div v-else class="user-icon initials ml-auto" :class="{'green-bg': !background && !transparentBg}">{{ initials }}</div>
        <p class="m-0 icon-username">{{ user && user.userName }}</p>
      </div>
      <div class="user-menu" @click="closeMenu">
        <ul v-if="!user" class="user-menu__list unauthenticated"  @click.stop="">
          <li @click="closeMenu"><router-link to="/register"><img src="@/assets/svg/icon-arrow-right.svg" alt="">{{ $t("general.register") }}</router-link></li>
          <li @click="closeMenu"><router-link to="/login"><img src="@/assets/svg/icon-arrow-right.svg" alt="">{{ $t("general.login") }}</router-link></li>
          <button class="close-menu" @click="closeMenu"><img src="@/assets/svg/icon-close.svg" alt=""></button>
        </ul>
        <ul v-else class="user-menu__list authenticated" @click.stop="">
          <button class="close-menu" @click="closeMenu"><img src="@/assets/svg/icon-close.svg" alt=""></button>
          <li>
            <div class="avatar-with-username">
              <img v-if="user && user.avatarFileName" class="user-icon" :src="userAvatarFilePath">
              <div v-else class="user-icon initials ml-auto green-bg">{{ initials }}</div>
              <p class="username">{{ user.userName }}</p>
            </div>
          </li>
          <li v-if="userRole !== 'USER'" @click="closeMenu">
            <router-link to="/admin/travelTips">
              <img src="@/assets/svg/icon-administration.svg" alt="">{{ $t("general.administration") }}
            </router-link>
          </li>
          <li @click="closeMenu">
            <router-link to="/myTravelTips">
              <img src="@/assets/svg/icon-my-trips.svg" alt="">{{ $t("general.myTravelTips") }}
            </router-link>
          </li>
          <li @click="closeMenu">
            <router-link to="/messages">
              <div class="icon-message-container">
                <img src="@/assets/svg/icon-messages.svg" alt="">{{ $t("general.messages") }}
                <div v-if="unreadMessagesCount != 0" class="unread-conversations-count">{{ unreadMessagesCount }}</div>
              </div>
            </router-link>
          </li>
          <li @click="closeMenu">
            <router-link :to="userRole == 'AUTHOR' ? `/admin/manageUser/${userId}` : '/profile'">
              <img src="@/assets/svg/icon-edit-profile.svg" alt="">{{ $t("general.profile") }}
            </router-link>
          </li>
          <li @click="closeMenu">
            <button class="logout" @click="logout">
              <img src="@/assets/svg/icon-arrow-right.svg" alt="">{{ $t("general.logout") }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import {AUTH_LOGOUT} from "@/store/actions/auth"
import {getCookie} from "@/services/CookieService.js"
import { mapGetters } from 'vuex';
import {CLEVERTRIP_API_URL}  from '@/definitions';
import { USER_BY_ID } from '@/api/graphql/query/UserQuery'
import { LIKED_TRAVEL_TIPS } from '@/api/graphql/query/TravelTipQuery'
import sharedUtils from '@/utils/sharedUtils'
import LanguageSelect from "../LanguageSelect.vue";

export default {
  name: "NavBar",

  components: {
    LanguageSelect
  },

  data() {
    return {
      closeIcon: require(`@/assets/svg/icon-close.svg`),
      imgURL: CLEVERTRIP_API_URL + "/avatar/downloadFile/",
      isMenuVisible: false,
      isNavbarModalVisible: false,
      lastScrollY: 0,
      isHidden: false,
      isNavAtTheTop: true
    }
  },

  watch: {
    userId(nV) {
      if (!nV) {
        this.userLogout()
      }
    }
  },

  computed: {
    initials() {
      if (!this.user) return
      return sharedUtils.extractInitials(this.user.userName)
    },
    userAvatarFilePath() {
      if (!this.user?.avatarFileName) return
      return sharedUtils.getPictureUrlLowResolution(this.user.avatarFileName, this.imgURL)
    },
    isUserLoggedIn() {
      return this.userId != null
    },
    ...mapGetters({
      userId : 'currentUserId',
      userLogin: 'currentUser',
      userRole: 'currentUserRole',
      unreadMessagesCount: 'unreadMessagesCount',
    }),
  },

  props: {
    background: {
      type: Boolean,
      default: false
    },
    transparentBg: {
      type: Boolean,
      default: false
    },
    greenLogo: {
      type: Boolean,
      default: false
    }
  },

  created() {
    const loginCookie = getCookie('loginExpirationDate')
    if (loginCookie == null && this.$store.state.currentUserId != null) {
      this.userLogout()
    } else if (loginCookie != null && this.$store.state.currentUserId == null) {
      this.$store.commit('setCurrentUserId', localStorage.getItem('id'));
      this.$store.commit('setCurrentUser', localStorage.getItem('username'));
      this.$store.commit('setCurrentUserRole', localStorage.getItem('role'));
    }
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll() {
      if (this.isNavbarModalVisible) return
      const currentScrollY = window.scrollY;

      if (currentScrollY > 0) {
        this.isNavAtTheTop = false
      } else {
        this.isNavAtTheTop = true
      }
      if (currentScrollY > 300 && currentScrollY > this.lastScrollY) {
        this.isHidden = true;
      } else if (currentScrollY <= this.lastScrollY) {
        this.isHidden = false;
      }

      this.lastScrollY = currentScrollY;
    },
    userLogout() {
      this.user = null
      this.$store.commit('setCurrentUserId', null);
      this.$store.commit('setCurrentUser', null);
      this.$store.commit('setCurrentUserRole', null);
      this.$store.commit('setLikedTravelTipIds', null)

      localStorage.removeItem('id')
      localStorage.removeItem('username')
      localStorage.removeItem('role')
    },
    closeMenu() {
      this.isMenuVisible = false
    },
    showMenu() {
      this.isMenuVisible = !this.isMenuVisible
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT)
      if (this.$route.path != '/') {
        this.$router.push('/');
      } else {
        this.isMenuVisible = false
      }
      this.user = null
    },
    closeSamePath(path) {
      if (path == "/search") {
        this.$store.commit("setDefaultFilter")
        if (this.$store.state.sliderMaxValue) {
          this.$store.commit('setCurrentSlider', [1, this.$store.state.sliderMaxValue])
        }
        this.$store.commit("refreshResult")
      }
      this.isNavbarModalVisible = false
      document.querySelector('.tr-top-nav-items').classList.remove("slidein")
    },

    toggleMenu: function () {
      this.isNavbarModalVisible = !this.isNavbarModalVisible
      setTimeout(() => document.querySelector('.tr-top-nav-items').classList.toggle("slidein"),0)
    }

  },

  apollo: {
    user: {
      query: USER_BY_ID,
      variables: function() {
        return {
          userId: this.userId
        }
      },

      skip: function() {
        return !this.userId
      },
    },

    likedTravelTips: {
      query: LIKED_TRAVEL_TIPS,
      variables: function() {
        return {
          filter: {
            countries: [],
            themes: [],
            difficulties: [],
            minLengthOfTravel: 1,
            maxLengthOfTravel: 20,
            searchString: null,
            transferTypes: [],
            liked: true,
            progressState: null
          },
        }
      },
      
      skip: function() {
        return this.$store.state.currentUserId == null || this.$store.state.likedTravelTipIds !== null
      },

      fetchPolicy: 'network-only',

      result: function(resp) {
        this.$store.commit("setLikedTravelTipIds", resp.data.likedTravelTips.map(travelTip => travelTip.id))
      }
    },
  }
}
</script>

<style scoped lang="scss">
  @import '@/scss/variables';

.gray-bg {
  background-color: $tr-white-alt;
}

</style>
