export default {
    title: "Travel with us, we know what we're doing",
    whoAreWeTitle: "Who we are",
    whoAreWeText: "We love traveling. In the Czech Republic and around the world. Always ready to explore. We don't shy away from the 'must-see' attractions but are more excited about places off the beaten track. The ones that aren't in a regular guidebook. Where you won't meet mainstream tourists. And where remarkable experiences await. We enjoy chatting in the market queue and watching the sunrise on a remote viewpoint. With a cup of tea with local honey in hand. And we want to help other kindred spirits to do the same. That's why we started the Trekio platform: for sharing travel itineraries and tips on interesting places.",
    ourApproachTitle: "Our approach",
    ourApproachText: "Sure, we don't share all the unusual places we come across. We know that some are too rare and fragile. But we believe that the beauty of a place doesn't equate to how many people visit it. Fighting overtourism, because we realize that too many people in the same sites over and over can have devastating effects on the places and the lives of the locals while also spoiling the travel experience. And yet there are so many fascinating unknown places worth seeing, where even the local communities would welcome a few more visitors. Those are the ones we want to present in our itineraries! However, a sustainable harmony between humans and nature must always remain at the forefront. This is a crucial part of our approach. Whether it's preferring more environmentally friendly modes of transport, supporting local businesses, or spreading awareness of what grows and lives in the locations and providing practical information about protected areas directly in the descriptions of individual trips. It's more work, but we believe this work is essential. And we hope you will adopt such an approach on our trips too. After all, one does not set out on a trip to consume and destroy but rather to discover and admire, right?",
    preparingTripsIn15Countries: "Trips in 15 countries made for you!",
    howItAllStartedTitle: "How it all started",
    howItAllStartedText: "A few years ago, I missed the bus to Basel. I wanted to go to an art museum, but wouldn't make it anymore. Looking for a replacement program on my nearly dead phone, I found offers of overpriced guided tours and repetitive tips – always to the same places. Additionally, most of the websites were confusing or full of ads. They barely loaded... You can probably imagine that before I managed to find anything interesting, my phone battery was completely dead. Luckily, I had a printed map and could plan a simple trip around the area. I ended up having a great time.",
    howItAllStartedText2: "However, the inability to find enough information about any unique place in those 15 minutes frustrated me. I was disappointed that the top positions in the search results were occupied by commercial websites that were only trying to make money off their users. And yet the information provided looks like a badly copied Wikipedia. Moreover, all the tips for trips were repeated over and over. I started to develop an idea... If only there was a site that recommended original and new tips for places and trips, and, at the same time, contained useful, accurate information all in one place. Clearly organized and with criteria that could be easily filtered. And so Trekio was born...",
    fromAnIdeaToWorkingSearchEngineTitle: "From an idea to a working search engine",
    fromAnIdeaToWorkingSearchEngineText: "Several like-minded people shared my view, and that's how our team was formed. At the first meeting, we came up with the name Trekio, which was meant to describe our desire to offer suggestions for original trips and locations to the general public. The first steps of our officially named project began. We already had the basic concept, and all excited, we jumped to the graphic design. Which turned out to be much more complex. The layout, colors, and shapes of the individual components on the site were not as easy to implement as it seemed. In the end, the fact that one of the team members was a hobby designer helped a lot. Once we had the graphic design, we started the actual implementation.",
    fromAnIdeaToWorkingSearchEngineText2: "Many hours in the evenings were spent in front of our computer screens. Approximately three months after our first meeting, we had a working website. But it needed content. Fortunately, most of our team members had traveled all around the globe and could contribute with both text and images from their trips. The site gradually filled with dozens of travel itineraries, and we could celebrate our first success. Our project took the right direction, and we could see the traffic to the website slowly increasing.",
    wePlanSoYouDontHaveTo: "Travel without worries? We plan for you.",
    whatNextTitle: "What next?",
    whatNextText: "Trekio is moving forward by leaps, but we know it's still far from perfect. We're trying to improve the current version and iron out the kinks to make it as useful and enjoyable as possible. But we also have many new ideas for the future!",
    whatNextText2: "We don't know all the interesting unknown places either, and we love exploring new ones. That's why we're creating Trekio as a platform for sharing such discoveries and useful information about them. We'd like you, our trippers, to be able to participate in creating trips. So, we're rolling out a feature that allows you to create trips and add photos, practical advice, and share your experiences. In the future, we'd also like to have an app that could easily guide you through your chosen trip on the go.",
    whatNextText3: "We'd like to see a self-sustaining community form around the platform, adding what Trekio has lacked up to this point. Direct contact with users who are already traveling or contributing to our itineraries. So that together we can enliven some forgotten places and lighten up the tourist hotspots where too much traffic devastates nature and the atmosphere.",
    coolPlacesYouMightNotFind: "Cool places you might not have found on your own",
    wantToBecomeAuthor: "Do you want to share your trips on Trekio?",
}