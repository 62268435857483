export default {
    back: "Zpět",
    loadMore: "Načíst další",
    showMore: "Zobrazit více",
    showLess: "Zobrazit méně",
    
    link: "odkaz",
    search: "Vyhledat",
    here: "zde",

    youMightAlsoLike: "Mohlo by se ti líbit",
    popularTrips: "Oblíbené výlety",

    home: "Domů",
    travelTipAmmount: "{n} výletů | {n} výlet | {n} výlety",
    travelTip: "Výlet",
    travelTips: "Výlety",
    aboutUs: "O nás",
    howTo: "Jak to funguje",
    contact: "Kontakt",
    login: "Přihlásit",
    register: "Registrovat",
    administration: "Administrace",
    myTravelTips: "Moje výlety",
    messages: "Zprávy",
    pinboard: "Spolucestování",
    profile: "Profil",
    logout: "Odhlásit se",
    basicInfo: "Základní info",
    pictures: "Fotky",
    users: "Uživatelé",
    campaigns: "Kampaně",
    tags: "Tagy",
    equipments: "Vybavení",
    other: "Ostatní",
    
    yes: "Ano",
    no: "Ne",

    week: "Týden",
    month: "Měsíc",
    year: "Rok",
    min: "min",
    hour: "h",
    dayCapitalized: "Den",
    daysCapitalized: "Dny",
    day: "den",
    dayAmmount: "{n} dní | {n} den | {n} dny",
    dayOrder: "{0}. den",
    dayRoute: "Trasa dne",
    itineraryItem: "Zastávka",
    itineraryItems: "Zastávky",
    startItem: "Výchozí bod",
    finishItem: "Cílový bod",
    itemOrder: "{0}. zastávka",


    theme: "Téma",
    culture: "Kultura",
    food: "Jídlo",
    adventure: "Zážitky",
    nature: "Příroda",

    address: "Adresa",
    country: "Země",
    countries: "Země",
    length: "Délka",
    assessments: "Hodnocení",
    publishedFem: "Publikovaná",
    publishedMasc: "Publikovaný",
    publishedPlural: "Publikované",
    hiddenFem: "Skrytá",
    hiddenMasc: "Skrytý",
    hiddenPlural: "Skryté",
    numberOfTravelTips: "X výletů",
    footerText: "Objevování nikdy nebylo snazší",
    termsOfUse: "Podmínky používání",
    principlesDataProcessing: "Zásady zpracování osobních údajů",
    signInAsAdmin: "Přihlásit se jako autor",
    redirectToHome: "Přejít domů",
    showResults: "Zobrazit",
    all: "Vše",
    allFemAndLifeless: "Všechny",
    allNeutral: "Všechna",
    allMascLiving: "Všichni",

    transport: "Doprava",
    walk: "Chůze",
    flight: "Letadlo",
    bus: "Autobus",
    boat: "Loď",
    train: "Vlak",
    car: "Auto",
    bike: "Kolo",
    publicTransport: "MHD",
    scooter: "Koloběžka",
    rollerScate: "Inline brusle",
    cableCar: "Lanovka",

    difficulty: "Náročnost",
    low: "Nízká",
    medium: "Střední",
    high: "Vysoká",
    noDifficulty: "Nebyla zadána náročnost",
    mediumHigh: "Střední až Vysoká",
    lowMedium: "Nízká až Střední",
    lowHigh: "Nízká až Vysoká",

    sunday: "Neděle",
    monday: "Pondělí",
    tuesday: "Úterý",
    wednesday: 'Středa',
    thursday: 'Čtvrtek',
    friday: 'Pátek',
    saturday: 'Sobota',

}