export default {
    usingPlatformTrekioAgreement: "By using the Trekio Platform, you agree to the following terms of use and acknowledge our Privacy Policy.",
    definitions: "Definitions",
    definitionsParagraph1: "Trekio refers to the {trekioUrl} website and the Trekio mobile app.",
    definitionsParagraph2: "Content refers to all data posted by users on Trekio, including text, photos, ratings, information, and user communications through the Trekio platform.",
    definitionsParagraph3: "A Trip is a special type of content that can only be created by a user registered with the Author type of account. It is a travel itinerary consisting of text, route, and photos. This type of content must be approved by Trekio administrators before publication.",
    profileRegistrationAndSecurity: "Profile registration and security",
    profileRegistrationAndSecurityParagraph1: "You do not need to register to use some of Trekio's features. Non-registered users may view some content and use some features of the mobile app if they comply with our terms and conditions and standards of conduct.",
    profileRegistrationAndSecurityParagraph2: "You must register for an account to fully use the Trekio platform. By registering, you accept these terms and conditions and acknowledge our {principlesDataProcessingUrl}. By doing so, we become contractual partners, each of whom has contractual rights and obligations.",
    profileRegistrationAndSecurityParagraph3: "Registration on the Trekio platform is free of charge. You can register as a regular User or as an Author who can create Trips.",
    profileRegistrationAndSecurityParagraph4: "By registering as an Author, you also enter into a Licensing Agreement with Trekio.",
    profileRegistrationAndSecurityParagraph5: "You can register by entering a valid email address and secure password or via social media (if this option is available, e. g. Google or Facebook). You choose a username and optionally can add your own first name, last name and phone number to your profile. You can also optionally add a profile picture after you register your profile. You do not need to fill in the optional information. By filling them in and saving them, you consent to the processing of this personal data.",
    profileRegistrationAndSecurityParagraph6: "In the case of an Author account, you must provide your full name, address and date of birth for purposes of the Licensing Agreement.",
    profileRegistrationAndSecurityParagraph7: "Your registration becomes effective once you have completed the registration process and we have activated your account or otherwise confirmed this to you.",
    profileRegistrationAndSecurityParagraph8: "Your account on Trekio is your personal account. You are solely responsible for all activities conducted through your account. You agree to notify us immediately of any actual or suspected unauthorized access to your account.",
    profileRegistrationAndSecurityParagraph9: "You agree not to enter any false information about yourself on the Trekio platform and not to create profiles for others without their and our express permission. You agree to not create usernames that could be confused with existing usernames, infringe the rights of third parties, or are otherwise illegal or inappropriate. The administrators of the Trekio platform may modify usernames in their sole discretion.",
    profileRegistrationAndSecurityParagraph10: "You agree to keep your personal information and profile contact information up to date.",
    rightsToUploadedContent: "Rights to uploaded content",
    rightsToUploadedContentParagraph1: "The User or Author who uploads any Content to Trekio, including Trips, confirms by submitting such Content that s/he is the owner of all copyrights to the Content uploaded.",
    rightsToUploadedContentParagraph2: "By uploading Content to our platform, we are granted an unrestricted license for the entire term of the proprietary rights in that Content, which may only be revoked in writing and, in the case of Trips created by an Author, only under the terms of the applicable Licensing Agreement. This license also applies to deleted data.",
    rightsToUploadedContentParagraph3: "In the case of Trips created by Authors, all licensing rights (including rights to the text and photographs associated with the Trip) are governed by the Licensing Agreement between such user and Trekio.",
    rightsToUploadedContentParagraph4: "Publication of any Content means that such information is freely available worldwide on the Internet and anyone may link to it. By posting Content on Trekio, you agree to make such Content available to the public worldwide in non-tangible form through Trekio, and you agree that you will have no claim against Trekio or anyone else for any consideration or compensation.",
    rightsToUploadedContentParagraph5: "You are responsible for ensuring that you alone are authorized to consent to these terms and conditions, or that you do so on the valid behalf of any other persons whose consent is required.",
    rightsToUploadedContentParagraph6: "The operator and administrators of Trekio assume no obligation to protect the Content posted by you and shall not be liable for any infringement of your rights in the Content you post on Trekio.",
    rightsToUploadedContentParagraph7: "We are grateful for any comments and suggestions you may have about the operation and improvement of Trekio, but please note that by using your idea or the material you submit, Trekio is under no obligation to compensate you in any way for your idea or use of the material and information contained therein; by submitting it, you agree to the use of it under these terms and conditions, including the no-compensation policy.",
    rightsToUploadedContentParagraph8: "For content covered by our intellectual property rights and accessible through the Trekio platform (such as images, design or Trips provided by us), we retain all rights.",
    rightsToUploadedContentParagraph9: "Our prior written consent is required for the continued use of any content from Trekio (placed by Users, Authors or platform administrators and operators) and parts of the platform itself. You must obtain our written permission (or permission under an open source license) to modify, translate, create derivative works from, decompile or reverse engineer content hosted on Trekio or portions thereof, or otherwise attempt to extract our source code, except where an exception or restriction under applicable law applies.",
    termsOfUse: "Terms of use",
    termsOfUseParagraph1: "You agree to not post any Content on Trekio or take any action that would infringe or threaten the rights of others. You also agree to not use Trekio for illegal or unfair activities.",
    termsOfUseParagraph2: "The Content you post on Trekio will not promote or contain racial, national, religious or other intolerance or discrimination or illegal activities. The content you post will respect the privacy of others.",
    termsOfUseParagraph3: "You agree not to post on Trekio any commercial communications in any form which would not be previously authorized by us.",
    termsOfUseParagraph4: "You are not allowed to collect any data about Trekio users. You agree not to upload viruses or malware (or content that you are unsure about), nor post links to sites that may contain viruses, malware or any other offensive software. You will not attack other users' profiles or otherwise attempt to tamper with profiles that you have not created yourself.",
    termsOfUseParagraph5: "You will not do anything that could jeopardize the operation of the Trekio Platform or in any way damage its system or proper functionality. Any modifications to the profile that are not directly prepared in the profile administration are prohibited.",
    termsOfUseParagraph6: "You agree to report any errors found in the system to us and not exploit them.",
    termsOfUseParagraph7: "Every user is required to be familiar with the current version of the Terms and conditions. Trekio reserves the right to amend these terms without notice. We will notify you about major encroachments and changes to these terms by the registered email address.",
    termsOfUseParagraph8: "Violations of the rules and the interpretation of any of the above points are at Trekio's sole discretion.",
    violationOfUserTermsAndConditions: "Violation of user terms and conditions",
    violationOfUserTermsAndConditionsParagraph1: "We reserve the right to remove any of your content if we believe it violates these platform terms and conditions of use. If you believe your rights are being violated in any way, please email us at {trekioInfoEmail}",
    violationOfUserTermsAndConditionsParagraph2: "Should you repeatedly violate these platform terms and conditions of use, we reserve the right to terminate your profile and block your access to Trekio.",
    ourLiabilityForTheContent: "Our liability for the content ",
    ourLiabilityForTheContentParagraph1: "We are not responsible for the Content placed by users on Trekio, its use and disposal. If anyone commences any litigation, legal or otherwise, against your actions or Content posted by you on Trekio, including claims against Trekio, you agree to reimburse us for all costs associated therewith, including attorneys' fees, in the actual amount of the claim. The same applies to the out-of-court assertion and settlement of such claims, if any.",
    ourLiabilityForTheContentParagraph2: "Trekio shall have no responsibility or liability for the Content, its use and disposition. You may find travel itineraries and recommendations on the platform, but we are not responsible for the accuracy or timeliness of the information provided within the Content, or for the consequences of using such Content. (If you decide to take a trip, we are not responsible for how it is conducted, how it will go, or if you get hurt on the trip.)",
    ourLiabilityForTheContentParagraph3: "We are a provider of space on the internet, which we try to manage to the best of our knowledge and conscience, and which we protect as a precautionary measure by these terms and conditions of use.",

    theseTermsTakeEffectOn: "These terms and conditions take effect on 15 April 2024."
}