import Vue from 'vue'
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import admin from "@/store/modules/admin";


Vue.use(Vuex);

const TRAVEL_TIPS_SINGLE_PAGE_SIZE = 20
const SLIDER_VALUE_DEFAULT = [1, undefined]

export default new Vuex.Store({
    modules: {
        //user,
        auth,
        admin
    },

    state: {
        languages: [
            { code: 'cs', nameCzech: 'Čeština', nameEnglish: 'Czech' },
            { code: 'sk', nameCzech: 'Slovenština', nameEnglish: 'Slovak', isSecondary: true},
            { code: 'en', nameCzech: 'Angličtina', nameEnglish: 'English' }
        ],
        publicLanguageCodes: ['cs', 'en'],
        currentCampaign: null,
        campaigns: [],
        campaignFavorite: null,
        campaignSimilar: null,
        currentUser: null,
        currentUserRole: null,
        currentUserId: null,
        currentFilter: null,
        currentSliderValue: SLIDER_VALUE_DEFAULT,
        currentPageSize: null,
        resultRefresh: true,
        travelTipsActual: [],
        itineraryItemLocations: [],
        sliderMaxValue: SLIDER_VALUE_DEFAULT[1],
        likedTravelTipIds: null,
        unreadMessagesCount: 0,
        preferredLanguage: "cs"
    },

    getters: {
        currentUserId: state => {
            return state.currentUserId;
        },

        currentUser: state => {
          return state.currentUser;
        },

        currentUserRole: state => {
            return state.currentUserRole;
        },

        campaigns: state => {
            return state.campaigns
        },

        actualCampaign: state => {
            let currentDate = new Date();
            //get last valid campaign. Campaigns are desc ordered by their validTo date
            //therefore the last valid campaign will be ending first => it is the actual campaign
            console.log("State Campaigns: ", state.campaigns);
            let validCampaigns = state.campaigns.filter(campaign => {
                return ((campaign.validFrom <= currentDate) && (campaign.validTo >= currentDate));
            });
            return (validCampaigns && validCampaigns.length > 0) ? validCampaigns[validCampaigns.length - 1] : null;
        },

        currentFilter: state => {
            return state.currentFilter;
        },

        currentSlider: state => {
            return state.currentSliderValue;
        },

        currentPageSize: state => {
            return state.currentPageSize;
        },

        resultRefresh: state => {
            return state.resultRefresh;
        },

        travelTipsActual: state => {
            return state.travelTipsActual;
        },

        itineraryItemLocations: state => {
            return state.itineraryItemLocations;
        },
        sliderMaxValue: state => {
            return state.sliderMaxValue;
        },
        unreadMessagesCount: state => {
            return state.unreadMessagesCount
        },
        languages: state => {
            return state.languages
        },
        preferredLanguage: state => {
            return state.preferredLanguage
        }
    },

    mutations: {
        setIsLoadingCampaigns(state, isLoading) {
            state.isLoadingCampaigns = isLoading
        },
        setCurrentUserId(state, currentUserId) {
            state.currentUserId = currentUserId;
        },

        setCurrentUser(state, currentUser) {
            state.currentUser = currentUser;
        },

        setCurrentUserRole(state, currentUserRole) {
            state.currentUserRole = currentUserRole;
        },

        setCampaignFavorite(state, campaignFavorite) {
            state.campaignFavorite = campaignFavorite;
        },
        setCampaignSimilar(state, campaignSimilar) {
            state.campaignSimilar = campaignSimilar;
        },

        setCurrentFilter(state, filter) {
            state.currentFilter = filter;
            state.currentSliderValue = [filter.minLengthOfTravel, filter.maxLengthOfTravel];
        },

        setDefaultPage(state) {
            state.currentPageSize.page = 0
        },

        setNextPage(state) {
            state.currentPageSize.page += 1

        },

        setDefaultFilter(state) {
            if (!state.currentFilter) {
                state.currentFilter = {
                    countries: [],
                    themes: [],
                    difficulties: [],
                    minLengthOfTravel: 1,
                    maxLengthOfTravel: state.sliderMaxValue,
                    transferTypes: [],
                    tagIds: [],
                    searchString: '',
                }
            } else {
                state.currentFilter.countries = []
                state.currentFilter.themes = [],
                state.currentFilter.difficulties = [],
                state.currentFilter.minLengthOfTravel = 1,
                state.currentFilter.maxLengthOfTravel = state.sliderMaxValue,
                state.currentFilter.transferTypes = [],
                state.currentFilter.tagIds = [],
                state.currentFilter.searchString = ''
            }

            state.currentPageSize = {
                page: 0,
                size: TRAVEL_TIPS_SINGLE_PAGE_SIZE,
                sortAttribute: 'VIEW_COUNT',
                sortDirection: 'DESC'
            }
        },

        setCurrentSlider(state, sliderValue) {
            state.currentSliderValue = sliderValue;
        },

        setCurrentPageSize(state, pageSize) {
            state.currentPageSize = pageSize;
        },

        refreshResult(state) {
            state.resultRefresh = !state.resultRefresh;
        },

        setTravelTipsActual(state, travelTipsActual) {
            state.travelTipsActual = travelTipsActual;
        },

        setItineraryItemLocations(state, index, location) {
            state.itineraryItemLocations[index] = location
        },
        setSliderMaxValue(state, maxValue) {
            state.sliderMaxValue = maxValue
            if (state.currentFilter) {
                state.currentFilter.maxLengthOfTravel = maxValue
            }
        },
        setLikedTravelTipIds(state, travelTipIds) {
            state.likedTravelTipIds = travelTipIds
        },
        setUnreadMessagesCount(state, count) {
            state.unreadMessagesCount = count
        },
        setPreferredLanguage(state, language) {
            state.preferredLanguage = language
        }
    },

    actions: {

    }
});
