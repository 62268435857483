import Vue from "vue";
import {CLEVERTRIP_API_URL} from "../definitions";
import VueApollo from "vue-apollo";
import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client'
import store from '@/store/store';

Vue.use(VueApollo);

// Cache implementation
const cache = new InMemoryCache();

// HTTP connection to the API
const httpLink = createUploadLink({
    uri: CLEVERTRIP_API_URL+"/graphql",
    credentials: process.env.VUE_APP_APOLLO_DEFAULT_CREDENTIALS,
});

const authLink = setContext((_, { headers }) => {
    const { preferredLanguage } = store.state;
    return {
      headers: {
        ...headers,
        'Entity-Language': preferredLanguage,
      },
    };
  });

export const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache
});

export const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    errorHandler (error) {
        // If token time runs out redirect user to login page
        if (error.toString().includes('Access is denied') || error.toString().includes("Přístup odepřen")) {
            this.$router.push({path: "/login", query: {returnUrl: this.$router.currentRoute.path}}).catch(routerError => {
                if (routerError.name != "NavigationDuplicated") {
                    throw routerError;
                }
            });
        }
        // Global error handler for all smart queries and subscriptions
        console.log('Global error handler')
        console.error(error)
    },
});

//export default {apolloClient, apolloProvider}



