export default {
    maxLength160: "Max. 160 characters",
    maxLength40: "Max. 40 characters",
    maxLength1024: "Max. 1024 characters",
    maxLength: "Max. {0} characters",
    min3Max160: "Min. 3 characters - max. 160 characters",
    ammountOfSymbols: "Number of characters: {0}",
    fileSize: "File size must not exceed 12.5 MB",
    resolutionTooSmall: "The image resolution is too low!",
    resolutionCouldBeHigher: "The image resolution could be higher!",
    resolutionIsCorrect: "The image resolution is correct!",
    resolutionIs: "The resolution is {0} / {1}"
}