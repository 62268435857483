<template>
    <div class='input-wrapper' :class="{'dynamic-label': dynamicLabel, 'static-label': !dynamicLabel}" :style="inputStyle">
      <div v-if="info" class="info-icon" v-tooltip="{content: info, placement: 'right-start'}"></div>
      <div v-if="searchInput" class="search-icon">
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.1421 17.1421L12.1421 13.1421" stroke="#222222"/>
          <circle cx="7.5" cy="7.5" r="7" stroke="#222222"/>
        </svg>
      </div>
      <input
        :type="isPasswordVisible ? 'text' : type"
        :id="id"
        :class="{error, disabled, 'icon-input-with-info': ['password', 'date'].includes(this.type) && info, 'white-bg': whiteBg, 'dark-bg': darkBg}"
        :value="value"
        :required="required"
        :disabled="disabled"
        :placeholder="dynamicLabel ? ' ' : $attrs.placeholder ? $attrs.placeholder : null" 
        v-bind="$attrs"
        @input="onInput"
        @focus="$emit('focus', $event)"
        @blur="onBlur"
        @keypress="$emit('keypress', $event)"
        @change="$emit('change', $event)">
      <label 
        :for="id"
        :data-required="required">
        {{ label }}
      </label>
      <img v-if="type == 'password' && !isPasswordVisible" @click="isPasswordVisible = true" class="show-password" src="@/assets/svg/show-password.svg" alt="">
      <img v-else-if="type == 'password'" @click="isPasswordVisible = false" class="show-password" src="@/assets/svg/hide-password.svg" alt="">
      <span v-if="error" class="input-alert">{{ error }}</span>
      <span v-if="hint" class="input-hint">{{ hint }}</span>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      width: {
        type: String
      },
      height: {
        type: String
      },
      maxWidth: {
        type: String
      },
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
      },
      type: {
        type: String,
        default: 'text',
      },
      value: {
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      error: {
        type: String,
      },
      hint: {
        type: String
      },
      dynamicLabel: {
        type: Boolean,
        default: false,
      },
      info: {
        type: String
      },
      whiteBg: {
        type: Boolean
      },
      darkBg: {
        type: Boolean
      },
      searchInput: {
        type: Boolean,
      },
      minHeight: {
        type: String
      },
      trim: {
        type: Boolean
      },
      debounceTime: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        isPasswordVisible: false,
        debounceTimeout: null
      }
    },

    methods: {
      onInput(event) {
        if (this.debounceTime) {
          clearTimeout(this.debounceTimeout)
            this.debounceTimeout = setTimeout(() => {
              this.$emit('input', event.target.value)
            }, this.debounceTime);
        } else {
          this.$emit('input', event.target.value)
        }
      },
      onBlur(event) {
        if (this.trim) {
          this.$emit('input', event.target.value.trim())
        }
        this.$emit('blur', event)
      }
    },

    computed: {
      inputStyle() {
        const style = {}
        if (this.height) {
            style.height = this.height
        }
        if (this.maxWidth) {
            style.maxWidth = this.maxWidth;
        }
        if (this.width) {
            style.width = this.width
        }
        return style;
      }
    },

    inheritAttrs: false,
  };
  </script>
  
  <style scoped lang="scss">
    @import '@/scss/variables';

    .input-wrapper {
        max-width: 330px;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 5px;

        label {
          position: absolute;
          pointer-events: none;
          color: $tr-gray;
          line-height: 16px;

          &[data-required]::after {
              content: "*";
              margin-left: 3px;
          }
        }

        &.dynamic-label {
          label {
            top: 20px;
            width: 100%;
            left: 20px;
            transition: top 0.1s ease-in-out, color 0.05s ease-in-out, font-size 0.1s ease-in-out;
          }

          & input:not(:placeholder-shown), & input:autofill {
            padding-top: 15px;
            
            + label {
              font-size: 11px;
              top: 6px     
            }
          } 

          input:focus + label {
            color: $tr-black;
            font-size: 11px;
            top: 6px
          }
        }

        &.static-label label {
            font-size: 11px;
            left: 20px;
            top: 7px;
            z-index: 2;
        }

        .info-icon {
          position: absolute;
          right: 20px;
          top: 22px;
        }

        .info-icon + input {
          padding-right: 45px;
        }

        input {
            padding: 7px 18px 0 18px;
            height: 60px;
            width: 100%;
            vertical-align: top;
            border: 2px solid transparent;
            background-color: $tr-white-alt;
            border: 2px solid transparent;

            &.icon-input-with-info {
              padding-right: 45px;

              &#password {
                padding-right: 80px;

                & ~ img {
                  right: 45px;
                }
              }
            }

            &.white-bg {
              background-color: $tr-white
            }
            &.dark-bg {
              background-color: $tr-light-gray
            }

            &.error {
              border-bottom: $tr-pink solid 2px !important;
            }

            &:focus {
              border: 2px solid $tr-green;
            }

            &:disabled {
              color: $tr-gray;

              & + label {
                color: $tr-mid-gray;
              }
            }
        }

        .search-icon {
          position: absolute;
          left: 20px;
          top: 18px;

          & + input {
            padding-left: 50px;
          }
        }

        .input-hint {
          color: $tr-gray;
          font-size: 12px;
        }

        .input-alert {
          color: $tr-pink;
          font-size: 12px;
        }

        #password, #repeatPassword {
            padding-right: 65px;
        }
        .show-password {
            cursor: pointer;
            position: absolute;
            right: 19px;
            top: 18px;
            width: 24px;
            height: 24px;
        }
      }

  </style>
  