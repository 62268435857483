export default {
    travelTipCampaign: "Kampaň",
    travelTipTitle: "Název výletu",
    travelTipShortInfo: "Podnadpis",
    travelTipPerex: "Perex",
    travelTipDescription: "Popis",
    travelTipHashtags: "Hashtagy",
    travelTipTheme: "Téma",
    travelTipVideoDescription: "Popisek videa",
    travelTipVideoUrl: "Odkaz na video",
    
    dayTitle: "Název dne",
    dayDescription: "Popis dne",
    dayPicture: "Fotka dne",

    itemTitle: "Název zastávky",
    itemDescription: "Popis zastávky",
    itemLatitude: "Latitude",
    itemLongitude: "Longitude",
    itemDuration: "Odhadovaný čas strávený na zastávce (min)",
    itemRouteLength: "Délka cesty (km)",
    practicalInfo: "Praktické info",
    countries: "Země",
    country: "Země",
    difficulty: "Náročnost",
    transport: "Doprava",

    email: "E-mail",
    login: "E-mail",
    password: "Heslo",
    username: "Přezdívka",
    repeatPassword: "Zopakovat heslo",

    aboutAuthor: "Medailonek",
    role: "Role",
    firstName: "Křestní jméno",
    lastName: "Příjmení",
    dateOfBirth: "Datum narození",
    phoneNumber: "Telefonní číslo",
    city: "Město",
    street: "Ulice",
    houseNumber: "Číslo popisné",
    zipCode: "PSČ",
    additionalInfo: "Dodatečné info k adrese",
    authorEmail: "Autorův e-mail",
    campaignTitle: "Název",
    campaignDescription: "Popis",
    campaignValidFrom: "Platnost od",
    campaignValidTo: "Platnost do",
    pictureTitle: "Název Fotky",
    pictureInputFile: "Přiložený soubor",
    assignedToTravelTip: "Přiřazený k výletu",
    name: "Jméno",
    message: "Zpráva",
    language: "Jazyk",

    state: "Stav",
    originalLanguage: "Výchozí jazyk",
    chooseOriginalLanguage: "Vybrat výchozí jazyk",
    choosePrimaryLanguage: "Vybrat primární jazyk",
    showTranslation: "Zobrazit překlad",

    author: "Autor",
    contractSigned: "Smlouva podepsána",

    pinboardTitle: "Název nástěnky",
    pinboardDescription: "Popis nástěnky",
    pinboardPostTitle: "Nadpis",
    pinboardPostDescription: "Popis příspěvku",
    tripLength: "Délka výletu",
    leaveEarliestOn: "Vyrazím nejdříve od",
    returnLatestOn: "Konec výletu nejpozději do",

    categoryTitle: "Název kategorie",
}
