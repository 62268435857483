const state = {
    isSidebarVisible: true,
    travelTipsFilter: null,
    picturesFilter: null,
    campaignsFilter: null,
    usersFilter: null,
    loggingOut: false,
    itemForms: {},
    errorIds: [],
    itemRouteLengths: {},
    equipmentCategories: [],
};

const getters = {
    getIsSidebarVisible(state) {
        return state.isSidebarVisible
    },
    getTravelTipsFilter (state) {
        return state.travelTipsFilter
    },
    getPicturesFilter (state) {
        return state.picturesFilter
    },
    getCampaignsFilter (state) {
        return state.campaignsFilter
    },
    getUsersFilter (state) {
        return state.usersFilter
    },
    getLoggingOut (state) {
        return state.loggingOut
    },
    getAllItemForms: (state) => {
        return state.itemForms
    },
    getItemLocation: (state) => (payload) => {
        const {dayId, itemId} = payload
        const index = state.itemForms['day' + dayId].findIndex(item => item.id == itemId)
        return state.itemForms['day' + dayId][index].location
    }
    
};

const actions = {

};

const mutations = {
    setIsSidebarVisible(state, isVisible) {
        state.isSidebarVisible = isVisible
    },
    setTravelTipsFilter (state, filter) {
        state.travelTipsFilter = filter
    },
    setPicturesFilter (state, filter) {
        state.picturesFilter = filter
    },
    setCampaignsFilter (state, filter) {
        state.campaignsFilter = filter
    },
    setUsersFilter (state, filter) {
        state.usersFilter = filter
    },
    setDefaultAdminFilters (state) {
        state.travelTipsFilter = null;
        state.picturesFilter = null;
        state.campaignsFilter = null;
        state.usersFilter = null;
        state.loggingOut = true;
    },
    setLoggingOut (state, loggingOut) {
        state.loggingOut = loggingOut
    },
    setItemForms (state, payload) {
        const {dayId, items} = payload
        state.itemForms['day' + dayId] = items

        for (let i; i < items.length; i++) {
            state['itemLocation' + items[i].id] = {longitude: items[i].location.longitude, latitude: items[i].location.latitude}
            state.itemRouteLengths['item' + items[i].id] = null
        }
    },
    setSingleItemForm (state, payload) {
        const {dayId, index, itemForm} = payload
        state.itemForms['day' + dayId][index] = state.form = itemForm
    },
    setTitle (state, payload) {
        const {dayId, index, title} = payload
        state.itemForms['day' + dayId][index]['localizations'][0].title = title
    },
    setCountries (state, payload) {
        const {dayId, index, countries} = payload
        state.itemForms['day' + dayId][index].countries = countries
    },
    changeItemPlaces (state, payload) {
        const {dayId, draggedIndex, draggedItem, index} = payload
        let items = state.itemForms['day' + dayId]
        let sortWithNumbers = false
        const draggedItemNumber = items[draggedIndex].itemNumber
        let draggedOverNumber = items[index].itemNumber
        if (items[index].itineraryItemType !='EMPTY' && items[draggedIndex].itineraryItemType == 'EMPTY') {
            if (draggedItemNumber > draggedOverNumber) {
                draggedOverNumber = items[index - 1]?.itemNumber + 1 
            }
            else {
                draggedOverNumber += 1
                
            }
            sortWithNumbers = true
        }        
        items[draggedIndex].itemNumber = draggedOverNumber
        if (items[index].itineraryItemType == 'EMPTY' || items[draggedIndex].itineraryItemType != 'EMPTY') {
            items[index].itemNumber = draggedItemNumber
        }
        
        items.splice(draggedIndex, 1);
        items.splice(index, 0, draggedItem);
        
        if (!sortWithNumbers) {
            state.itemForms['day' + payload.dayId].sort((a, b) => {
                if (a.itineraryItemType === "START" || b.itineraryItemType === 'FINISH') return -1;
                else if (b.itineraryItemType === "START" || a.itineraryItemType === 'FINISH') return 1;
                else return a.itemNumber - b.itemNumber;
            })
        }
        
        state.itemForms['day' + dayId] = items
        if (sortWithNumbers) {
            this.commit('sortItems', dayId)
        }
    },
    setTransferTypeValues (state, payload) {
        const {transferTypeValues, dayId, itemIndex} = payload
        state.itemForms['day' + dayId][itemIndex].transferTypeValues = transferTypeValues 
    },
    setCoords (state, payload) {
        const {coords, dayId, itemId} = payload
        const index = state.itemForms['day' + dayId].findIndex(item => item.id == itemId)
        state.itemForms['day' + dayId][index].location['longitude'] = coords.x 
        state.itemForms['day' + dayId][index].location['latitude'] = coords.y 
    },
    addNewItem (state, payload) {
        const {dayId, item} = payload
        const index = state.itemForms['day' + dayId].findIndex(stateItem => stateItem.id == item.id)
        state.itemForms['day' + dayId].push(item)

    },
    addErrorId (state, id) {
        let errorIds = state.errorIds
        errorIds.push(id)
        state.errorIds = errorIds
    },
    resetErrorIds (state) {
        state.errorIds = []
    },
    
    deleteItineraryItem (state, payload) {
        for (let i = 0; i < state.itemForms['day' + payload.dayId].length; i++) {
            if (state.itemForms['day' + payload.dayId][i].id === payload.itemId) {
                state.itemForms['day' + payload.dayId].splice(i, 1);
                i--;
            }
        }
        this.commit('sortItems', payload.dayId)
    },
    addItineraryItem (state, payload) {
        if (!state.itemForms['day' + payload.dayId]) state.itemForms['day' + payload.dayId] = []
        state.itemForms['day' + payload.dayId].push({
            ...payload.item,
            location: {...payload.item.location},
            localizations: payload.item.localizations.map(loc => ({...loc}))
        })
        state.itemForms['day' + payload.dayId].sort((a, b) => {
            if (a.itineraryItemType === "START" || b.itineraryItemType === 'FINISH') return -1;
            else if (b.itineraryItemType === "START" || a.itineraryItemType === 'FINISH') return 1;
            else return a.itemNumber - b.itemNumber;
        })
        this.commit('sortItems', payload.dayId)
        
    },
    sortItems (state, dayId) {
        let defaultItemsNumber = 0
        let emptyItemNumber = 0
        for (let i = 0; i < state.itemForms['day' + dayId].length; i++) {
            if (state.itemForms['day' + dayId][i].itineraryItemType == "START") {
                state.itemForms['day' + dayId][i].itemNumber = 0
            }
            else if (state.itemForms['day' + dayId][i].itineraryItemType == "FINISH") {
                state.itemForms['day' + dayId][i].itemNumber = 1000
            }
            else if (state.itemForms['day' + dayId][i].itineraryItemType == "DEFAULT") {
                defaultItemsNumber += 10
                emptyItemNumber = 0
                state.itemForms['day' + dayId][i].itemNumber = defaultItemsNumber
            }
            else if (state.itemForms['day' + dayId][i].itineraryItemType == "EMPTY") {
                emptyItemNumber += 1
                state.itemForms['day' + dayId][i].itemNumber = defaultItemsNumber + emptyItemNumber
            }
        }
    },
    
    createDayItemArray (state, dayId) {
        state.itemForms['day' + dayId] = []
    },
    
    setItemRouteLength (state, payload) {
        state.itemRouteLengths[`item${payload.itemId}`] = payload.routeLength
    },
    resetRouteLengths (state, items) {
        for (let i = 0; i < items.length; i++) {
            if (items[i].itineraryItemType != 'EMPTY') {
                state.itemRouteLengths[`item${items[i].id}`] = null
            }
        }
    },
    setEquipmentCategories (state, payload) {
        state.equipmentCategories = payload
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
