export default {
    title: "Inspire. Share. Help. Become an author!",
    whoWeAre: "Who we are",
    whoWeAreText: "We love traveling. In the Czech Republic and around the world. Always ready to explore. We don't shy away from the 'must-see' attractions but are more excited about places off the beaten track. The ones that aren't in a regular guidebook. Where you won't meet mainstream tourists. And where remarkable experiences await. We enjoy chatting in the market queue and watching the sunrise on a remote viewpoint. With a cup of tea with local honey in hand. And we want to help other kindred spirits to do the same. That's why we started the Trekio platform: for sharing travel itineraries and tips on interesting places.",
    ourApproach: "Our approach",
    ourApproachText: "Sure, we don't share here all the unusual places we come across. We know that some are too precious and fragile. But we believe that the beauty of a place doesn't equate to how many people visit it. Fighting overtourism, because we realize that too many people in the same sites over and over can have devastating effects on the places and the lives of the locals while also spoiling the travel experience. And yet there are so many fascinating unknown places worth seeing, where even the local communities would welcome a few more visitors. Those are the ones we want to present in our itineraries! However, a sustainable harmony between humans and nature must always remain at the forefront. This is a crucial part of our approach. Whether it's preferring more environmentally friendly modes of transportation, supporting local businesses, or spreading awareness of what grows and lives in the locations and providing practical information about protected areas directly in the descriptions of individual trips. It's more work, but we believe this work is essential. And we hope you will adopt such an approach too. After all, one does not set out on a trip to consume and destroy but rather to discover and admire, right?",
    whoAreWeLookingFor: "Who are we looking for?",
    whoAreWeLookingForText1: "We don't know all the worthwhile places off the beaten path ourselves. And we like to explore new ones. That's why we're now looking for reliable travel itinerary writers to work with us on a freelance basis. People who think like us. People who love to travel and explore. Or those who want to share the highlights of their region. Because tips from locals are usually the most valuable!",
    whoAreWeLookingForText2: "At the moment we want to focus on trips in the Czech Republic, Slovakia and Poland. But if you're interested and would like to share tips from somewhere else, feel free to contact us too. Let's see what kind of cooperation we can agree on 😊",
    whoAreWeLookingForText3: "We'd love to see a self-sustaining community form around the platform over time. Using their ideas, experiences and comments to give Trekio what it has lacked up to now; direct contact with people. With users who are already travelling based on our itineraries or want to be involved in creating them. So that together we can bring forgotten places back to life. And, on the other hand, ease the adverse impact of tourism on mainstream attractions where too many visitors are devastating the nature and the atmosphere.",
    whatIsItAllAbout: "What is it all about?",
    whatIsItAllAboutText1: "Each of our trips is an itinerary with a planned route, descriptions of the places to visit, photos and useful information. On top of that, explanation of the natural and cultural conditions is also important. So that every traveller knows clearly what they are allowed to do in each place and what to avoid. And why. We want to make it as easy as possible to travel responsibly.",
    whatIsItAllAboutText2: "It's a lot of work and time, but we can appreciate a well-crafted trip. And we've got a detailed how-to guide for you, too.",
    whatWillYouGetOutOfIt: "What will you get out of it?",
    whatWillYouGetOutOfItText1: "We want to buy every quality itinerary from you at a fair price. We know that as our platform grows technically, so must the amount of quality content. To make it attractive to users, and to spread the amount of travellers across more places.",
    whatWillYouGetOutOfItText2: "And what is a fair price? You tell us! Let's see if our ideas coincide... Either email us at {trekioInfoEmail} or register directly and we'll contact you ourselves 😊 Don't worry, until we sign a licensing agreement together, we won't publish any of your content without your consent.",
    wantToBecomeAuthor: "Want to become an author of our itineraries?"
}