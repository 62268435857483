import gql from 'graphql-tag'

export const USERS = gql`
    query AllUsersQuery($count: Int!) {
        users: users {
            id,
            userLogin,
            userName,
            avatarFileName,
            articleCount
            role {
                id,
                code
            }
        }
    }`
export const FILTERED_USERS = gql`
    query AllUsersQuery($filter: UsersFilter, $pageSize: PageSize) {
        filteredUsers: users(filter: $filter, pageSize: $pageSize) {
            id,
            userLogin,
            userName,
            avatarFileName,
            articleCount,
            role {
                id,
                code
            }
            blocked
            locked
        }
    }`

export const USER_BY_ID = gql`query GetUserByIdQuery($userId: Int!) {
    user(id: $userId) {
        id,
        userLogin,
        userName,
        firstName,
        lastName,
        phoneNumber,
        avatarFileName,
        role {
            id,
            code
        }
    }
}`

export const AUTHOR_BY_ID = gql`query GetAuthorByIdQuery($userId: Int!) {
    user(id: $userId) {
        id,
        userLogin,
        userName,
        firstName,
        lastName,
        phoneNumber,
        dateOfBirth,
        licenceAgreementSignedAt,
        avatarFileName,
        about,
        isAuthorPublished,
        address {
            country {
                id,
                nameCzech
                nameEnglish
            },
            city,
            street,
            houseNumber,
            zipCode,
            additionalInfo
        },
        role {
            id,
            code
        }
        blocked
        locked
    }
}`
export const LOGGED_USER = gql`query GetLoggedUser {
    loggedUser {
        id,
        userLogin,
    }
}`

export const NUMBER_OF_USERS = gql`
    query GetNumberOfUsers {
        numberOfUsers
    }`;

export const USER_PREFERRED_LANGUAGE = gql`
    query preferredLanguageForCurrentUser {
        preferredLanguageForCurrentUser
    }`
