export default {
    title: "Inspiruj. Sdílej. Poraď. Staň se autorem!",
    whoWeAre: "Kdo jsme?",
    whoWeAreText: "Rádi cestujeme. Po Česku i ve světě. Chceme poznávat a objevovat. Nevyhýbáme se „must-see“ zajímavostem, ale víc se těšíme na neotřelá zapadlá místa, která v běžném průvodci nenajdeš. Kde nepotkáš mainstreamové turisty, za to tam ale nechybí pozoruhodné zážitky. Baví nás pokec ve frontě na tržišti i východ slunce na zapadlé vyhlídce. Třeba s hrnkem čaje s místním medem. A k takovému cestování chceme pomoct i dalším spřízněným duším. Proto jsme začali tvořit platformu Trekio: pro sdílení cestovních itinerářů a tipů na zajímavá místa.",
    ourApproach: "Náš přístup",
    ourApproachText: "Jasně, nedělíme se úplně o všechny zajímavosti, na které narazíme. Víme, že některé jsou příliš křehké a vzácné. Věříme ale, že krása místa nezávisí na tom, kolik ho navštíví lidí. Myšlenkově jdeme proti overturismu, protože víme, že všeho moc škodí. Příliš mnoho lidí na stále stejných místech vede k degradaci lokalit, může kazit životy místních i zážitky z cestování. A přitom existuje tolik fascinujících neznámých míst, která stojí za to vidět, a kde by i tamní komunity uvítaly trochu více návštěvníků. A právě ta chceme popisovat v našich výletech! Udržitelný soulad člověka a přírody ale musí vždy zůstat na prvním místě. K tomu chceme přispět i naším přístupem. Ať už jde o preferenci šetrnějších způsobů dopravy, podporu lokálních podniků nebo šíření povědomí o tom, co kde roste a žije, a poskytování praktických informací třeba o chráněných územích přímo v popisech jednotlivých výletů. Je to víc práce, ale víme, že tahle práce je důležitá. Na výlet přece nechodíme konzumovat a devastovat, ale spíš objevovat a obdivovat, ne?",
    whoAreWeLookingFor: "Koho hledáme?",
    whoAreWeLookingForText1: "Sami neznáme všechna zapomenutá místa. A rádi zkoumáme nová. Proto teď hledáme na externí spolupráci spolehlivé autory cestovních itinerářů. Lidi, co smýšlí jako my. Ty, co baví cestovat a objevovat. A taky ty, co se chtějí podělit o zajímavosti svého kraje. Protože tipy od místních bývají nejcennější!",
    whoAreWeLookingForText2: "V tuto chvíli se chceme zaměřit na výlety v Česku, na Slovensku a v Polsku. Ale pokud jsme tě zaujali a chtěl/a by ses podělit o tipy někde jinde, klidně nás taky kontaktuj. Uvidíme, na jakém typu spolupráce se dokážeme domluvit 😊",
    whoAreWeLookingForText3: "Rádi bychom, aby se kolem stránek časem vytvořila samostatně fungující komunita, která by svými nápady, zážitky a připomínkami dodala Trekiu to, co mu do teď chybělo; přímý kontakt s lidmi. S uživateli, co už podle našich itinerářů cestují nebo se chtějí podílet na jejich vytváření. Abychom mohli společně vdechnout trochu života zapomenutým místům. A naopak odlehčit turistickým trhákům, kde příliš vysoká návštěvnost devastuje přírodu i atmosféru.",
    whatIsItAllAbout: "O co jde?",
    whatIsItAllAboutText1: "Každý náš výlet je itinerář s naplánovanou cestou, popisem navštívených míst, jejich fotkami a užitečnými informacemi. Obzvlášť důležité je i vysvětlení přírodních a kulturních podmínek. Aby každý cestovatel jasně věděl, co se kde může a čeho se vyvarovat. A taky proč. Chceme, aby bylo co nejsnazší cestovat zodpovědně.",
    whatIsItAllAboutText2: "Je to hodně práce a času, ale dobře zpracovaný výlet dokážeme ocenit. A taky pro tebe máme podrobný návod, jak na to.",
    whatWillYouGetOutOfIt: "Co za to?",
    whatWillYouGetOutOfItText1: "Každý kvalitní itinerář si od tebe chceme koupit za férovou cenu. Víme totiž, že spolu s technickým rozvojem naší platformy musí růst také množství kvalitního obsahu. Aby byla pro uživatele atraktivní, a aby se množství cestovatelů rozmělnilo na více míst.",
    whatWillYouGetOutOfItText2: "A co je to ta férová cena? To nám řekni ty! Uvidíme, na kolik se naše představy shodují… Buď nám napiš na {trekioInfoEmail} nebo se rovnou registruj a kontaktujeme tě sami 😊 Neboj, než spolu podepíšeme licenční smlouvu, žádný tvůj obsah bez souhlasu zveřejňovat nebudeme.",
    wantToBecomeAuthor: "Chceš se stát autorem našich výletů?"
}