export default {
    required: "This field is required",
    dayNumberUnique: "The day number must be unique!",
    dayNumberTooHigh: "The day number is too high!",
    dayNumberNotNegative: "The day number must not be negative!",
    latitudeRange: "GPS value must be between -90 and +90",
    longitudeRange: "GPS value must be between -180 and +180",
    sequenceNumberNotNegative: "The sequence number of the point of interest must not be negative!",
    sequenceNumberTooHigh: "The sequence number of the point of interest is too high!",
    sequenceNumberUnique: "The sequence number of the point of interest must be unique!",
    travelTipPictureRequired: "Title photo for the trip is required!",
    validToBeforeValidFrom: "The 'Valid to' field cannot be earlier than the 'Valid from' field",
    valueMustBeLargerThanZero: "Value must be higher than 0!",
    incorrectEmailFormat: "The email format is incorrect.",
    incorrectPasswordFormat: "Password must have at least 8 characters, one uppercase and one lowercase letter, and one symbol",
    incorrectFirstNameFormat: "The first name may only contain letters and certain special characters (hyphen, apostrophe).",
    incorrectLastNameFormat: "The last name may only contain letters and certain special characters (hyphen, apostrophe).",
    incorrectUserNameFormat: "The username may only contain letters, numbers, and certain special characters.",
    incorrectZipCodeFormat: "The zip code must contain only numbers or letters.",
    mustBeAtLeast16ToRegister: "You must be at least 16 years old to register",
    passwordsDoNotMatch: "Passwords do not match!",
    userNameAlreadyUsed: "User with nickname {0} already exists.",
    loginAlreadyUsed: "User with email address {0} already exists.",
    pinboardTitleMustBeUnique: "Make sure the pinboard title is unique.",

    dateMustNotBeInPast: "The date must not be in the past.",
    endDateBeforeStartDate: 'The date must not be earlier than "I will leave at the earliest from".',
    dateRangeIsTooSmall: 'Date range is less than min. trip length.'
}