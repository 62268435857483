export default {
    maxLength160: "Maximálně 160 znaků",
    maxLength40: "Maximálně 40 znaků",
    maxLength1024: "Maximálně 1024 znaků",
    maxLength: "Maximálně {0} znaků",
    min3Max160: "Minimálně 3 znaky - Maximálně 160 znaků",
    ammountOfSymbols: "Počet znaků: {0}",
    fileSize: "Velikost souboru nesmí přesáhnout 12,5 MB",
    resolutionTooSmall: "Rozlišení obrázku je příliš nízké!",
    resolutionCouldBeHigher: "Rozlišení obrázku by mohlo být větší!",
    resolutionIsCorrect: "Rozlišení obrázku je správné!",
    resolutionIs: "Rozlišení je {0} / {1}"
}