import en_about_page from "./en_about_page";
import en_how_to_page from "./en_how_to_page";
import en_input_errors from "./en_input_errors";
import en_input_labels from "./en_input_labels";
import en_general from "./en_general";
import en_buttons from "./en_buttons";
import en_loading from "./en_loading";
import en_terms_of_use from "./en_terms_of_use";
import en_principles_data_processing from "./en_principles_data_processing";
import en_loading_errors from "./en_loading_errors";
import en_info from "./en_info";
import en_input_hints from "./en_input_hints";
import en_alerts from "./en_alerts";
import en_landing_page from "./en_landing_page";
import en_difficulty_modal from "./en_difficulty_modal";
import en_modals from "./en_modals";


export default {
	inputLabels: en_input_labels,
	inputErrors: en_input_errors,
  inputHints: en_input_hints,
	general: en_general,
	buttons: en_buttons,
  loading: en_loading,
  loadingErrors: en_loading_errors,
  info: en_info,
  alerts: en_alerts,
  modals: en_modals,

  websiteTitle: "Travel with us and explore the entire globe.",

  admin: {
    loginExpired: "The login session has expired",
    loginToContinue: "Log in to continue.",
    redirectToHomepage: "Return to homepage",
    redirectToHomepageAlert: "(You will lose all unsaved data by doing so.)",

    welcomeToAdmin: "Welcome to Trekio admin section!",
    currentlyActiveCampaigns: "Currently active campaigns | Currently active campaign | Currently active campaigns",
    allCampaigns: "All campaigns",
    currentlyActiveTravelTips: "Currently active trips | Currently active trip | Currently active trips",
    allTravelTips: "All trips",
    registeredUsers: "Registered users | Registered user | Registered users",
    manageUsers: "Manage users",
    trafficOfTrekioInTheLast: "Traffic of trekio.com over the last",

    inviteAuthor: "Invite author",

    mobileViewAlert: "Admin section is not adapted for mobile view",
    switchToDesktopVersion: "Switch to desktop version",
    untilLogout: "Logout in:",
    trekioVersion: "Trekio • Version 2.1",
		state: "State",
    priority: "Priority",

    publishTranslation: "Pulish translation",
    notSelected: "Not selected",
    original: "Original",
    unsavedChangesWarning: "Some changes have not been saved. Do you really want to leave this page?",
    addCampaign: "Create campaign",
    withoutCampaign: "Without campaign",
		addTravelTip: "Create trip",
    addTravelTipToCampaign: "Add trip to the campaign",
    topTravelTip: '"Top" trip',
		untopTravelTip: '"Untop" trip',
		travelTipAlreadyUntopped: 'The trip is already "untopped"',
		travelTipAlreadyOnTheTop: 'The trip is already on the top',
		addPicture: "Create picture",
		addPictures: "Create pictures",
    clickOrDragImageToUpload: "Click or drag image here to upload",
    clickOrDragImagesToUpload: "Click or drag images here to upload",
    noTravelTipWithoutCampaign: "There are no trips without an assigned campaign.",
    noCommonLocalizationWithCampaign: "The trip does not have a common localization with the campaign.\nHighlighted localizations of the trip are missing.",
    inProgress: "Drafts",
    toRework: "For rework",
    toControl: "For review",
    toApprove: "For approval",
    declined: "Rejected",
    published: "Published",
    notPublished: "Hidden",
    forSale: "For sale",
		notForSale: "Not for sale",
    tripsWithoutPictureAre: "Trips without photo are",
		visible: "displayed",
    hidden: "not displayed",

    pictureIsAssignedTo: "The photo is assigned to:",
    inTravelTip: "in trip:",
    inDayWithNumber: "In day with number",

    travelTipComments: "Comments to trip",
    writeComment: "Write comment...",

    approveCampaignLocalization: "Approve the translation of the campaign",
    approveTravelTipTranslation: "Approve the translation of the basic info",
    addTravelTipPicture: "Add trip title photo",
    picturesTabChoosePictureFromTravelTip: "Select from trip photos",
    addNewPicture: "Create new picture",
    addNewPictures: "Create new pictures",
    noPictureInTravelTip: "There is no photo assigned to this trip.",
    choosePicture: "Choose picture",
    saveTravelTipBeforeAddingDays: "First you have to save the trip in order to add days and points of interest.",

    deleteItemTooManyEmptyItems: 'After deleting this point of interest, the number of waypoints ({ammountOfEmptyPoints}) defining the route between {previousItem} and "{nextItem}" would exceed the maximum possible number (9).',
		betweenItem: 'stop "{0}"',
		currentStartOfRoute: "current start of route",
		byTheCurrentEndOfTheRoute: "by the current end of the route",
    warningMaxEmptyItems: "Maximum number of waypoints is 9!",
    closeItem: "Close point of interest",
    dayPictureWarning: "To select a title photo for the day, first upload photos to the day's points of interest. You'll be able to select the day title photo from them.",
    dayPictureChoosePicture: "Select day title photo from the photos assigned to its points of interest.",
    dayPictureWarningNoItemPicture: "There is no photo assigned to this day's points of interest.",
    specifyRoute: "Specify route",
    approveDayTranslation: "Approve the translation of the day",
    routeLengthHint: "Distance to point of interest from the previous one according to map: ",
    addPicturesToItem: "Add photos to this point of interest",

		approveItemTranslation: "Approve the translation of the point of interest",

    doYouWantToBePublishedAsAuthor: "Do you want to be credited as the author of your trips?",
    authorIsPublished: "Author is credited",
    authorIsHidden: "Author is not listed",
    aboutAuthor: "About author",
    addUser: "Add user",
    myProfile: "My profile",
		userIsBlocked: "User is blocked!",
		userIsAnonymized: "User is anonymized!",
    addProfilePicture: "Add profile photo",

    hereWillBe: 'Here will be "{0}"',
    hereCouldBe: 'Here could be "{0}"',

    tagsChosenOutOfInLanguage: '{total} of which {n} is in "{language}" | {total} of which {n} is in "{language}" | {total} of which {n} are in "{language}"',
    tagCategories: 'Tag categories',
		addCategory: 'Add category',
    translation: 'Translation',
    addTag: "Add tag",

    addEquipment: "Add equipment",
    addLink: "Add link",
    newTemplateTitle: "New template title",
    templates: "Templates",
    notTranslated: "Not translated",
	},

  map: {
		zoomIn: "Zoom in",
		zoomOut: "Zoom out",
		allDays: "All days",
		viewTheDayOnMapyCZ: "Show day on mapy.cz",
    showTrip: "Show trip"
	},

	filter: {
		noResults: "No results found for the applied filter(s).",
		search: "Search",
		searchTerm: "Search for keywords",
		state: "State",
		assignedToTravelTip: "Assigned to a trip",
		assigned: "Assigned",
		notAssigned: "Not assigned",
		lookUpYourTrip: "Find a trip!",
		uncheckAll: "Clear all",
		checkAll: "Select all",
		tripLength: "Length of trip",
		to: 'to',
		chosenOutOf: "{0} out of {1} selected",
		numberOfDays: "How many days?",
		searchBySearchTerm: "Keywords",
    moreCountries: "More countries",
		lessCountries: "Less countries",
		moreFilters: "More filters",
		lessFilters: "Less filters",
		searchingForTrips: "Search trips",
		resultFoundTrips: "We found {tripCount} for your search",
	},

	public: {
    shareWithYourFriends: "Share trip with friends",
    copyLink: "Copy link",
    shareVia: "Share on",
    checkOutTheTrip: "Check out trip XXX on Trekio!",

    travelTipDetailPage: {
      travelTipTranslationUnavailable: "The translation of the trip in this language is not available",
      itemEstimatedTime: "Estimated time spent at point of interest: {0}",
      transferBetweenItems: "Transportation between points of interest (km)",
      tripMap: "Map",
      settled: "Prepared",
			packed: "Packed",
			packingList: "Packing list",
      packingListInfo: "This trip does not have a suggested equipment list, but you can create your own. The interactive list will be saved with the trip, and you can return to it anytime from the trip details. Start by clicking on the '+' next to the 'Others' label.",
      summary: "Summary",
			itinerary: "Itinerary",
      gallery: "Gallery",
    },
    assessments: {
			confirmModalAssessmentDeleteTitle: "Do you really want to delete your review?",
			confirmModalAssessmentDeleteOthersTitle: "Do you really want to delete XXX's review?",
			loadingDelete: "Deleting review...",
      beFirstToReview: "Be the first to rate this trip!",
      overallRating: "Overall rating",
      addAssessment: "Write a review",
      loginToAddAssessment: "Log in to write a review",
      rateTravelTip: "Write a review",
      comment: "Comment",
      loading: "Submitting review...",
      overallInterest: "Overall impression",
      priceQualityRatio: "Price-Quality ratio",
      trafficAvailability: "Transportation availability",
      sights: "Sightseeing",
      correspondsToTheDescription: "Trip matches description",
      photogenic: "Photogenic",
      availabilityOfEquipment: "Equipment/Gear availability",
      uniqueness: "Uniqueness",
      availabilityOfPublicFacilities: "Public facilities availability",
      accommodation: "Accommodation",
      difficultyMatching: "Difficulty matches description",
      cleanliness: "Cleanliness",
      personnel: "Services",
      atmosphere: "Atmosphere",
    },
    profilePage: {
      support: "Support",
      havingTroubleWithTrekio: "Have you encountered a problem with Trekio? Can't handle something? We care about making the platform as easy as possible for you to use. Please let us know so we can continue improving what we're already doing.",
      hereForYou: "We're here for you",
      message: "Your message",
      addProfilePicture: "Add profile photo",
      changePicture: "Change photo",
      anonymize: "Anonymize",
      anonymizeAccount: "Anonymize account (delete account)",
      editProfile: "Change profile",
    },
    registrationPage: {
      registration: "Registration",
      authorRegistration: "Author registration",
      joinUs: "Join us",
      alreadyHaveAccount: "Do you already have an account?",
      agreeWith: "I agree with",
      agreeWithTermsOfUse: "Terms and conditions for using the platform",
      iHaveRead: "I have read",
      iHaveReadPrinciplesDataProcessing: "Personal data processing policy",
    },

    loginPage: {
      loginHeader: "Login",
      welcomeBack: "Welcome back to Trekio!",
      forgottenPassword: "Forgotten password",
      noAccountYetUser: "Don't have an account yet?",
      noAccountYetAuthor: "Would you like to collaborate as an author?",
      registerAuthor: "Register as author",
      loginToContinueToAdmin: "Please log in to continue to the admin section.",
      loginExpired: "The login session has expired",
      loginToContinue: "Log in to continue.",
      redirectToHomepage: "Return to homepage",
      redirectToHomepageAlert: "(You will lose all unsaved data by doing so.)",
    },
    resetPasswordPage: {
      forgottenPassword: "Forgotten password",
      passwordChangedSuccessfully: "Password changed successfully!",
      fillInYourEmailAddress: "Enter your e-mail address",
      forLoginClickOn: "To log in click",
      fillInNewPassword: "Enter new password",
      changePassword: "Change password"
    },

    contactPage: {
      contactUs: "Contact us",
      writeUs: "Leave us a message",
      inputLabelContactName: "Name",
      inputPlaceholderContactMessage: "Hi, I'm interested in..."
    },
    homePage: {
      homepageTagline: "Discover places you wouldn't find in a guide book",
      experiencesTitle: "Experience it",
      experiencesText: "Original itineraries, memorable experiences",
      experiencesSubTitle: "Experience",
      coolPlacesTitle: "Cool places",
      coolPlacesText: "Discover places you haven't heard of",
      coolPlacesSubTitle: "Discover",
      comfortTitle: "No worries",
      comfortText: "Leave the planning and worries to us",
      comfortSubTitle: "Chill",
      interestsAboutTravel: "What do you travel for?",
      all: "Everything"
    },
    myTravelTipsPage: {
      myTrips: "My trips",
      favourites: "Saved",
      ongoing: "Current trip",
      past: "Past",
      noTravelTips: "There are no trips",
      tryToClearFilter: "You can try to clear the filter(s).",
      clearFilter: "Clear filter",
      findYourTrips: "1. Find the trip of your choice.\n2. Click on the heart icon to save it.\n3. You can find your saved trips here.",
      browseTrips: "Browse trips",
      filterTrips: "Filter trips",
    },
    pinboardPostsPage: {
      lookingForTravelBuddy: "Looking for a travel buddy?",
      createPostWithInfo: "Create a post with the info on when and where you'd like to go.",
      otherUsersCanReply: "Other registered users will be able to reply to you by direct message. Then you can fine-tune the details. Or check out what trips others are planning in our travel directory. And join someone.",
      thereAreNoPosts: "There are no posts",
      findTravelBuddy: "Find a travel buddy",
      chooseTripToYourPost: "Choose a trip to your post",
      searchByName: "Search by name",
      loginToAddPost: "Log in to create a post.",
    },
    messagesPage: {
      noConversation: "You don't have any conversation.",
      newConversation: "New conversation",
      writeMessageToStartConversation: "Write a message to start a conversation",
      writeMessage: "Write a message",
		},
    aboutPage: en_about_page,
    howToPage: en_how_to_page,
    landingPage: en_landing_page,
    notFoundPage: {
      pageNotFound: "We're sorry. This page does not exist :(",
      weCantFind: "We can't find",
      backToHomepage: "Back to HomePage"
    }
  },
  difficultyModal: en_difficulty_modal,
  termsOfUse: en_terms_of_use,
  principlesDataProcessing: en_principles_data_processing,
}