export default {
    howItAllWorks: "Jak to celé funguje...",
    iWantToGoOnATripTitle: "Chci vyrazit na výlet!",
    iWantToGoOnATripText: "Máš vysněnou destinaci? Chceš objevit zajímavá místa? Potřebuješ tip na volný den? Nebo rovnou neotřelý plán na příští dovolenou? ...výlety na míru najdeš pomocí našeho přehledného filtru.",
    howToChooseTitle: "Podle čeho vybírat?",
    howToChooseText: "Můžeš vybírat na základě kritérií jako: {country}, {numberOfDays}, náročnost, preferované dopravní prostředky, nebo podle toho, {whatInterestsYouWhenTraveling}. Ať tě baví chodit po lesích a horách nebo radši poznáváš místní kulturu a obdivuješ památky. A jestli nevíš, co ve filtru vybrat, nech se inspirovat oblíbenými výlety!",
    country: "země",
    numberOfDays: "počet dnů výletu",
    difficulty: "náročnost",
    whatInterestsYouWhenTraveling: "co vás na cestování zajímá",
    howToChooseText2: "Zaujal tě nějaký výlet? Nebo dokonce víc? {share} je rodině nebo kamarádům!",
    share: "Nasdílej",
    howToChooseText3: "Pokud máš účet, můžeš si výlet pomocí {heartIcon} uložit na později. Tak se k němu budeš moct vrátit, až přijde ten správný čas!",
    heartIcon: "srdíčka",
    foundATripTitle: "Mám výlet! Chci vyrazit",
    foundATripText: "Snažíme se, aby každý náš výlet obsahoval všechny důležité informace. Ty, které potřebuješ znát dopředu, i ty, co se hodí až během výletu. A podle toho jsou taky strukturované naše itineráře. Jejich součástí je i mapa s vyznačeným startem a cílem každého dne, trasou i všemi naplánovanými zastávkami po cestě.",
    foundATripText2: "Musíš si tedy už jen vyřešit, jak se na začátek a z konce výletu dopravíš a kde budeš spát, pokud je vícedenní. A pak... hurá na výlet!",
    rateTheTripYouLikedTitle: "Líbil se ti výlet? Ohodnoť ho!",
    rateTheTripYouLikedText: "Jako přihlášený uživatel můžeš k výletům přidávat hodnocení a pomoct tak ostatním cestovatelům s rozhodnutím, jestli právě ten výlet je pro ně to pravé.",
    rateTheTripYouLikedText2: "Zpětná vazba těší i nás, tým Trekio. Přes sekci {contactPageLink} nám můžeš dát vědět, jak se ti na výletě s plánem od nás líbilo. Rádi si přečteme zážitky z cest i připomínky, pokud něco nešlo podle plánu. Posouváš nás tak dál.",
    rateTheTripYouLikedText3: "Fotky z výletů sdílej na sociálních sítích s hashtagem #trekio. Chceme vidět, jak jste se měli!",
    iWantToShareTripsTitle: "Chci se podělit o tipy na výlety a zajímavá místa!",
    iWantToShareTripsText: "Rád/a na cestách objevuješ a chceš se podělit o zajímavá místa? Nebo chceš ostatním ukázat místo ze svého okolí, které nám tu zatím chybí? Přidej se k naší komunitě autorů!",
    becomeAuthor: 'Staň se autorem',
    culture: 'Kultura',
    threeDays: '3 dny',
    italy: 'Itálie',
}