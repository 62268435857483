import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import App from './App.vue'
import { apolloProvider } from "@/api/apolloClient";
import { BootstrapVue, IconsPlugin, } from 'bootstrap-vue'
import store from "@/store/store"
import router from "@/router/index.js";
import NavBarNew from "@/components/redesign/NavBarNew";
import TrekioButton from "@/components/TrekioButton";
import TrekioInput from "@/components/TrekioInput"
import TrekioTextarea from "@/components/TrekioTextarea"
import TrekioSwitch from "@/components/TrekioSwitch"
import TrekioSelect from "@/components/TrekioSelect"
import TrekioSelectOption from "@/components/TrekioSelectOption"
import VueGtag from "vue-gtag";
import Gkeys from "./google_keys";
import RecaptchaEnterprise from './utils/RecaptchaEnterprise'
import VueI18n from 'vue-i18n'
import i18n from './localization/i18n'
import VTooltip from 'v-tooltip'


//plugin for date format
Vue.use(require('vue-moment'));
// Install BootstrapVue
Vue.use(BootstrapVue)
// For slider
Vue.use(VueCompositionAPI)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.config.productionTip = false;

// Tooltip
Vue.use(VTooltip)
VTooltip.options.popover.defaultTrigger = 'hover'
VTooltip.options.defaultDelay = {show: 0, hide: 0}

//make NavBar global component
Vue.component('NavBarNew',NavBarNew)
//make TrekioButton global component
Vue.component('TrekioButton', TrekioButton)
//make TrekioInput global component
Vue.component('TrekioInput', TrekioInput)
//make TrekioTextarea global component
Vue.component('TrekioTextarea', TrekioTextarea)
//make TrekioSwitch global component
Vue.component('TrekioSwitch', TrekioSwitch)
//make TrekioSelect global component
Vue.component('TrekioSelect', TrekioSelect)
Vue.component('TrekioSelectOption', TrekioSelectOption)

//google analytics
Vue.use(VueGtag, {
    config: { id: Gkeys.Analytics_id },
    enabled: false
});
Vue.use(RecaptchaEnterprise, { siteKey: Gkeys.Recaptcha_SiteKey })
Vue.use(VueI18n)

Vue.config.devtools = true

new Vue({
    apolloProvider,
    render: h => h(App),
    router,
    store,
    Gkeys,
    i18n
}).$mount('#app');
