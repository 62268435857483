export default {
    warning: "Warning:",
    changesWillBeIrreversible: "The changes you make will be irreversible!",
    travelTipDeleteTitle: "Do you really want to delete trip '{0}'?",
    travelTipDeleteText: "This action will be irreversible! All days and points of interest in the trip will be deleted!",
    travelTipDeclineTitle: "Do you really want to reject trip '{0}'?",
    travelTipDeclineText: "The status of the trip will be changed to 'Rejected' and the trip will be deleted in 30 days!",
    travelTipToReworkTitle: "Do you really want to return trip '{0}' to the user for rework?",
    travelTipToReworkText: "The trip will be returned to the user for rework. Write a comment explaining why you are returning it for rework so the user knows what to change.",
    travelTipApproveTitle: "Do you really want to approve trip '{0}'?",
    travelTipApproveText: "The trip will be approved. It's status will be set to 'Hidden'.",
    travelTipToApproveTitle: "Do you really want to submit trip '{0}' for approval?",
    travelTipToApproveText: "Once the trip is submitted, you will no longer be able to edit it.",
    travelTipToControlTitle: "Do you really want to submit trip '{0}' for review?",
    travelTipToControlText: "Once the trip is submitted, you will no longer be able to edit it.",
    dayDeleteTitle: "Do you really want to delete day {0} of trip {1}?",
    dayDeleteText: "This action will be irreversible! All points of interest within the day will be deleted!",
    emptyItemDelete: "Do you really want to delete this waypoint?",
    itemDelete: "Do you really want to delete point of interest '{0}'?",
    emptyItemLimitText1: "After deleting this point of interest, the number of waypoints ({0}) defining the route between {{ prevItem && 'stop ' }}{{ prevItem ? '\"' + prevItem.title + '\"' : 'current start of route' }} and  \"{{ nextItem ? '\"' + nextItem.title + '\"' : 'by the current end of the route' }}\" would exceed the maximum possible number (9)",
    emptyItemLimitText2: "Clicking 'Delete' will therefore also delete all waypoints between these points of interest.",
    emptyItemLimitText3: "If you want to keep any of these waypoints (max. 9), click 'Back' and manually remove at least {0} of the waypoints.",
    userAnonymizeTitle: "Do you really want to anonymize user '{0}'?",
    userAnonymizeAlert: "You are trying to delete your account!",
    userAnonymizeOtherText: "This action will be irreversible. The user will no longer have access to their account.",
    userAnonymizeText: "This action will be irreversible. After anonymization, you will no longer have access to your account.",
    userAnonymizeText2: "However, the authorship of the trips remains yours. (Due to licensing rights, we will still be able to connect you and your trips in our internal database.)\nCheck your profile settings of whether you also want to be publicly listed as author or not!\nIf the switch is set to yes, your nickname and short info will be visible with your trips. Once you cancel your account, you won't be able to change these settings.",
    userBlockTitle: "Do you really want to block user '{0}'?",
    userBlockText: "After being blocked, the user will no longer be able to log in to their account.",
    userUnblockTitle: "Do you really want to unblock user '{0}'?",
    userUnblockText: "After being unblocked, the user will be able to log in to their account again.",
    invitationTitle: "Do you really want to send an invitation to {0}?",
    invitationText: "The author will be able to register using the link provided within the next 14 days after submitting this form.",
    campaignDeleteTitle: "Do you really want to delete this campaign?",
    campaignDeleteText: "This action will be irreversible, but the trips included in the campaign will remain in the Trips section.",
    pictureDeleteTitle: "Do you really want to delete this photo?",
    pictureDeleteText: "The changes you make will be irreversible!",
    assessmentDeleteTitle: "Do you really want to delete your review?",
    assessmentDeleteOthersTitle: "Do you really want to delete {0}'s review?",
    pinboardPostDeleteTitle: 'Do you really want to delete the pinboardPost "{0}"?',
    pinboardPostDeleteText: "All user conversations related to this post will be deleted!",
    pinboardDeleteTitle: 'Do you really want to delete the pinboard "{0}"?',
    pinboardDeleteText: 'All of the posts in the pinboard will be moved into the "GENERAL" pinboard',
    changedLocalizationInputWarningTitle: "Warning:  you have changed fields that have localizations in other languages.",
    changedLocalizationInputWarningText1: "If the change was only stylistic, select \"Save\" (without hiding the other localizations).",
    changedLocalizationInputWarningText2: "If the meaning of the message has changed, select \"Save and hide others\".",
    changedLocalizationInputWarningText3: "The \"Save and hide others\" option marks the changed part of the trip as unapproved in all approved localizations. All such localizations will be hidden for regular users until an adequate change of meaning is made and approved in the modified part.",
    unsavedChangesInFormTitle: "There are unsaved changes",
    unsavedChangesInFormText: "Go back and save the changes or discard the changes and continue.",
    tagCategoryDeleteTitle: "Do you really want to delete tag category '{0}'?",
    tagCategoryDeleteText1: "Tags in the category are used in these trips:",
    tagCategoryDeleteText2: "All tags in the category will be deleted",
    tagCategoryDeleteText3: "If the tags are used in trips, they will be deleted there as well.",
    tagDeleteTitle: "Do you really want to delete tag '{0}'?",
    tagDeleteText1: "The tag is used in these trips:",
    tagDeleteText2: "The tag will be removed from the trips where it is used.",
    equipmentDeleteTitle: "Do you really want to delete equipment '{0}'?",
    equipmentTemplateDeleteTitle: "Do you really want to delete template '{0}'?",
    equipmentCategoryDeleteTitle: 'Do you really want to delete equipment category "{0}"?',
    equipmentCategoryDeleteText: 'By deleting the category, all equipment contained within the category will be moved to the "DEFAULT" category.',
  }